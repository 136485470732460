import axios from 'axios';
import React, { useEffect, useState } from 'react'
import globeVar from '../../GlobeApi';
import { toast } from 'react-toastify';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import combo_underline from "../../assets/img/Group 446.svg";
import combo_Offer from "../../assets/img/homepage/Combo-Banner-2.gif";
import basket from "../../assets/img/homepage/basket.svg";
import { Link } from 'react-router-dom';
import like from "../../assets/img/homepage/like.png";

const ComboOffer = () => {
  const [user, setuser] = useState(null)
  const [combos, setcombos] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const [popular, setPopular] = useState([]);
  var data = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setuser(JSON.parse(sessionStorage.getItem("user")));
    }
  }, [])

  const add_card = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = data._id;
      const product_data = id;
      const product_qty = 1;
      let items = { productId: product_data, quantity: parseInt(product_qty) };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        if (response.data.type === "success") {
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img alt='basket' src={basket} />,
            style: { background: '#c9184a' },
          });
        }
      });
    } else {
      const product_data = id;
      const product_qty = 1;
      var items;
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
        };
      } else {
        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
        };
      }
      axios.post(globeVar + "addtocart", items).then((response) => {
        if (response.data.type === "success") {
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} alt='basket' />,
            style: { background: '#c9184a' },
          });
          if (!response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          } else {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }
        }
      });
    }
  };

  const combo_res = {
    responsive: {
      0: {
        items: 2,
        margin: 20
      },
      520: {
        items: 2,
      },
      950: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1300: {
        items: 5,
        margin: 20
      },

    },
  };

  function changeIcon(anchor, productId) {
    if (sessionStorage.getItem("user")) {
      var icon = anchor.target;
      icon.classList.toggle("icon-wishlist");
      icon.classList.toggle("icon-wishlist-fill");
      const item = { userId: data._id, productId: productId };
      axios.post(globeVar + "add_to_wishlist", item).then((response) => {

        if (response.data.type === "success") {
          var wishlist_id = [];
          if (response.data.newWish) {
            response.data.newWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
          } else if (response.data.updatedWish) {
            response.data.updatedWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
          }
          var x = wishlist.includes(productId);
          if (x === true) {
            toast.success('Removed from Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} alt='like' />,
              style: { background: '#c9184a' },
            });
          } else {
            toast.success('Added to Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} alt='like' />,
              style: { background: '#c9184a' },
            });
          }
          setwishlist(wishlist_id);
        }
      });
    } else {
      window.$("#exampleModalToggle").modal("show");
      localStorage.setItem("wishlist", JSON.stringify(productId));
    }
  }

  useEffect(() => {

    if (sessionStorage.getItem("user")) {
      var data = sessionStorage.getItem("user");
      data = JSON.parse(data);
      const item = { userId: data._id };
      axios.post(globeVar + "get_Wishlist", item).then((response) => {
        if (response.data.type === "success") {
          var wishlist_id = [];
          response.data.wishlist.products.forEach((rev) => {
            wishlist_id.push(rev.productId);
          });
          setwishlist(wishlist_id);
          axios.post(globeVar + "product_tags_list").then((response) => {
            if (response.data.type === "success") {
              setPopular(response.data.data);
            }
          });
        }
      });
    }

    axios.post(globeVar + "combo_offer").then((response) => {
      if (response.data.type === "success") {
        setcombos(response.data.data);

      }
    });

  },[])

  return (
    <section className="combo-offers-section">
        <div className="combo_underline container-el container">
          <h5>Combo Offers</h5>
          <img src={combo_underline} alt="" />
        </div>
        <img src={combo_Offer} className="w-100" alt="" />
        <div className="container">
          <div className="row combo-slider-row justify-content-md-center">
            {/* <div className="col-lg-3 col-md-6 left-img-col">
              <button className="view-btn" onClick={() => prdByfunc("Combos")}>View All</button>
              <img src={image30} alt="" />
            </div> */}
            <div className="col-lg-12 right-slider-col">
              <div className="swiper comboSwiper">
                <div className="swiper-wrapper">

                  <OwlCarousel
                    items={4}
                    margin={70}
                    loop
                    autoplayHoverPause={true}
                    {...combo_res}
                    autoplay={true}
                    autoplayTimeout={1000}
                    nav={true}
                    dots={true}
                  >
                    {combos.map((data) => {
                      return (
                        <div className="swiper-slide card popular-section" key={data._id}>
                          <div className="header-sec">
                            {user?.role !== "retailer" && data.discount ? (
                              <span className="price-tag">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="69.521"
                                  height="74.698"
                                  viewBox="0 0 69.521 74.698"
                                >
                                  <defs>
                                    <filter
                                      id="_281639d9032fd35ab631d5064a2c1da9"
                                      x="0"
                                      y="5.177"
                                      width="69.521"
                                      height="69.521"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feOffset dy="3" input="SourceAlpha" />
                                      <feGaussianBlur
                                        stdDeviation="2"
                                        result="blur"
                                      />
                                      <feFlood
                                        floodColor="#7a6b6b"
                                        floodOpacity="0.361"
                                      />
                                      <feComposite operator="in" in2="blur" />
                                      <feComposite in="SourceGraphic" />
                                    </filter>
                                  </defs>
                                  <g
                                    id="Component_59_1"
                                    data-name="Component 59 – 1"
                                    transform="translate(6.865 0.507)"
                                  >
                                    <g
                                      id="Group_272"
                                      data-name="Group 272"
                                      transform="translate(-357.104 -1330.704)"
                                    >
                                      <g
                                        id="Group_269"
                                        data-name="Group 269"
                                        transform="translate(278 -41)"
                                      >
                                        <g id="Group_268" data-name="Group 268">
                                          <g
                                            id="Group_267"
                                            data-name="Group 267"
                                            className="discount"
                                          >
                                            <g
                                              transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                              filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                                            >
                                              <path
                                                id="_281639d9032fd35ab631d5064a2c1da9-2"
                                                data-name="281639d9032fd35ab631d5064a2c1da9"
                                                d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                                transform="translate(5.45 35.39) rotate(-45)"
                                                fill="#fff1f5"
                                                stroke="#ee7e9e"
                                                strokeWidth="1"
                                                className="rotate-tag"
                                              />
                                            </g>
                                            <text
                                              id="_15_"
                                              className="_15_"
                                              data-name="15%"
                                              transform="translate(98 1413)"
                                              fontSize="12"
                                              fontFamily="Comfortaa-Bold, Comfortaa"
                                              fontWeight="700"
                                            >
                                              <tspan x="0" y="0">
                                                {data.discount}%
                                              </tspan>
                                            </text>
                                          </g>
                                        </g>
                                      </g>
                                      <g
                                        id="Group_270"
                                        data-name="Group 270"
                                        className="hook"
                                        transform="translate(277.674 -41)"
                                      >
                                        <path
                                          id="Path_156"
                                          data-name="Path 156"
                                          d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                          transform="translate(1997.472 4421.696)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_155"
                                          data-name="Path 155"
                                          d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                          transform="translate(1980.552 4427.111)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/product-cart/${data._id}`}>
                              {
                                data.prod_gal == "Video" ?
                                  <img
                                    src={data.product_img}
                                    className="card-img-top"
                                    alt="..."
                                  />
                                  :
                                  <img
                                    src={data.product_img}
                                    className="card-img-top"
                                    alt="..."
                                  />
                              }
                            </Link>
                            <button
                              className="like-btn border-0 bg-transparent"
                            >
                              {wishlist.length > 0 ? (
                                wishlist.includes(data._id) ? (
                                  <span className="icon-wishlist-fill" onClick={(e) => changeIcon(e, data._id)}></span>
                                ) : (
                                  <span className="icon-wishlist" onClick={(e) => changeIcon(e, data._id)}></span>
                                )
                              ) : (
                                <span className="icon-wishlist" onClick={(e) => changeIcon(e, data._id)}></span>
                              )}
                            </button>
                          </div>
                          <div className="card-body">
                            {data.tags === "Trending" ?
                              <div className="flag1">
                                <span className="flag-text">{data.tags}</span>
                              </div> : data.tags === "Popular" ?
                              <div className="flag2">
                                <span className="flag-text">{data.tags}</span>
                              </div>  : data.tags === "Combo Offer" ? 
                              <div className="flag4">
                                <span className="flag-text">{data.tags}</span>
                              </div>:
                              <div className="flag3">
                                <span className="flag-text">{data.tags}</span>
                              </div>
                            }
                            <h4>{data.product_name.substring(0, 18) + '...'} </h4>
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                {user && user?.role === "retailer" ? "₹" + data?.retailerPrice + " ( CP : "  :""} ₹{data.saving ? data.price - data.saving : data.price} {user?.role !== "retailer" && data.discount ? <del>₹{data.price === "" ? 0 : data.price}</del> : ""} {user && user?.role === "retailer" ? " )" :" "}
                              </span>
                              <button
                                onClick={() => add_card(data._id)}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <img src={basket} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      );

                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ComboOffer