import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import globeVar from '../../GlobeApi';
import underline from "../../assets/img/Group 442.svg";
import LazyLoad from 'react-lazyload';
import Loading from '../../assets/img/loding.gif';

const ShopByCategory = () => {
  const [category, setcategory] = useState([]);
  const [mobileCategory, setMobileCategory] = useState([]);

  // const isMobile = window.innerWidth <= 500; 
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const prdByCate = (id) => {
    sessionStorage.setItem("cate_id", id);
    window.location.href = "/product";
  }

  const prdBySubCate = (id) => {
    sessionStorage.setItem("sub_id", id);
    window.location.href = "/product";
  }

  const handleWindowResize = () => {
    window.innerWidth >= 500 ? setIsLaptop(true) : setIsLaptop(false);
    window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  useEffect(() => {
    handleWindowResize();
    axios.post(globeVar + "category_list").then((response) => {
      if (response.data.type === "success") {
        setcategory(response.data.data);
        var Arr = [];
        var childArr = [];
        var cate = response.data.data;
        cate.map((cat, i) => {
          let modulusCheck = i % 4;
          childArr.push(cat);
          if (modulusCheck === 3 || i === cate.length-1) {
            Arr.push(childArr);
            childArr=[];
          };
        })
        setMobileCategory(Arr);
      }
    });
  },[])
  return (
    <>
      {!isMobile && (
        <section className="shop-by-category-section">
        <div className="container">
          <div className="row shop_underline">
            <h5>Shop By Category</h5>
            <img src={underline} alt="" />
          </div>
          <div className="row category-card-row">
            <div className="">
              <Swiper
                // install Swiper modules
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 3
                  },
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  992: {
                    spaceBetween: 75,
                    slidesPerView: 3,
                    slidesPerGroup: 3
                  },
                  1200: {
                    spaceBetween: 10,
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 24
                  },
                  1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 57
                  },
                }}
                modules={[Navigation, Pagination]}
                centerslide="false"
                effect={"fade"}
                grabCursor={"true"}
                // spaceBetween={57}
                slidesPerView={"auto"}
                variableWidth={false}
                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".product2",
                  prevEl: ".product1",
                }}

              /* onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")} */
              >
                <div className="swiper-wrapper">
                  {/*  <OwlCarousel
                    items={4}
                    margin={50}
                    className="owl-theme"
                    {...options}
                    dots nav={true} navText={[ '<div className="arrow product1">&#10094;</div>', '<div className="arrow product2">&#10095;</div>' ]} 
                  > */}

                  {category.map((dep, i) => {
                    return (
                      <SwiperSlide key={dep._id}>
                        {" "}
                        <div className="card swiper-slide">
                          <div className="category-card-img">
                            {dep.gal_type === 'Image' ?
                              <img
                                src={dep.category_img}
                                className="card-img-top"
                                alt={dep.category_name}
                                style={{cursor : "pointer"}}
                                onClick={() => prdByCate(dep._id)}
                              />
                              :
                              <LazyLoad>
                                <video id="myvideo"
                                  playsInline
                                  loop
                                  muted
                                  autoPlay
                                  className="card-video-top"
                                  src={dep.category_video}
                                  style={{cursor : "pointer"}}
                                  onClick={() => prdByCate(dep._id)}
                                />
                              </LazyLoad>
                            }
                          </div>
                          <div className="card-body">
                            <h4>{dep.category_name}</h4>
                            <ul
                              className="list-group list-group-flush"
                            >
                              {dep.sub_data.slice(0, 3).map((rev) => {
                                return (
                                  <li className="list-group-item" key={rev._id}>
                                    <div onClick={() => prdBySubCate(rev._id)} state={{ id: rev._id, cate_id: dep._id }} >
                                      {rev.subcategory_name}
                                    </div>
                                    <i className="icon-list-marker"></i>
                                  </li>
                                );
                              })}
                              {/* <li className="list-group-item view-all-color">
                                <div
                                  className="card-link"
                                  style={{ color: "#c9184a" }}
                                  onClick={() => prdByCate(dep._id)}
                                >
                                  View All
                                </div>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}


                  {/* </OwlCarousel> */}
                </div>
              </Swiper>
            </div>
            <div className="arrow product1">&#10094;</div>
            <div className="arrow product2">&#10095;</div>
          </div>
        </div>
      </section> ) }
      {/* mobile responsive view category */}
      {!isLaptop &&  (
        <section className="shop-by-category-section">
        <div className="container">
          <div className="row shop_underline">
            <h5>Shop By Category</h5>
            <img src={underline} alt="" />
          </div>
          <div className="row category-card-row">
            <div className="">
              <Swiper
                // install Swiper modules
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  768: {

                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  992: {
                    spaceBetween: 75,
                    slidesPerView: 3,
                    slidesPerGroup: 3
                  },
                  1200: {
                    spaceBetween: 10,
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 24
                  },
                  1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 57
                  },
                }}
                modules={[Navigation, Pagination]}
                centerslide="false"
                effect={"fade"}
                grabCursor={"true"}
                // spaceBetween={57}
                slidesPerView={"auto"}
                variableWidth={false}
                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".product2",
                  prevEl: ".product1",
                }}

              /* onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")} */
              >
                <div className="swiper-wrapper">

                  {mobileCategory.map((depa, i) => {
                    console.log(i);
                    const fun = (dep) => {
                      return (
                        <div className="col-6">
                          <div className="category-card-img" style={{height : "auto", width : "auto"}}>
                            {dep.gal_type === 'Image' ?
                              <img
                                src={dep.category_img}
                                className="card-img-top "
                                alt={dep.category_name}
                                onClick={() => prdByCate(dep._id)}
                                style={{cursor : "pointer"}}
                              />
                              :
                              <LazyLoad>
                                <video id="myvideo"
                                  playsInline
                                  loop
                                  muted
                                  autoPlay
                                  className="card-video-top"
                                  src={dep.category_video}
                                  onClick={() => prdByCate(dep._id)}
                                  style={{ cursor: "pointer" }}
                                  poster={Loading}
                                />
                              </LazyLoad>
                            }
                          </div>
                          <div className="card-body" style={{padding: "3px 0px 10px", textAlign: "center"}}>
                            <h4>{dep.category_name}</h4>
                            {/* <ul
                              className="list-group list-group-flush"
                            >
                              <li className="list-group-item view-all-color">
                                <div
                                  className="card-link"
                                  style={{ color: "#c9184a" }}
                                  onClick={() => prdByCate(dep._id)}
                                >
                                  View All
                                </div>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      )
                    } 
                    return (
                        <SwiperSlide key={depa[0]._id+""+Math.random()}>
                          {" "}
                          <div className="card swiper-slide" style={{width: "100%"}}>
                          <div className="row">
                            {depa.map((dep) =>
                              fun(dep)
                            )}
                          </div>
                          </div>
                        </SwiperSlide>
                    );
                  })}
                </div>
              </Swiper>
            </div>
            <div className="arrow product1">&#10094;</div>
            <div className="arrow product2">&#10095;</div>
          </div>
        </div>
      </section>
      )
      }
    </>
  )
}

export default ShopByCategory