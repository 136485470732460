import React, { useState, useEffect } from 'react'
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CorporateAV = () => {
  const [corporate_data, setcorporate_data] = useState({})
  useEffect(() => {
    window.scrollTo(0, 0);

    axios.post(globeVar + "corporate_list").then((response) => {
      if (response.data.type === "success") {
        setcorporate_data(response.data.data);
      }
    })
  }, [])
  return (
    <div>
      <ScrollToTop />
      <div className='corporate-detail-banner'>
        {corporate_data.corporate_banner_type === "Video" ?
          <video
            controls={true}
            loop={true}
            muted={true}
            autoPlay={true}
          >
            <source src={corporate_data.corporate_video} type="video/mp4" />
          </video>
          :
          <img src={corporate_data.corporate_img} alt="" />
        }
      </div>
      {/* <div className="our-core-values-bnr">
        <img src={corporate_data.corporate_img} alt="" />
      </div> */}
      <div className="container container-el py-5">
        <div className="blog-detail-content">
          <h2 className='text-center'>{corporate_data.corporate_title}</h2>
          <p style={{textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: corporate_data.corporate_desc }}></p>
        </div>
      </div>
    </div>
  )
}

export default CorporateAV