import React, { useState, useEffect } from "react";
import underline from "../../assets/img/Group 442.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import globeVar from "../../GlobeApi";
import ScrollToTop from "./ScrollToTop";
import parse from 'html-react-parser';
function Term_Condition() {
  const [term_condition, setterm_condition] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post(globeVar + "term_condition").then((response) => {
      if (response.data.type === "success") {
        // console.log(response.data.data);
        setterm_condition(response.data.data);
      }
    });
  }, []);
  return (
    <div>
      <ScrollToTop />
      <section
        className="privacy-banner"
        style={{ backgroundImage: `url('${term_condition.privacy_img}')` }}
      >
        <h1 className="text-center">{term_condition.title}</h1>
        <ul className="privacy-path">
          <li>
            <Link to={`/`}>Home</Link>
          </li>
          <li> {`>`} </li>
          <li>{term_condition.title}</li>
        </ul>
      </section>

      <section className="Privacy-policy pb-5 pt-5">
        <div className="container">
          <div className="product-headeing-section container terms-underline">
            <h5>{term_condition.title}</h5>
            <img src={underline} alt="" />
          </div>
          <div className="row mt-5">
            <div
              className="col-lg-12 col-md-12 Privacy-condition tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="privacy-term">
                  {term_condition.sub_title ? parse(term_condition.sub_title) : (
                    <>

                      <p>
                        At iCottage Cart, we're dedicated to supporting small
                        business entrepreneurs like you on your journey to success.
                        Our B to C platform is designed to provide an authentic and
                        seamless experience, helping you connect with your customers
                        and grow your business. Here's what you can expect from
                        iCottage Cart:
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Authentic Connection:</b>
                      </h6>
                      <p>
                        We understand the value of authenticity in today's business
                        landscape. iCottage Cart is more than just a platform – it's
                        a community where you can showcase your unique products and
                        services to customers who appreciate and support local
                        businesses. We're committed to fostering genuine connections
                        that go beyond transactions.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>User-Friendly Experience:</b>
                      </h6>
                      <p>
                        Navigating our platform is as easy as a stroll through a
                        cottage garden. We've designed iCottage Cart with
                        user-friendliness in mind, ensuring that you can
                        effortlessly create and manage your business profile, list
                        your products or services, and interact with your customers.
                        Our intuitive interface lets you focus on what matters most
                        – your business.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Legal and Security Matters:</b>
                      </h6>
                      <p>
                        We take legality and security seriously at iCottage Cart.
                        Our terms and conditions policy is in place to safeguard
                        both your interests and the rights of your customers. We've
                        worked diligently to create a transparent and fair policy
                        that outlines the responsibilities of both parties, ensuring
                        a smooth and trustworthy business environment for everyone.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Terms and Conditions:</b>
                      </h6>
                      <p>
                        Our comprehensive Terms and Conditions policy covers
                        essential aspects of your interaction with iCottage Cart. It
                        includes information about:
                      </p>
                      <p>
                        <b>1. Account Creation:</b>
                        How to create and manage your business account on our
                        platform.
                      </p>
                      <p>
                        <b>2. Product/Service Listings:</b>
                        Guidelines for accurately listing your products or services,
                        including pricing and descriptions.
                      </p>
                      <p>
                        <b>3. Customer Interaction:</b>
                        Best practices for communicating with your customers,
                        managing orders, and addressing inquiries.
                      </p>
                      <p>
                        <b>4. Payment and Fees: </b>
                        Details about our payment processing system, fees, and
                        revenue distribution.
                      </p>
                      <p>
                        <b>5. Privacy and Data:</b>
                        How we handle your data and customer data to ensure privacy
                        and security.
                      </p>
                      <p>
                        <b>6. Intellectual Property:</b>
                        Guidelines for intellectual property rights and content
                        ownership on the platform.
                      </p>
                      <p>
                        <b>7. Termination:</b>
                        Conditions under which either party can terminate their
                        involvement on the platform.
                      </p>
                      <p>
                        By using iCottage Cart, you agree to abide by these terms,
                        which are designed to create a fair and ethical environment
                        for all users.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Transparency and Accountability:</b>
                      </h6>
                      <p>
                        We believe in transparent communication and accountability.
                        Our platform offers tools for tracking your sales,
                        monitoring customer interactions, and managing your business
                        performance. You'll have access to valuable insights that
                        can help you make informed decisions and optimize your
                        offerings.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Customer Support:</b>
                      </h6>
                      <p>
                        Should you encounter any issues or have questions, our
                        dedicated customer support team is here to assist you.
                        Whether you need help setting up your profile, managing
                        orders, or understanding the terms and conditions, we're
                        just a message away. We're committed to ensuring that your
                        experience on iCottage Cart is as smooth as possible.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Growth Opportunities:</b>
                      </h6>
                      <p>
                        At iCottage Cart, we're not just about maintaining your
                        current business – we're about helping you grow. Through
                        features such as promotion opportunities, targeted
                        marketing, and customer feedback, we provide avenues for you
                        to expand your customer base and enhance your brand
                        visibility.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Join iCottage Cart Today:</b>
                      </h6>
                      <p>
                        Embark on your journey toward success with iCottage Cart.
                        Join our community of passionate small business
                        entrepreneurs and connect with customers who appreciate your
                        authenticity. Together, we'll create a thriving marketplace
                        that celebrates the spirit of entrepreneurship.
                      </p>
                      <p>
                        Visit our website today to sign up, familiarize yourself
                        with our terms and conditions, and start showcasing your
                        offerings to the world. iCottage Cart – where authenticity
                        meets opportunity.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Legal Disclaimer:</b>
                      </h6>
                      <p>
                        Please note that while we strive to provide accurate and
                        up-to-date information, this text is intended for
                        informational purposes only and does not constitute legal
                        advice.
                      </p>
                      <p>
                        For any inquiries or assistance, feel free to reach out to
                        our customer support team at support@icottagecart.com.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Secure Transactions:</b>
                      </h6>
                      <p>
                        Your security is paramount to us. Our platform employs the
                        latest encryption and security measures to ensure that all
                        transactions are conducted safely. Whether you're making a
                        purchase or receiving payments for your products or
                        services, you can trust that your financial information is
                        protected.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Feedback and Reviews:</b>
                      </h6>
                      <p>
                        Feedback is the lifeblood of any business. iCottage Cart
                        provides a space for customers to leave reviews and feedback
                        about their experiences with your business. This not only
                        helps you build credibility but also gives you valuable
                        insights into areas where you can improve.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b> Community Engagement:</b>
                      </h6>
                      <p>
                        iCottage Cart isn't just a platform; it's a community of
                        like-minded entrepreneurs. Engage with fellow business
                        owners, share tips, collaborate, and find inspiration to
                        fuel your business growth. Our platform is designed to
                        foster connections and support networks that extend beyond
                        the digital realm.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Feedback and Reviews:</b>
                      </h6>
                      <p>
                        We're committed to continuously improving our platform to
                        meet your evolving needs. Our team is dedicated to staying
                        up-to-date with the latest trends and technologies to
                        provide you with cutting-edge tools that give you a
                        competitive edge.
                      </p>
                      <h6 className="mt-5 mb-3">
                        <b>Partner with iCottage Cart:</b>
                      </h6>
                      <p>
                        When you partner with iCottage Cart, you're not just using a
                        platform – you're becoming part of a movement to empower
                        small businesses and reshape the way business is done. Join
                        us in creating a vibrant, authentic, and thriving
                        marketplace that celebrates entrepreneurship.
                      </p>
                      <p>
                        Thank you for considering iCottage Cart as your business
                        partner. We're excited to support you on your journey to
                        success.
                      </p>
                      <p>
                        For all inquiries, partnership opportunities, and support,
                        reach out to us at info@icottagecart.com
                      </p>
                      <p>
                        Remember, authenticity is the heart of every successful
                        business. Let iCottage Cart be your canvas to showcase your
                        unique entrepreneurial spirit.
                      </p>
                      <p>
                        *Legal Disclaimer: This content is meant to provide a
                        general overview and introduction to our platform.
                      </p>
                    </>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Term_Condition;
