import React, { useState, useEffect, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import basket from "../../assets/img/homepage/basket.svg";
import like from "../../assets/img/homepage/like.png";
import img_not_available from "../../assets/img/Image_not_available.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import globeVar from "../../GlobeApi";
import ReactPaginate from "react-paginate";
import ScrollToTop from "./ScrollToTop";
import TawkTo from "./TawkTo";
import TawkTo1 from "./TawkTo1";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyContext from "../Layout/MyContext";
import fiterImage from '../../assets/img/filterimage2.svg';
import whatsapp from '../../assets/img/whatsapp-logo.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import ProductNotFound from "../../assets/img/ProductNotFound.png"
function Product() {
  const [user, setUser] = useState(null)
  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")))
    }
  }, [])
  const [producyBySearch, setproducyBySearch] = useState("");
  const [pageLoder, setpageLoder] = useState(true);
  const [productFilter, setproductFilter] = useState([]);
  const [tcs, settcs] = useState("");
  const [category, setcategory] = useState([]);
  const [regions, setregions] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [produ, setprodu] = useState();
  const location = useLocation();

  const [queryParameters] = useSearchParams();

  let navigate = useNavigate();

  function myFunction2() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
// const loder =()=>{
//     $(".loader").show();
//     document.body.style.overflow = 'hidden'
//     setTimeout(() => {
//       $(".loader").hide();
//         document.body.style.overflow = 'auto'
//     }, 1000 * 10);
//   }
//   loder();
  const region_prod = (ret_id) => {
    // setTimeout(() => {
      $('input[name="region"]')
        .filter(function () {
          return this.value === ret_id;
        })
        .prop("checked", true);
      $(".region_id").addClass("show");
      $(".region_arrow").removeClass("collapsed");
      $(".region_arrow").css("color", "#c9184a");
      $(".region_arrow").attr("aria-expanded", "true");

      filter();
      sessionStorage.removeItem("ret_id");
    // }, 2000);

  }


  const offer_pro = (id) => {
    // setTimeout(() => {
      $('input[name="ids"]')
        .filter(function () {
          return this.value === id;
        })


      filter();
      sessionStorage.removeItem("sub_id");
    // }, 2000);

  }



   
  const producr_subcategory = (id, cate_id) => {
    /*  if(location.state.id !== null){ */
    // setTimeout(() => {
      $('input[name="ids"]')
        .filter(function () {
          return this.value === id;
        })
        .prop("checked", true);
      $(".cate_id_" + cate_id).addClass("show");
      $(".arrow_" + cate_id).removeClass("collapsed");
      $(".arrow_" + cate_id).css("color", "#c9184a");
      $(".arrow_" + cate_id).attr("aria-expanded", "true");
      /* $(".arrow_" + cate_id).css("color", "red"); */

      filter();
      sessionStorage.removeItem("sub_id");
    // }, 2000);

    /*  } */
    /*  $("input[name='ids']").each(function(e){
            if($(this).val() === location.state.id){
                $(this).attr("checked", "checked");
            }
        }); */
  };
  function product_by_tcs(name) {
    setprodu("");
    if (name === "Trending") {
      // setTimeout(() => {
        // {
          $("#sort_by").val("Trending");
          axios.post(globeVar + "trending_product").then((response) => {
            setpageLoder(false);
            if (response.data.type === "success") {
              // console.log(response.data.data);
              setproductFilter(response.data.data)
            }

          });
          sessionStorage.removeItem("functionality");
        // }
      // }, 2000);
    } else if (name === "Subscriptions") {
      // setTimeout(() => {

        $('input[name="subscribe"]')
          .filter(function () {
            return this.value === "subscribe";
          })
          .prop("checked", true);
        $(".subscribe").addClass("show");
        $(".subscribe_arrow").removeClass("collapsed");
        $(".subscribe_arrow").attr("aria-expanded", "true");

        sessionStorage.removeItem("sub_id");
        axios.post(globeVar + "product_subscription").then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            // console.log(response.data.data);
            setproductFilter(response.data.data);
          }
        });
        sessionStorage.removeItem("functionality");
      // }, 2000);
    } else if (name === "Combos") {
      // setTimeout(() => {
        $("#sort_by").val("");
        $('input[name="combo"]')
          .filter(function () {
            return this.value === "combo";
          })
          .prop("checked", true);
        $(".combo").addClass("show");
        $(".combo_arrow").removeClass("collapsed");
        $(".combo_arrow").attr("aria-expanded", "true");

        axios.post(globeVar + "combo_offer").then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            // console.log(response.data.data);
            setproductFilter(response.data.data);
          }
        });
        sessionStorage.removeItem("functionality");
      // }, 2000);
    } else if (name === "Popular") {
      // setTimeout(() => {
        $("#sort_by").val("Popular");
        axios.post(globeVar + "product_popular").then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            // console.log(response.data.data);
            setproductFilter(response.data.data);
          }
        });
        sessionStorage.removeItem("true");
      // }, 2000);
    }
  }



  const combo = (name) => {
    setprodu("");
    // setTimeout(() => {
      $("#sort_by").val("");
      $('input[name="combo"]')
        .filter(function () {
          return this.value === "combo";
        })
          .prop("checked", true);
      $(".combo").addClass("show");
      $(".combo_arrow").removeClass("collapsed");
      $(".combo_arrow").attr("aria-expanded", "true");
      setActiveNavItem("Combos");

      axios.post(globeVar + "combo_offer").then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          // console.log(response.data.data);
          setproductFilter(response.data.data);
        }
      });
      sessionStorage.removeItem("Combo Offer");
      

    // }, 2000);
  }
  const subscription = (name) => {
    setprodu("");
    // setTimeout(() => {

      $('input[name="subscribe"]')
        .filter(function () {
          return this.value === "subscribe";
        })
         .prop("checked", true);
      $(".subscribe").addClass("show");
      $(".subscribe_arrow").removeClass("collapsed");
      $(".subscribe_arrow").attr("aria-expanded", "true");
      setActiveNavItem("Subscriptions");

      sessionStorage.removeItem("sub_id");
      axios.post(globeVar + "product_subscription").then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          // console.log(response.data.data);
          setproductFilter(response.data.data);
        }
      });
      sessionStorage.removeItem("true");
    // }, 2000);
  } 
  
  function product_by_Cate(cate_id) {
    setprodu("");
    // setTimeout(() => {
      $(".cate_id_" + cate_id).addClass("show");
      $(".arrow_" + cate_id).removeClass("collapsed");
      $(".arrow_" + cate_id).css("color", "#c9184a");
      $(".arrow_" + cate_id).attr("aria-expanded", "true");

      let item = { cat_id: cate_id };
      axios.post(globeVar + "category_product", item).then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          setproductFilter(response.data.data);
          response.data.data.forEach((tem) => {
            $('input[name="ids"]')
              .filter(function () {
                return this.value === tem.product_sub_category;
              })
              .prop("checked", true);
          });
        }
      });
      sessionStorage.removeItem("cate_id");
    // }, 3000);
  }



  const category_list = () => {
    //api of therapist
    if (sessionStorage.getItem("user")) {
      var data = sessionStorage.getItem("user");
      data = JSON.parse(data);
      const item = { userId: data._id };
      axios.post(globeVar + "get_Wishlist", item).then((response) => {
        // setpageLoder(false);
        if (response.data.type === "success") {
          var wishlist_id = [];
          response.data.wishlist.products.forEach((rev) => {
            wishlist_id.push(rev.productId);
          });
          // console.log(wishlist_id);
          setwishlist(wishlist_id);
        }
      });
    }
    axios.post(globeVar + "category_list").then((response) => {
      // setpageLoder(false);
      if (response.data.type === "success") {
        setcategory(response.data.data);
      }
    });

    axios.post(globeVar + "cms_region").then((response) => {
      // setpageLoder(false);
      if (response.data.type === "success") {
        setregions(response.data.data);
      }
    });
    // after 24 8 2023 change
    // axios.post(globeVar + "product_listing").then((response) => {
    //   setpageLoder(false);
    //   if (response.data.type === "success") {
    //     setproductFilter(response.data.data);
    //   }
    // });
    // after 24 8 2023 change
  };

  function changeIcon(anchor, productId) {
    if (sessionStorage.getItem("user")) {
      var icon = anchor.target;
      // console.log(icon);
      icon.classList.toggle("icon-wishlist");
      icon.classList.toggle("icon-wishlist-fill");

      const item = { userId: user._id, productId: productId };
      axios.post(globeVar + "add_to_wishlist", item).then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          var wishlist_id = [];
          if (response.data.newWish) {
            response.data.newWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          } else if (response.data.updatedWish) {
            response.data.updatedWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          }
          var x = wishlist.includes(productId);
          if (x === true) {
            toast.success('Removed from Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: '#c9184a' },
            });
          } else {
            toast.success('Added to Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: '#c9184a' },
            });
          }
          setwishlist(wishlist_id);
        }
      });
    } else {
      window.$("#exampleModalToggle").modal("show");
      localStorage.setItem("wishlist", JSON.stringify(productId));
    }
  }


  function products() {
    $("#sort_by").val("");
    $('input[name="ids"]').prop("checked", false);
    $('input[name="region"]').prop("checked", false);
    $('input[name="subscribe"]').prop("checked", false);
    $('input[name="combo"]').prop("checked", false);

    axios.post(globeVar + "product_listing").then((response) => {
      setpageLoder(false);
      if (response.data.type === "success") {
        setproductFilter(response.data.data);
      }
    });
  }
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const skeletonTheme = [1, 2, 3, 4, 5, 6].map((rec) => {
    return (
      <div className="popular-slider-row col-lg-4 col-md-4 col-6">
        <SkeletonTheme highlightColor="#fff2f6">
          <div className="card ">
            <Skeleton className="Skeleton-img" />
            <div className="card-body">
              <h4>
                {" "}
                <Skeleton />
              </h4>
              <span>
                <Skeleton />
              </span>
              <span>
                <Skeleton />
              </span>
            </div>
          </div>
        </SkeletonTheme>
      </div>
    );
  });

  const displayUsers = productFilter.length > 0 ? (productFilter.map((rec) => {
    return (
      <div
        className="popular-slider-row col-lg-4 col-md-4  col-6"
        key={rec._id}
      >
        <div className="card ">
          {user?.role !== "retailer" && rec.discount ? (
            <span className="price-tag">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="69.521"
                height="74.698"
                viewBox="0 0 69.521 74.698"
              >
                <defs>
                  <filter
                    id="_281639d9032fd35ab631d5064a2c1da9"
                    x="0"
                    y="5.177"
                    width="69.521"
                    height="69.521"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2" result="blur" />
                    <feFlood floodColor="#7a6b6b" floodOpacity="0.361" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Component_59_1"
                  data-name="Component 59 – 1"
                  transform="translate(6.865 0.507)"
                >
                  <g
                    id="Group_272"
                    data-name="Group 272"
                    transform="translate(-357.104 -1330.704)"
                  >
                    <g
                      id="Group_269"
                      data-name="Group 269"
                      transform="translate(278 -41)"
                    >
                      <g id="Group_268" data-name="Group 268">
                        <g
                          id="Group_267"
                          data-name="Group 267"
                          className="discount"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                            filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                          >
                            <path
                              id="_281639d9032fd35ab631d5064a2c1da9-2"
                              data-name="281639d9032fd35ab631d5064a2c1da9"
                              d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                              transform="translate(5.45 35.39) rotate(-45)"
                              fill="#fff1f5"
                              stroke="#ee7e9e"
                              strokeWidth="1"
                              className="rotate-tag"
                            />
                          </g>
                          <text
                            id="_15_"
                            className="_15_"
                            data-name="15%"
                            transform="translate(98 1413)"
                            fontSize="12"
                            fontFamily="Comfortaa-Bold, Comfortaa"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              {rec.discount}%
                            </tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Group_270"
                      data-name="Group 270"
                      className="hook"
                      transform="translate(277.674 -41)"
                    >
                      <path
                        id="Path_156"
                        data-name="Path 156"
                        d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                        transform="translate(1997.472 4421.696)"
                        fill="none"
                        stroke="#bbb"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_155"
                        data-name="Path 155"
                        d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                        transform="translate(1980.552 4427.111)"
                        fill="none"
                        stroke="#bbb"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          ) : (
            ""
          )}

          <Link to={`/product-cart/${rec._id}`}>
            {rec.prod_gal == "Video" ? (
              <img
                src={
                  rec.video_thambnail ? rec.video_thambnail : img_not_available
                }
                className="card-img-top"
                alt="..."
                onError={(e) => {
                  // console.log("2", e);
                  e.target.onerror = null; // prevents looping
                  e.target.src =
                    "../../assets/img/homepage/Image_not_available.png";
                }}
              />
            ) : (
              <img
                src={rec.product_img ? rec.product_img : img_not_available}
                className="card-img-top"
                alt="..."
                onError={(e) => {
                  // console.log("2", e);
                  e.target.onerror = null; // prevents looping
                  e.target.src =
                    "../../assets/img/homepage/Image_not_available.png";
                }}
              />
            )}
            {/* <img src={rec.product_img} className="card-img-top" alt="..." /> */}
          </Link>
          <button className="like-btn border-0 bg-transparent">
            {wishlist.length > 0 ? (
              wishlist.includes(rec._id) ? (
                <span
                  className="icon-wishlist-fill"
                  onClick={(e) => changeIcon(e, rec._id)}
                ></span>
              ) : (
                <span
                  className="icon-wishlist"
                  onClick={(e) => changeIcon(e, rec._id)}
                ></span>
              )
            ) : (
              <span
                className="icon-wishlist"
                onClick={(e) => changeIcon(e, rec._id)}
              ></span>
            )}
          </button>
          <div className="card-body">
            {rec.tags === "Trending" ? (
              <div className="flag1">
                <span className="flag-text">{rec.tags}</span>
              </div>
            ) : rec.tags === "Popular" ? (
              <div className="flag2">
                <span className="flag-text">{rec.tags}</span>
              </div>
            ) : rec.tags === "New Arrivals" ? (
              <div className="flag3">
                <span className="flag-text">{rec.tags}</span>
              </div>
            ) : rec.tags === "Combo Offer" ? (
              <div className="flag4">
                <span className="flag-text">{rec.tags}</span>
              </div>
            ) : rec.tags === "Subscription" ? (
              <div className="flag5">
                <span className="flag-text">{rec.tags}</span>
              </div>
            ) : (
              <div className="flag6">
                <span className="flag-text">{rec.tags}</span>
              </div>
            )}
            <h4>{rec.product_name}</h4>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                {user && user?.role === "retailer" ? "₹" + rec?.retailerPrice + " ( CP : "  :""} 
                ₹{rec.saving ? rec.price - rec.saving : rec.price}{" "}
                {user?.role !== "retailer" && rec.discount ? <del className="ms-1">₹{rec.price}</del> : ""}
                {user && user?.role === "retailer" ? ")" :""}
              </span>

              <button
                onClick={() => add_card(rec._id)}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={basket} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>

    ) 
  } )) : (<article style={{ textAlign: "center", marginTop: "70px" }}>
                    <img className="h-25" src={ProductNotFound} alt="product not found" />
                    <h1>No Product Found</h1>
                    <p>Please try again with some different keywords.</p>
                  </article>);

  const pageCount = Math.ceil(productFilter.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const { activeNavItem, setActiveNavItem } = useContext(MyContext);
  function filter(sort_by) {
    setprodu("");
    // console.log("filter",sort_by);
    setpageLoder(false)
    /* sessionStorage.setItem("sort", name);
        setsortBy(sessionStorage.getItem("sort")) */
    var subscriptions = "";
    var ids = [];
    var key = queryParameters.get("data");

    if(sessionStorage.getItem('sub_id')){
      ids.push(sessionStorage.getItem('sub_id'));
    }

    $("input[name='ids']:checked").each(function () {
      ids.push($(this).val());
    });

    var regions = [];

    if(sessionStorage.getItem('ret_id')){
      regions.push(sessionStorage.getItem('ret_id'));
    }
    
    $("input[name='region']:checked").each(function () {
      regions.push($(this).val());
    });


    const subscription = $("input[name='subscribe']:checked").val();
    if (subscription) {
      subscriptions = "true";
      if (sort_by === "subscribe") {
        $('input[name="combo"]').prop("checked", false);
        setActiveNavItem("Subscriptions");
        $('#sort_by').val('');
        settcs(subscription);
        settcs("Subscriptions", subscription);
        sessionStorage.setItem("tcs", subscription);
      }

    } else {
      subscriptions = "false";
      setActiveNavItem(null);
    }


    const combo = $("input[name='combo']:checked").val();
    regions = [...new Set(regions)];
    var item = {};
    if (sort_by !== "combo" && sort_by !== "subscribe" && sort_by !== undefined) {
      item = {
        ids: ids,
        subscription: subscriptions,
        region: regions,
        sort_by: sort_by,
        key: key
      };
    } else if (combo) {
      $('#sort_by').val('');
      if (sort_by === "combo") {
        $('input[name="ids"]').prop("checked", false);
        $('input[name="region"]').prop("checked", false);
        $('input[name="subscribe"]').prop("checked", false);
        setActiveNavItem("Combos");
        settcs(combo);
        settcs("Combos", combo);
        sessionStorage.setItem("tcs", combo);
      }

      item = { combo: "Combo Offer" };
    } else {
      if ($("#sort_by").val() === "") {
        item = { ids: ids, subscription: subscriptions, region: regions };
      } else {
        item = { ids: ids, subscription: subscriptions, region: regions, sort_by: $("#sort_by").val() };
      }
    }
    // console.log(item);
    axios.post(globeVar + "product_filter", item).then((response) => {
      // console.log("filter_item",item);
      setpageLoder(false);
      if (response.data.type === "success") {
        setproductFilter(response.data.data);

      }
      // getState(state);
    });
  }
  const add_card = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = user._id;
      const product_data = id;
      const product_qty = 1;
      let items = { productId: product_data, quantity: parseInt(product_qty) };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          toast.success("Added to Cart", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: '#c9184a' },
          });
          /* window.location.href = "/product"; */
        }
      });
    } else {
      const product_data = id;
      const product_qty = 1;
      /* if(!localStorage.getItem("cart")){
            var cart =[];
            localStorage.setItem("cart", JSON.stringify(cart))
            }
            var cart = JSON.parse(localStorage.getItem("cart")); */
      var items = {};
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
        };
      } else {
        items = { productId: product_data, quantity: parseInt(product_qty) };
      }
      /*  cart.push(items);
            localStorage.setItem("cart", JSON.stringify(cart) )
            window.location.href="/my-cart"; */
      // console.log(items);
      axios.post(globeVar + "addtocart", items).then((response) => {
        setpageLoder(false);
        if (response.data.type === "success") {
          // console.log(response.data);
          if (!response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          } else {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }
          toast.success("Added to Cart", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: '#c9184a' },
          });
          /* window.location.href = "/product"; */
        }
      });
    }
  };
  const filter_by_discount = (data) => {
    // console.log(data);
    if (data.category_id) {
      // setTimeout(() => {
        const itemss = {
          category_id: data.category_id,
          discount: data.discount,
        };
        axios.post(globeVar + "product_filter", itemss).then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            // console.log(response.data.data);
            setproductFilter(response.data.data);
          }
        });
      // }, 500);
    }
    if (data.combo_id) {
      // setTimeout(() => {
        const items = { combo_id: data.combo_id };
        axios.post(globeVar + "product_filter", items).then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            setproductFilter(response.data.data)
          }
        });
      // }, 500);
    }
  };
  useEffect(() => {

    window.scrollTo(0, 0);
    category_list();
    setTimeout(() => {

    // axios.post(globeVar + "product_listing").then((response) => {
    //   setpageLoder(false);
    //   if (response.data.type === "success") {
    //     setproductFilter(response.data.data);
    //   }
    // });
      /* const optionMenu = document.querySelector(".select-menu");
          var selectBtn = optionMenu.querySelector(".select-btn");
          var options = optionMenu.querySelectorAll(".option");
          var sBtn_text = optionMenu.querySelector(".sBtn-text");
          selectBtn.addEventListener("mouseover", () => optionMenu.classList.add("active"));
          options.forEach(option =>{
             option.addEventListener("click", ()=>{
                 let selectedOption = option.querySelector(".option-text").innerText;
                 sBtn_text.innerText = selectedOption;
                 optionMenu.classList.remove("active");
             });
          }); */
      const sub_id = sessionStorage.getItem("sub_id");
      // console.log(sub_id);
      if (sub_id !== null) {
        const cate_sub_id = sessionStorage.getItem("cate_sub_id");
        producr_subcategory(sub_id, cate_sub_id);
      }

      const cate_id = sessionStorage.getItem("cat_id");
      // console.log(cate_id);
      if (cate_id !== null) {
        offer_pro(cate_id);
      }

      const ret_id = sessionStorage.getItem("ret_id");
      // console.log('ret_id',ret_id);
      if (ret_id !== null) {
        region_prod(ret_id);
      }

      const functionality1 = sessionStorage.getItem("true");
      // console.log(functionality1);
      if (functionality1 !== null) {
        subscription(functionality1);
        settcs(functionality1);
        settcs("Subscriptions", functionality1);
        sessionStorage.setItem("tcs", functionality1);
      }

      const functionality2 = sessionStorage.getItem("Combo Offer");
      // console.log(functionality2);
      if (functionality2 !== null) {
        combo(functionality2);
        settcs(functionality2);
        settcs("Combo", functionality2);
        sessionStorage.setItem("tcs", functionality2);
      }

      const functionality = sessionStorage.getItem("functionality");
      // console.log(functionality);
      if (functionality !== null) {
        product_by_tcs(functionality);
        settcs(functionality);
        sessionStorage.setItem("tcs", functionality);
      }

      

      const category_id = sessionStorage.getItem("cate_id");
      // console.log(category_id);
      if (category_id !== null) {
        product_by_Cate(category_id);
      }

      var data = queryParameters.get("data");
      if (data !== null) {
        location.state = { product_data: data }
      }
      // console.log(location.state);
      setprodu(data);
      if (location.state !== null) {

        if (location.state.product_data) {
          var idss = [];
          $("input[name='ids']:checked").each(function () {
            idss.push($(this).val());
          });

          var regionss = [];
          $("input[name='region']:checked").each(function () {
            regionss.push($(this).val());
          });

          const subscriptionss = $("input[name='subscribe']:checked").val();
          const comboss = $("input[name='combo']:checked").val();
          const sort_byss = $("#sort_by").val();

          if (idss.length === 0 && regionss.length === 0 && !subscriptionss && !comboss && sort_byss == "") {
            // setTimeout(() => {

              const item = { 
                key: location.state.product_data,
                ids: idss,
                region: regionss,
              };
              axios.post(globeVar + "product_filter", item).then((response) => {
                setpageLoder(false);
                if (response.data.type === "success") {
                  setproductFilter(response.data.data);
                }
              }).catch((error) => {
                // console.log(error);
              });
            // }, 2000);
          }
        }


        // products();
        // console.log("hello");


        filter_by_discount(location.state);
        if (location.state.cate_id) {
          producr_subcategory(location.state.id, location.state.cate_id);
        }
      }
      

      if( location.state === null &&  data === null && category_id === null && functionality === null && functionality2 === null && functionality1 === null && ret_id === null && cate_id === null && sub_id === null){
        // console.log((data === null));
        axios.post(globeVar + "product_listing").then((response) => {
          setpageLoder(false);
          if (response.data.type === "success") {
            setproductFilter(response.data.data);
          }
        });
      }
    }, 4000);
  }, []);
  return (


    <div>
      <ScrollToTop />
      {/* <TawkTo /> */}
      {/* <TawkTo1 /> */}
      <a href="https://api.whatsapp.com/send/?phone=919096099303&text=Hi">
        <img
          src={whatsapp}
          alt=""
          style={{
            height: "60px",
            position: "fixed",
            bottom: "2%",
            left: "2%",
            zIndex: "1000",
            margin: "0px",
          }}
        />
      </a>
      <ToastContainer style={{ width: "fit-content", marginTop: "3%" }} />
      <section className="products">
        <div className="container">
          <nav
            style={{ "--bs-breadcrumb-divider": "'>'" }}
            aria-label="breadcrumb"
            className="products-first"
          >
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {tcs ? (
                <li className="breadcrumb-item" aria-current="page">
                  <Link to="/product">{tcs}</Link>
                </li>
              ) : (
                <li className="breadcrumb-item" aria-current="page">
                  <Link to="/product">Product</Link>
                </li>
              )}
            </ul>
          </nav>

          <div className="row products-second">
            <div className="col-lg-3  navbar-expand-lg  product-range">
              <div className="filters Filters-d-none">
                <h4>Filters</h4>
                <span onClick={() => products()} style={{ cursor: "pointer" }}>
                  Clear All
                </span>
              </div>
              <div className="filters Filters-d-block">
                <button
                  onClick={myFunction2}
                  className="navbar-toggler collapsed border-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <button className="filter-btn">
                    <img src={fiterImage} alt="" className="pe-2" />
                    Filters
                  </button>
                </button>
              </div>
              <div
                className="category pdt-ctg-sec collapse offcanvas navbar-collapse offcanvas-start d-flex d-lg-block"
                id="offcanvasWithBothOptions"
                data-bs-scroll="true"
                tabindex="-1"
                aria-labelledby="offcanvasWithBothOptionsLabel"
              >
                <p className="Filters-d-none">CATEGORY</p>
                <div className="offcanvas-header ">
                  <h3>Filters</h3>
                  <button
                    onClick={myFunction2}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-header ">
                  <h4 className="m-0">Category</h4>
                  <span
                    onClick={() => products()}
                    style={{ cursor: "pointer" }}
                  >
                    Clear All
                  </span>
                </div>
                <div className="accordion px-lg-2" id="accordionExample">
                  {category.map((rex, i) => {
                    return (
                      <div
                        className="accordion-item px-lg-0 Handmade"
                        key={rex._id}
                      >
                        <h2
                          className="accordion-header"
                          id={"heading_" + i + 1}
                        >
                          <button
                            className={
                              "collapsed accordion-button arrow_" + rex._id
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse_" + i + 1}
                            aria-expanded="false"
                            aria-controls={"collapse_" + i + 1}
                          >
                            {rex.category_name}
                          </button>
                        </h2>
                        <div
                          id={"collapse_" + i + 1}
                          className={
                            "accordion-collapse collapse cate_id_" + rex._id
                          }
                          aria-labelledby={"heading_" + i + 1}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <ul>
                              {rex.sub_data.map((rev) => {
                                return (
                                  <li key={rev._id}>
                                    <input
                                      type="checkbox"
                                      name="ids"
                                      id={rev._id}
                                      value={rev._id}
                                      onChange={(e) => filter()}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={rev._id}
                                    >
                                      <span className="ms-2">
                                        {rev.subcategory_name}
                                      </span>
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <hr />
                  <div className="accordion-item px-lg-0 price">
                    <h2
                      className="accordion-header subscriptions"
                      id="heading_sub"
                    >
                      <button
                        className="accordion-button collapsed subscribe_arrow"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_sub"
                        aria-expanded="false"
                        aria-controls="collapse_sub"
                        style={{ color: "#c9184a" }}
                      >
                        <span className="blink"> SUBSCRIPTIONS</span>
                      </button>
                    </h2>
                    <div
                      id="collapse_sub"
                      className={"accordion-collapse collapse subscribe"}
                      aria-labelledby="heading_sub"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="mt-3">
                          <li>
                            <input
                              type="checkbox"
                              name="subscribe"
                              id="subscribe"
                              value="subscribe"
                              onChange={(e) => filter("subscribe")}
                            />
                            <span className="ms-2">Subscribe & Save</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <hr/>
                            <div className="accordion-item price">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive" aria-expanded="false"
                                        aria-controls="collapseFive">
                                        PRICE
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className="mt-3">
                                            <li><input type="checkbox" name='price' value="100" onChange={(e) =>filter()} /><span className="ms-2">₹0 - ₹100</span></li>
                                            <li><input type="checkbox"  name='price' value="200" onChange={(e) =>filter()}/><span className="ms-2">₹101 - ₹200</span></li>
                                            <li><input type="checkbox" name='price' value="500" onChange={(e) =>filter()} /><span className="ms-2">₹201 - ₹500</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="accordion-item price">
                                <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        DISCOUNT
                                    </button>
                                </h2>
                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className="mt-3">
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹500 (71)</span></li>
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹1,000 (71)</span></li>
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹1,999 (71)</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="accordion-item price">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseSeven" aria-expanded="false"
                                        aria-controls="collapseSeven">
                                        CUSTOMER RATINGS
                                    </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse collapse"
                                    aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className="mt-3">
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹500 (71)</span></li>
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹1,000 (71)</span></li>
                                            <li><input type="checkbox" /><span className="ms-2">₹0 - ₹1,999 (71)</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                  <hr />
                  <div className="accordion-item px-lg-0  price combos">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed combo_arrow"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <span className="blink2">COMBOS</span>
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse combo"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="mt-3">
                          <li>
                            <input
                              type="checkbox"
                              name="combo"
                              value="combo"
                              onChange={(e) => filter("combo")}
                            />
                            <span className="ms-2">Combo</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="accordion-item px-lg-0 price">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed region_arrow"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        SHOP BY REGIONS
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse region_id"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="mt-3">
                          {regions.map((data) => {
                            return (
                              <li key={data._id}>
                                <input
                                  type="checkbox"
                                  name="region"
                                  value={data._id}
                                  onChange={(e) => filter()}
                                />
                                <span className="ms-2">
                                  {data.region_title}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 product-range-first">
              <div className="row justify-content-between product-section ">
                {/* filter and produts count */}
                <div className="d-sm-flex justify-content-between p-0">
                  <div className=" product-section-first d-flex">
                    <h4>
                      Showing Products <span>({productFilter.length}) </span>{" "}
                      {produ}
                    </h4>
                  </div>
                  {/*  sort product section */}
                  <div className="select-menu">
                    <span className="sort">Sort By :</span>
                    <select
                      className="sort-options"
                      name="sort_by"
                      id="sort_by"
                      onChange={(e) => filter(e.target.value)}
                    >
                      <option value="none" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                      <option value="">All</option>
                      <option value="none" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                      <option value="Recommended">Recommended</option>
                      <option value="none" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                      <option value="Popular">Popular</option>
                      <option value="none" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                      <option value="Trending">Trending</option>
                      <option value="none" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                      {/* <option value="Combo Offer">Combo Offers</option>
                      <option value="" style={{ fontSize: "2pt" }} disabled>
                        &nbsp; 
                      </option>*/}
                      <option value="New Arrivals">Newest Arrivals</option>
                      <option value="" style={{ fontSize: "2pt" }} disabled>
                        &nbsp;
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row popular-section product-popular gx-5 product_data">
                {/* <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
                {producyBySearch !== "yes" ? (
                  pageLoder ? (
                    skeletonTheme
                  ) : (
                    displayUsers
                  )
                ) : (
                  <article style={{ textAlign: "center", marginTop: "70px" }}>
                    <img className="h-25" src={ProductNotFound} alt="product not found" />
                    <h1>No Result Found</h1>
                    <p>Please try again with some different keywords.</p>
                  </article>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Product;
