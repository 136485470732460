import React, { useState, useEffect } from 'react'
import regions_underline from "../../assets/img/Group 444.svg";
import doubleQuote from "../../assets/img/Testimonial/left-quotes-sign.svg"
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from 'axios';
import globeVar from '../../GlobeApi';

const VoiceOfCustomers = () => {
  const [customer_review, setcustomer_review] = useState([]);

  useEffect(() => {
    axios.post(globeVar + "testimonial").then((response) => {
      if (response.data.type === "success") {
        setcustomer_review(response.data.data);
      }
    })
  }, [])
  
  return (
    <section className="testimonial-slider-section">
      <div className="container container-el">
        <div className="region_underline">
          <h5>Voice of Customers</h5>
          <img src={regions_underline} alt="" />
        </div>
        <Swiper className="mySwiper"

          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}

          breakpoints={{
            255: {
              slidesPerView: 1,
              // spaceBetween: 20,
            },
            992: {
              slidesPerView: 2,
              // spaceBetween: 40,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 60,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}>

          {customer_review.map(data => {
            return <SwiperSlide className="d-flex justify-content-center" key={data._id}>
              <div className="testimonial-box">
                <div className="testimonial-imgBox">
                  <img src={data.avtar} alt="" />
                </div>
                <div className="testimonial-contentBox d-flex flex-column">
                  <div className="testimonial-name d-flex justify-content-sm-end align-items-center justify-content-between">
                    <div className="testimonial-information">
                      <h4>{data.fullname}</h4>
                      <span>{data.city}, {data.state}</span>
                    </div>
                    <div className="testimonial-rating d-flex justify-content-center align-items-center">
                      <span className="icon-starfill star-rating"></span>
                      <span>{data.ratings}</span>
                    </div>
                  </div>
                  <div className="testimonial-reviews">
                    <div className="comment-quote">
                      <img src={doubleQuote} alt="" />
                    </div>
                    <div className="testimonial-profile-detail">
                      <h6>{data.title}</h6>
                      <p>{data.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default VoiceOfCustomers