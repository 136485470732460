import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import axios from 'axios';
import globeVar from '../../GlobeApi';

const OfferBanner = () => {
    const [mobileban, setmobileban] = useState([]);
    useEffect(() => {
        axios.post(globeVar + "mobile_banners").then((response) => {
        //alert("d");
            if (response.data.type === "success") {
                // alert("dd");
                //console.log(response.data.data.mobile_banner_img);
                setmobileban(response.data.data.mobile_banner_img);
                // setcategory(response.data.mobile_banner_img);

            }
        });
    }, [])
    return (
        <section className="home-slider">
            <div className="container container-el">

            <div className="row">
                <div className="">
                <Swiper className="py-4" modules={[Autoplay, Pagination]}
                    autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    slidesPerView: 1,
                    slidesPerGroup: 1
                    }}
                    // install Swiper modules
                    breakpoints={{
                    0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1
                    },
                    520: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    950: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    1000: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    1200: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    1400: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },

                    }}
                    /* loop={true} */
                    centerslide="false"
                    effect={"fade"}
                    grabCursor={true}
                    spaceBetween={30}
                    slidesPerView={4}

                    pagination={{ clickable: true }}
                    navigation={{
                    nextEl: ".button2",
                    prevEl: ".button1",
                    }}

                >

                    <div className="swiper-wrapper ">
                    {mobileban.map((valued, key) => {
                        return (
                        <SwiperSlide >
                            <img src={valued} alt='banner' className="img-fluid border-radius-20" />
                        </SwiperSlide>
                        );
                    })}

                    { /* <SwiperSlide >
                        <img src={homeSlider1} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={homeSlider1} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={homeSlider1} className="img-fluid" />
                    </SwiperSlide> */}


                    </div>
                    {/* </OwlCarousel> */}
                </Swiper>
                </div>

            </div>
            </div>
        </section>
    )
}

export default OfferBanner