import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import event_underline from "../../assets/img/Group 1358.svg";
import globeVar from '../../GlobeApi';
import axios from 'axios';
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Events = () => {
  const [event_list, setevent_list] = useState([]);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleWindowResize = () => {
    window.innerWidth >= 500 ? setIsLaptop(true) : setIsLaptop(false);
    window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  useEffect(() => {
    handleWindowResize();
    axios.post(globeVar + "event_list").then((response) => {
      if (response.data.type === "success") {
        setevent_list(response.data.data);
      }
    })
  }, [])
  
  return (
    <>
      {!isMobile && (
      <section className="event-section">
        <div className="container container-el">

          <div className="row d-flex product-headeing-section justify-content-between shop_underline ">
            <div className="col-4">
              <div>
                <h5>Events</h5>
                <img src={event_underline} alt="" className="event-underline" />
              </div>
            </div>
            <div className="col-4 text-right">
              <Link to={`/event`}>  <li
                className="list-group-item view-all-btn pt-3"
                style={{
                  float: "right",
                  color: "#c9184a",
                  fontSize: "14px",
                  cursor: "pointer",
                }}

              >
                View All
              </li>
              </Link>
            </div>
          </div>
          <div className="event-page">
            <div className="grid-container">
              {event_list.slice(0, 4).map(data => {
                return <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative" key={data._id}  >
                  {/* <div className="event-top-text d-flex justify-content-between">
                  <h6>{data.event_title}</h6>
                  <span>{data.event_date}</span>
                </div> */}
                  <img src={data.event_img} alt="" className='img-fluid' />
                  <div className="openingEvent-bottom-text d-flex flex-column  align-items-center">
                    <p className="w-100" dangerouslySetInnerHTML={{ __html: data.event_desc }}></p>
                    {data.iseventdetail === "true" && <Link to={`/event_detail/${data._id}`}><button>Read More</button></Link>}
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </section>)}

      {!isLaptop && (<section className="home-slider event-section">
        <div className="container container-el">
          <div className="row d-flex product-headeing-section justify-content-between shop_underline ">
            <div className="col-4">
              <div>
                <h5>Events</h5>
                <img src={event_underline} alt="" className="event-underline" />
              </div>
            </div>
            <div className="col-4 text-right">
              <Link to={`/event`}>  <li
                className="list-group-item view-all-btn pt-3"
                style={{
                  float: "right",
                  color: "#c9184a",
                  fontSize: "14px",
                  cursor: "pointer",
                }}

              >
                View All
              </li>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="">
              <Swiper className="py-4" modules={[Autoplay, Pagination]}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  slidesPerView: 1,
                  slidesPerGroup: 1
                }}
                // install Swiper modules
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  950: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1000: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1200: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1400: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },

                }}
                /* loop={true} */
                centerslide="false"
                effect={"fade"}
                grabCursor={true}
                spaceBetween={30}
                slidesPerView={4}

                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".button2",
                  prevEl: ".button1",
                }}
              >
                <div className="swiper-wrapper ">
                  {event_list.slice(0, 4).map((data, key) => {
                    return (
                      <SwiperSlide key={key}>
                        <img src={data.event_img} alt='banner' className="img-fluid border-radius-20" style={{
                          objectFit: "cover",
                          height: "300px",
                          width: "100%"
                        }} />
                      </SwiperSlide>
                    );
                  })}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>)}
    </>
  )
}

export default Events