import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import underline from "../../assets/img/Group 442.svg";
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
import { Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


function EventDetail() {
  const { id } = useParams();
  const [event_data, setevent_data] = useState({})
  const [event_list, setevent_list] = useState([])
  const [event_gallary, setevent_gallary] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);

    const item = { event_id: id }
    axios.post(globeVar + "event_detail", item).then((response) => {
      if (response.data.type === "success") {
        setevent_data(response.data.data);
        setevent_list(response.data.list);
        setevent_gallary(response.data.data.event_gallery)
      }
    })
  }, []);

  function handleClick(id) {
    const item = { event_id: id }
    axios.post(globeVar + "event_detail", item).then((response) => {
      if (response.data.type === "success") {
        setevent_data(response.data.data);
        setevent_list(response.data.list);
        setevent_gallary(response.data.data.event_gallery)
      }
    })
    window.location.href = '/event_detail/'+id
  }

  return (
    <div>
      <ScrollToTop />
      <section className='eventdtl-bnr-section'>
        <div className="evnt-dtl-bnr d-flex justify-content-center align-items-center">
          {/* <img src={eventDetailBanner} alt="" /> */}
          {event_data.event_banner_type === "Video" ?
            <video
              controls={true}
              loop={true}
              muted={true}
            >
              <source src={event_data.event_video} type="video/mp4" />
            </video>
            :
            <img src={event_data.event_img} alt="" />
          }
          {/* <video src={eventDetailBnrVdo} controls></video> */}
        </div>
      </section>
      <section className='event-dtl-content'>
        <div className="container container-el">
          <div className="blog-event-h2">
            <h2 className='text-center'>"<span>{event_data.event_title}</span>{/* : {event_data.event_notes} */}"</h2>
          </div>
          <div className="row event-dtl-cnt-row">
            <div className="col-md-6 para-height">
              <p dangerouslySetInnerHTML={{ __html: event_data.event_desc }}></p>

            </div>
            <div className="col-md-6">
              <div className="guest-imput-dtl"><marquee>{event_data.event_guests}</marquee></div>
              <div className="guest-imput-dtl my-4">{event_data.event_location}</div>
              <div className="guest-imput-dtl">{event_data.event_date}</div>
              <div className="guest-imput-dtl my-4">{event_data.event_attendees}</div>
              <div className="guest-imput-dtl">{event_data.event_notes}</div>
            </div>
          </div>
          <div className="event-dtl-glry">
            {event_gallary.map((rec, i) => {
              return <div className="evntDtl-glry-item" key={i}>
                <img src={rec} alt="" className='evntDtl-glry-img' />
              </div>
            })}

          </div>
          <div className="explore-other-blogs">
            <div className="row shop_underline event-details_underline">
              <h5>Explore Other Events</h5>
              <img src={underline} alt="" />
            </div>
            <div className='grid-container2'>
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  950: {
                    slidesPerView: 2,
                    slidesPerGroup: 3
                  },
                  1000: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                  },

                  1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                  },
                }}
                pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper" slidesPerView={3} spaceBetween={10}>
                {event_list.map(data => {
                  return <SwiperSlide className="d-flex justify-content-center" key={data._id}>
                    <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative" key={data._id}  >
                      {/* <div className="event-top-text d-flex justify-content-between">
                        <h6>{data.event_title}</h6>
                        <span>{data.event_date}</span>
                      </div> */}
                      <img src={data.event_img} className='img-fluid' />
                      <div className="openingEvent-bottom-text d-flex flex-column  align-items-center">
                        <p className='w-100' dangerouslySetInnerHTML={{ __html: data.event_desc }}></p>
                        { data.iseventdetail === "true" && <button onClick={() => handleClick(data._id)}> Read More</button> }
                      </div>
                    </div>
                  </SwiperSlide>
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EventDetail