import React, { useState, useEffect } from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import collection_underline from "../../assets/img/Group 443.svg";
import axios from 'axios';
import globeVar from '../../GlobeApi';
import { Link } from 'react-router-dom';

const Subscription = () => {
  
  const [sub, setsub] = useState([]);

  const prdByfunc = (name) => {
    // sessionStorage.setItem("functionality", name);
    window.location.href = "/product";
  };

  const subscription_res = {
    responsive: {
      0: {
        items: 1,
      },
      520: {
        items: 2,
      },
      768: {
        items: 3,
        margin:36
      },
      992: {
        items: 3,
        margin:36
      },
      1200: {
        items: 3,
        margin:56
      },
      1400:{
        margin:36,
        items: 3,
      }
    },
  };

  useEffect(() => {

    axios.post(globeVar + "product_subscription").then((response) => {
      if (response.data.type === "success") {
        setsub(response.data.data);
      }
    });

  }, [])

  return (
    <section className="subscription-section">
      <div>
        <div className="container container-el">
          <div className="row d-flex justify-content-between shop_underline ">
            <div className="col-4">
              <div className="product-headeing-section">
                <h5>Subscription</h5>
                <img src={collection_underline} alt="" className="subscription-underline" />
              </div>
            </div>
            <div className="col-4 text-right">
              <li
                className="list-group-item view-all-btn pt-3"
                style={{
                  float: "right",
                  color: "#c9184a",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => prdByfunc("Subscriptions")}
              >
                View All
              </li>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row w-100 m-0 justify-content-evenly subscription-content-row">
            <div className="col-12">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-12 left-title-column text-center">
                    { /*  <p>Get what you deserve & be vocal for local</p>  */}
                  </div>
                  <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-12 right-slider-column">
                    <div className="row pt-3 justify-content-center">
                      <div>
                        <div className="swiper subscriptionSwiper ">
                          <div className="swiper-wrapper">
                            <OwlCarousel
                              items={3}
                              autoplayHoverPause={true}
                              {...subscription_res}
            
                              loop
                              rewind={true}
                              autoplay={true}
                              autoplayTimeout={3000}
                              nav={true}
                              dots={true}
                            >
                              {sub.map((rec) => {
                                return (
                                  <div
                                    className=""
                                    key={rec._id}
                                  >
                                    <div className="subscribe-main-div">
                                      <Link to={`/product-cart/${rec._id}`}>
                                        <div className="subscribe-top-img d-flex justify-content-center">
                                          {
                                            rec.prod_gal == "Video" ?
                                              <img
                                                src={rec.product_img}
                                                className="card-img-top"
                                                alt="..."
                                              />
                                              :
                                              <img
                                                src={rec.product_img}
                                                className="card-img-top"
                                                alt="..."
                                              />
                                          }
                                        </div>
                                      </Link>

                                      <div className="card-body subscribe-card-body">
                                        <h3 className="card-title">
                                          <marquee scrollamount="3">{rec.product_name}</marquee>
                                        </h3>
                                        <Link
                                          to={`/product-cart/${rec._id}`}>
                                          {rec.tags}
                                        </Link>

                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </OwlCarousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscription