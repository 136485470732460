import React, {useState, useEffect} from 'react'
import underline from '../../assets/img/underline.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';

function MissionVision() {
    const [mission_vision, setmission_vision] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post( globeVar + "mission_vision").then((response)=>{
      if(response.data.type === "success")
        {
          // console.log(response.data.data)
          setmission_vision(response.data.data); 
        }      
      }) 
  }, [])
  return (
    <div>
      <ScrollToTop />
      <section className="privacy-banner" style={{backgroundImage: `url('${mission_vision.privacy_img}')`}}>
        <h1 className="text-center">{mission_vision.title}</h1>
          <ul className="privacy-path">
            <li><Link to={`/`}>Home</Link></li>
            <li> {`>`} </li>
            <li>{mission_vision.title}</li>
          </ul>
    </section>

    <section className="Privacy-policy pb-5 pt-5">
        <div className="container">
            <div className="product-headeing-section container">
                <h5>{mission_vision.title}</h5>
                <img src={underline} alt="" />
            </div>
            <div className="row mt-5">
                <div className="col-lg-12 col-md-12 Privacy-condition tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"> 
                        <div className="privacy-term">
                            <p>{mission_vision.sub_title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default MissionVision
