import React, { useState, useEffect } from "react";
import underline from "../../assets/img/Group 442.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import globeVar from "../../GlobeApi";
import ScrollToTop from "./ScrollToTop";
import parse from 'html-react-parser';
function Privacy() {
  const [privacyPolicy, setprivacyPolicy] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post(globeVar + "privacy_policy").then((response) => {
      if (response.data.type === "success") {
        // console.log(response.data.data);
        setprivacyPolicy(response.data.data);
      }
    });
  }, []);
  return (
    <div>
      <ScrollToTop />
      <section
        className="privacy-banner"
        style={{ backgroundImage: `url('${privacyPolicy.privacy_img}')` }}
      >
        <h1 className="text-center">{privacyPolicy.title}</h1>
        <ul className="privacy-path">
          <li>
            <Link to={`/`}>Home</Link>
          </li>
          <li> {`>`} </li>
          <li>{privacyPolicy.title}</li>
        </ul>
      </section>

      <section className="Privacy-policy pb-5 pt-5">
        <div className="container">
          <div className="product-headeing-section container privacyPolicy-underline ">
            <h5>{privacyPolicy.title}</h5>
            <img src={underline} alt="" />
          </div>

          <div className="row mt-5">
            <div
              className="col-lg-12 col-md-12 Privacy-condition tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="privacy-term">
                  {privacyPolicy.sub_title ? parse(privacyPolicy.sub_title) : 
                  (<><p>
                    This Privacy Policy describes how iCottage Cart ("us," "we,"
                    or "our") collects, uses, shares, and protects personal
                    information on the website [www.icottagecart.com] (the
                    "Website"). By accessing or using the Website, you consent
                    to the practices described in this Privacy Policy.
                  </p>
                  <h6 className="mt-5 mb-3">
                    <b>Information We Collect:</b>
                  </h6>
                  <p>
                    <b>1. Personal Information:</b> We collect personal
                    information such as your name, email address, postal
                    address, and phone number when you register an account,
                    place an order, or communicate with us.
                  </p>
                  <p>
                    <b>2. ayment Information:</b> We collect payment
                    information, such as credit card details or other financial
                    information, for processing transactions on our platform.
                  </p>
                  <p>
                    <b>3. Usage Information:</b> We collect information about
                    how you use the Website, including pages visited, products
                    viewed, and interactions with features. This information
                    helps us improve the user experience.
                  </p>
                  <p>
                    <b>4. Device and Browser Information:</b> We automatically
                    collect information about the device and browser you use to
                    access the Website, including IP address, device type,
                    operating system, and browser type.
                  </p>
                  <p>
                    <b>5. Cookies and Similar Technologies: </b> We use cookies
                    and similar technologies to enhance your experience and
                    gather information about your interactions with the Website.
                    You can manage your cookie preferences through your browser
                    settings.
                  </p>

                  <h6 className="mt-5 mb-3">
                    <b>How We Use Your Information:</b>
                  </h6>
                  <p>
                    <b>1. Provide Services: </b> We use your information to
                    provide the services and features of the Website, process
                    orders, and respond to your inquiries.
                  </p>
                  <p>
                    <b>2. Personalization:</b>We personalize your experience by
                    recommending products, offers, and content that match your
                    interests.
                  </p>
                  <p>
                    <b>3. Communication:</b> We may send you transactional
                    emails, promotional offers, and important updates related to
                    your account or the Website. You can opt out of marketing
                    communications.
                  </p>
                  <p>
                    <b>4. Analytics:</b> We analyze user behavior to improve our
                    services, troubleshoot issues, and optimize the Website's
                    performance.
                  </p>
                  <p>
                    <b>5. Legal Compliance: </b> We may use your information to
                    comply with legal obligations, resolve disputes, and enforce
                    our terms and policies.
                  </p>

                  <h6 className="mt-5 mb-3">
                    <b>Sharing of Your Information:</b>
                  </h6>
                  <p>
                    <b>1. Service Providers:</b> We share your information with
                    trusted service providers who assist us in delivering our
                    services, such as payment processors and shipping companies.
                  </p>
                  <p>
                    <b>2. Legal Requirements: </b> We may disclose your
                    information when required by law, court order, or government
                    request.
                  </p>
                  <p>
                    <b>3. Business Transfers: </b> If we undergo a merger,
                    acquisition, or sale of assets, your information may be
                    transferred as part of the transaction.
                  </p>

                  <h6 className="mt-5 mb-3">
                    <b>Data Security:</b>
                  </h6>
                  <p>
                    We implement security measures to protect your personal
                    information from unauthorized access, alteration,
                    disclosure, or destruction. However, no method of
                    transmission over the Internet or electronic storage is
                    completely secure, and we cannot guarantee absolute
                    security.
                  </p>
                  <h6 className="mt-5 mb-3">
                    <b>Your Choices:</b>
                  </h6>
                  <p>
                    <b>1. Access and Update:</b> You can access and update your
                    account information by logging into your account on the
                    Website.
                  </p>
                  <p>
                    <b>2. Cookies: </b> You can manage cookie preferences
                    through your browser settings.
                  </p>
                  <p>
                    <b>3. Marketing Communications:</b> You can opt out of
                    marketing communications by following the instructions in
                    the email or contacting us.
                  </p>
                  <h6 className="mt-5 mb-3">
                    <b>Children's Privacy:</b>
                  </h6>
                  <p>
                    The Website is not intended for individuals under the age of
                    18. We do not knowingly collect personal information from
                    children. If you are a parent or guardian and believe your
                    child has provided us with information, please contact us.
                  </p></>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Privacy;
