import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import underline from "../../assets/img/Group 442.svg";
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog_data, setblog_data] = useState({})
  const [blog_list, setblog_list] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    const item = { blog_id: id }
    axios.post(globeVar + "blog_detail", item).then((response) => {
      if (response.data.type === "success") {
        setblog_data(response.data.data);
        setblog_list(response.data.list)
      }
    })
  }, [])
  return (
    <div>
      <ScrollToTop />
      <div className='blog-detail-banner'>
        {blog_data.blog_video ?
          <video
            controls={true}
            loop={true}
            muted={true}
          >
            <source src={blog_data.blog_video} type="video/mp4" />
          </video>
          :
          <img src={blog_data.blog_img} alt="" />
        }
      </div>
      <div className="container">
        <div className="blog-detail-content">
          <h2 className='text-center'>{blog_data.blog_title}</h2>
          <span className='d-block text-center'>{blog_data.blogger_name}</span>
          <div className="dt-reading d-flex justify-content-center align-items-center">
            <span>{blog_data.blog_date}</span>
            <span className='dot'></span>
            <span>{blog_data.blog_reading_time}</span>
          </div>
          <p dangerouslySetInnerHTML={{ __html: blog_data.blog_desc }}></p>

        </div>
        <div className="explore-other-blogs">
          <div className="row shop_underline blog_underline">
            <h5>Explore Other Blogs</h5>
            <img src={underline} alt="" />
          </div>
          <Swiper pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper" slidesPerView={3} spaceBetween={10}>
            {blog_list.map(data => {
              return <SwiperSlide className="d-flex justify-content-center" key={data._id}>
                <div className='blog-explore-1' style={{ backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.3) 58%, rgba(17, 17, 17, 0.6) 69%, rgba(17, 17, 17, 1) 100%),url(${data.blog_img})` }}>
                  <div className="blog-explore-text">
                    <p>{data.blog_title}</p>
                    <span>{data.blog_date}</span>
                  </div>
                  <div className="openingEvent-bottom-text d-flex flex-column  align-items-center">
                    <p dangerouslySetInnerHTML={{ __html: data.blog_desc }}></p>
                    <a href={`/blog_detail/${data._id}`}><button>Read More</button></a>
                  </div>
                </div>
              </SwiperSlide>
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default BlogDetail