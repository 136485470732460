import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import regions_underline from "../../assets/img/Group 444.svg";
import { useEffect } from 'react';
import axios from 'axios';
import globeVar from '../../GlobeApi';

const ShopByRegion = () => {
  const [region, setregion] = useState([]);
  const [mobileRegion, setMobileRegion] = useState([]);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleWindowResize = () => {
    window.innerWidth >= 500 ? setIsLaptop(true) : setIsLaptop(false);
    window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  const proByreg = (id) => {
    // console.log(id);
    sessionStorage.setItem("ret_id", id);
    window.location.href = "/product";
  }

  useEffect(() => {
    handleWindowResize();
    axios.post(globeVar + "cms_region").then((response) => {
      if (response.data.type === "success") {
        setregion(response.data.data);
        var Arr = [];
        var childArr = [];
        var cate = response.data.data;
        cate.map((cat, i) => {
          let modulusCheck = i % 4;
          childArr.push(cat);
          if (modulusCheck === 3 || i === cate.length-1) {
            Arr.push(childArr);
            childArr=[];
          };
        })
        setMobileRegion(Arr);
      }
    });
  }, [])

  return (
    <>
    {!isMobile && (<section className="shop-by-region-section">
      <div className="container container-el">
        <div className="region_underline">
          <h5>Shop By Regions</h5>
          <img src={regions_underline} alt="" />
        </div>
        <div className="row region-card-row">
          <Swiper
            // install Swiper modules
            breakpoints={{
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              520: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
              950: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              1000: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4
              }
            }}
            modules={[Navigation, Pagination]}
            loop={true}
            centerslide="false"
            effect={"fade"}
            grabCursor={true}
            spaceBetween={30}
            slidesPerView={4}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".button2",
              prevEl: ".button1",
            }}

          >

            <div className="swiper-wrapper">
              {region.map((ret) => {

                return (
                  <SwiperSlide key={ret._id}>
                    <div className="main-div swiper-slide" >
                      <div className="region-top-img">
                        <img
                          src={ret.region_img}
                          className="card-img-top"
                          alt="..."
                        />
                        <span>{/* 1200 Products */}</span>
                      </div>

                      <div className="card-body">
                        <h3 className="card-title">{ret.region_title}</h3>
                        <p className="card-text" >{ret.region_desc.substring(0, 50) + '...'}</p>
                        <div
                          onClick={() => proByreg(ret._id)}>
                          Explore Products</div>

                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
            {/* </OwlCarousel> */}
          </Swiper>
          <div className="arrow button1">&#10094;</div>
          <div className="arrow button2">&#10095;</div>
        </div>
      </div>
    </section>)}

    {!isLaptop && (<section className="shop-by-region-section">
      <div className="container container-el">
        <div className="region_underline">
          <h5>Shop By Regions</h5>
          <img src={regions_underline} alt="" />
        </div>
        <div className="row region-card-row">
          <Swiper
            // install Swiper modules
            breakpoints={{
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              520: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
              950: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              1000: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4
              }
            }}
            modules={[Navigation, Pagination]}
            loop={true}
            centerslide="false"
            effect={"fade"}
            grabCursor={true}
            spaceBetween={30}
            slidesPerView={4}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".button2",
              prevEl: ".button1",
            }}

          >

            <div className="swiper-wrapper">
                {mobileRegion.map((reta, i) => {
                  console.log(reta);
                const mobRegions = (ret) => {
                  return (
                    <div className='col-6'>
                      <div className="main-div swiper-slide" >
                        <div className="region-top-img">
                          <img
                            src={ret.region_img}
                            className="card-img-top"
                            alt="..."
                            onClick={() => proByreg(ret._id)}
                          />
                        </div>
                        <div className="card-body">
                          <h3 className="card-title">{ret.region_title}</h3>
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <SwiperSlide key={i}>
                    <div className='row w-100'>                    
                      {reta.map((mobRegi) => mobRegions(mobRegi))}                
                    </div>
                  </SwiperSlide>
                )
              })}
            </div>
            {/* </OwlCarousel> */}
          </Swiper>
          <div className="arrow button1">&#10094;</div>
          <div className="arrow button2">&#10095;</div>
        </div>
      </div>
    </section>)}
    </>
  )
}

export default ShopByRegion