import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
/* alert(response);
console.log(response.data); */
function Blog() {
    const [blog, setblog] = useState({})
    const [blog_data, setblog_data] = useState({})
    const [blog_list, setblog_list] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
      axios.post( globeVar + "blog_list").then((response)=>{
        if(response.data.type === "success")
          {
            setblog(response.data.banner)
            setblog_data(response.data.data); 
            setblog_list(response.data.list)
          }      
        }) 
  }, [])
  return (
    <div>
      <ScrollToTop />
      <section className="privacy-banner blog-banner" style={{backgroundImage:`url(${blog.blog_banner_img})`}}>
        <h1 className="text-center">{blog.blog_banner_title}</h1>
          <ul className="privacy-path">
            <li><Link to={`/`}>Home</Link></li>
            <li> {`>`} </li>
            <li>{blog.blog_banner_title}</li>
          </ul>
      </section>

      <section className='blog-body'>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mm-img-div">
              <div className="mah-masale-image">
                <img src={blog_data.blog_img} alt="" />
                
              </div>
            </div>
            <div className="col-md-4 mm-text-div">
              <h3>{blog_data.blog_title}</h3>
              <span>{blog_data.blogger_name}</span>
              <div className="dt-reading d-flex align-items-center">
                <span>{blog_data.blog_date}</span>
                <span className='dot'></span>
                <span>{blog_data.blog_reading_time}</span>
              </div>
              <p dangerouslySetInnerHTML={{ __html: blog_data.blog_desc }}></p>
              <Link to={`/blog_detail/${blog_data._id}`}><button className='blog-btn'>Read More</button></Link>
            </div>
          </div>
          
          <div className="explore-other-blogs blog-grid-container">
            {blog_list.map(data =>{
              return <div className='item blog-explore-1' key={data._id} style={{backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.3) 80%, rgba(17, 17, 17, 0.6) 88%, rgba(17, 17, 17, 1) 100%),url(${data.blog_img})`}}>
              <div className="blog-explore-text">
                <p>{data.blog_title}</p>
                <span>{data.blog_date}</span>
              </div>
              <div className="openingEvent-bottom-text d-flex flex-column  align-items-center">
                <p dangerouslySetInnerHTML={{ __html: data.blog_desc }}></p>
                <Link to={`/blog_detail/${data._id}`}><button>Read More</button></Link>
              </div>
          </div>
            })}
          </div>
        </div>
      </section>

    </div>
  )
}

export default Blog
