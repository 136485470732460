import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import globeVar from "../../GlobeApi";
import ScrollToTop from "./ScrollToTop";
import cart from '../../assets/img/cart.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import basket from "../../assets/img/homepage/basket.svg";
import MyContext from "../Layout/MyContext";
function MyCart() {
  const navigate = useNavigate();
  var user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  const {activeNavItem, setActiveNavItem}= useContext(MyContext);
  setActiveNavItem(null)
   
  const [productCart, setproductCart] = useState([]);
  var totalCartPrice = 0;
 
  
  //  Increment 
  function incrementValue(cartitem_id) {
   
      setproductCart((productCart) =>
       
      productCart.map((item) =>
     
        cartitem_id === item.productId
       
          ? { ...item, quantity: item.quantity  +  (item.quantity < 10 ? 1 : 0)} 
          : item
            
      ) 
    );
    updateCartQuantity(cartitem_id, "inc");
  //  console.log('No More Qty');
}

 // DECREMENT
  function decrementValue(cartitem_id) {
    setproductCart(
      productCart.map((item) =>
        cartitem_id === item.productId
          ? { ...item, quantity: item.quantity - (item.quantity > 1 ? 1 : 0) }
          : item
      )
    );
    updateCartQuantity(cartitem_id, "dec");
  }
  
  function updateCartQuantity(cartitem_id, scope) {
    if (sessionStorage.getItem("user")) {
      const user_id = user._id;
      
      const item = { productId: cartitem_id, scope: scope };
      // console.log(item);
      axios.post(globeVar + "updateCart/" + user_id, item).then((response) => {
        if (response.data.type === "success") {
          setproductCart(response.data.updatedCart.products);
        }
      });
    } else {
      const cartItem = JSON.parse(localStorage.getItem("cart"));
      // console.log(cartItem);
      const item = { productId: cartitem_id, scope: scope };

      axios.post( globeVar +"updateCart_without_user/" + cartItem, item )
        .then((response) => {
          if (response.data.type === "success") {
            setproductCart(response.data.updatedCart.products);
          }
        });
    }
  }
  function checkLogin() {
    if (sessionStorage.getItem("user")) {
      navigate("/address");
    } else {
      window.$("#exampleModalToggle").modal("show");
    }
  }
  const removeProduct = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = user._id;
    const item = { productId: id };

    axios.post(globeVar + "removeItem/" + user_id, item).then((response) => {
      if (response.data.type === "success") {
        toast.success('Removed from Cart', {
          position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket}/>,
            style: { background: '#c9184a' },
          });
          setproductCart(response.data.updatedCart.products);
      }
    });
    } else {
      const cartItem = JSON.parse(localStorage.getItem("cart"));
      const item = { productId: id };
      axios.post( globeVar +"removeItem_without_user/" + cartItem, item )
        .then((response) => {
          if (response.data.type === "success") {
            toast.success('Removed from Cart', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={basket}/>,
              style: { background: '#c9184a' },
              });
            setproductCart(response.data.updatedCart.products);
          }
        });
    }
    
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem("user")) {
       const user_id = user._id;
      axios.post(globeVar + "getCart/" + user_id).then((response) => {
        // console.log(response);
        if (response.data.type === "success") {
       
          setproductCart(response.data.cart.products);
          // console.log(response.data.cart.products);
         
          
          
          // console.log(response.data.cart.products[]);
        }
      });
    } else {
      const cartItem = JSON.parse(localStorage.getItem("cart"));
      axios
        .post(globeVar +"getCart_without_user/" + cartItem)
        .then((response) => {
          if (response.data.type === "success") {
            setproductCart(response.data.cart.products);
          }
        });
    }
    
  }, []);

  return (
    <div>
      <ScrollToTop/>
      <ToastContainer style={{width: "fit-content",marginTop: "3%"}}/>
      <section className="my-cart">
        <div className="container">
          <div className="row my-cart-head">
            <div className="text-center">
              <p className="my-active">
                <a href="#">MY CART</a>
              </p>
              <span>---------</span>
              <p>
                <a href="#">ADDRESS</a>
              </p>
              <span>---------</span>
              <p>
                <a href="#">PAYMENT</a>
              </p>
            </div>
          </div>
          <div className="row">
            <h3 className="my-cart-heading">My Cart ({productCart.length})</h3>
            <div className="col-lg-8 col-md-7">
            {productCart.length === 0 ?
            <div className="row my-cart-first mt-3">
            <div className="_1AtVbE col-12-12"><div className="Vy94J0"><img src={cart} className="_2giOt4" alt="" /><div className="_1LCJ1U">Your cart is empty!</div><button type="button" onClick={()=>navigate("/product")} className="_2KpZ6l _1sbqEe _3dESVI"><span>Shop now</span></button></div></div>
             </div> : 
                productCart.map((data) => {
                  if (user && user?.role === "retailer") {
                    totalCartPrice += parseInt(data.retailerPrice) * parseInt(data.quantity);
                  } else {
                    data.sub_plan ? totalCartPrice += parseInt(data.sub_price) * parseInt(data.quantity) * parseInt(data.sub_plan.substring(6, 8))
                    : data.saving ? totalCartPrice += (parseInt(data.price) - data.saving) * parseInt(data.quantity) : totalCartPrice += parseInt(data.price) * parseInt(data.quantity)
                  }
                return (
                  <div className="row my-cart-first mt-3" key={data._id}>
                    <div className="col-3 p-3 my-cart-first-1">
                      <Link to={`/product-cart/${data.productId}`}>
                      <img src={data.product_img} alt="" />
                      </Link>
                      
                    </div>
                   { /* {data.price} */ }
                    <div className="col-9 p-4 my-cart-details">
                      <h4>
                      {data.product_name} - {data.variants}
                      </h4>
                      <h4>
                      {user && user?.role === "retailer" ? "₹" + (parseInt(data?.retailerPrice) * parseInt(data.quantity)).toFixed(2) + " ( CP : "  :""}
                      ₹
                      {
                        data.sub_plan ? (parseInt(data.sub_price) * parseInt(data.quantity)).toFixed(2) * parseInt(data.sub_plan.substring(6, 8) ) :
                        data.saving ? ((parseInt(data.price) - data.saving) * parseInt(data.quantity)).toFixed(2) : (parseInt(data.price) * parseInt(data.quantity)).toFixed(2)  
                      }
                      {user && user?.role === "retailer" ? " )" :""}
                      </h4>
                      
                      {data.sub_plan ?
                      <div className="flex-grow-1">
                          {data.sub_plan}
                      </div>:""}

                      
                      <div className="d-flex">
                        <div className="d-flex increament align-items-center ms-2 mb-2">
                          <button
                            className="qty-btn minus"
                            onClick={() => decrementValue(data.productId)}
                          >
                            - 
                          </button>
                          {data.quantity < data.alert_qty ? 
                          <input value={data.quantity}  /> 
                            : <input value={data.quantity}  />
                             }
                             {data.quantity < data.alert_qty ? 
                          <button
                            className="qty-btn plus"
                            onClick={() => incrementValue(data.productId )}
                          >
                            +
                          </button>
                          : <button>+</button>
                             }
                        </div>
                      </div>
                      {
                         data.quantity < data.alert_qty ? "" : "No More Qty Avalible"
                      }
                      {/* {
                avaliable_qty > alert_qty ? 
                <div className="d-flex increament align-items-center ms-2 mb-2">
              <p className="mb-2">Quantitydd</p>
              <div className="d-flex">
                <button className="dec" onClick={decrementValue}>
                  -
                </button>
                <input defaultValue="1" name="qty" id="number" readOnly />
                <button className="inc" onClick={incrementValue}> 
                  +
                </button>
              </div>
              {msg}
            </div>  :
            <div className="d-flex increament align-items-center ms-2 mb-2" >
              <p className="mb-2">Quantity</p>
              <div className="d-flex">
                <button className="dec" onClick={decrementValue}>
                  -
                </button>
                <input defaultValue="1" name="qty" id="number" readOnly />
                <button className="inc" onClick={incrementValue}> 
                  +
                </button>
              </div>
              {msg}
            </div>
              } */}
                      <div className="remove">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => removeProduct(data.productId)}
                        >
                          REMOVE
                        </a>
                        
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-lg-4 col-md-5 my-cart-second">
              <div className="my-cart-form">
                {/* <h4 className="options">Saving Corner</h4>
                <div className="d-flex justify-content-between">
                  <p className="coupons">COUPONS </p>
                  <span>
                    <button>Apply</button>
                  </span>
                </div>
                <p className="discount mb-2">My First Order 50% Discount</p> 
                <hr />
                <h4 className="options">Refferal Code</h4>
                <div className="d-flex justify-content-between">
                  <p className="coupons">COUPONS </p>
                  <span>
                    <button>Apply</button>
                  </span>
                </div>
                <p className="discount mb-2">My First Order 50% Discount</p> 
                <hr /> */}
                <h4 className="coupons">PRICE DETAILS</h4>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="discount">Total MRP</p>
                  <span>₹ {parseInt(totalCartPrice)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="discount">Discount</p>
                  <span>0</span>
                </div>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="discount">Tax</p>
                  <span>₹ 0</span>
                </div>
                {/* <div className="d-flex justify-content-between px-2">
                  <p className="discount">Delivery Charges</p>
                  <span>₹ {parseInt(totalCartPrice) > 0 ? 40 : 0}</span>
                </div> */}
                <hr />
                <div className="d-flex justify-content-between mb-2">
                  <p className="total-2">Total</p>
                  <p className="total-2">₹ {parseInt(totalCartPrice)}</p>
                </div>
                {productCart.length === 0 ?
                <button
                className="place-order mt-2 "
                disabled
              >
                PLACE ORDER
              </button>
                :<button
                className="place-order mt-2 "
                onClick={() => checkLogin()}
              >
                PLACE ORDER
              </button>}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyCart;
