import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import underline from "../../assets/img/Group 442.svg";
import who_we_are from "../../assets/img/Group 448.svg";
import basket from "../../assets/img/homepage/basket.svg";
import like from "../../assets/img/homepage/like.png";
import { useNavigate } from "react-router-dom";
import globeVar from "../../GlobeApi";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appImage from '../../assets/img/showpopup.png';
import appImageSmall from '../../assets/img/showpopup2.png';
import whatsapp from '../../assets/img/logos/WhatsApp.svg';
import Faq1 from "../../assets/img/FAQ.png"
import Faq2 from "../../assets/img/Online-Payment-1.png"
import Faq3 from "../../assets/img/Home-Delivery-1.png"
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ScrollToTop from "./ScrollToTop";
import MyContext from "../Layout/MyContext";
import VisionMissionBanner from "../../assets/img/Our-Vision-Banner.jpg"
import Banner from "../HomePage/Banner";
import OfferBanner from "../HomePage/OfferBanner";
import ShopByRegion from "../HomePage/ShopByRegion";
import Subscription from "../HomePage/Subscription";
import ComboOffer from "../HomePage/ComboOffer";
import Events from "../HomePage/Events";
import VoiceOfCustomers from "../HomePage/VoiceOfCustomers";
import Collection from "../HomePage/Collection";
import ShopByCategory from "../HomePage/ShopByCategory";
const Home = () => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleWindowResize = () => {
    window.innerWidth >= 500 ? setIsLaptop(true) : setIsLaptop(false);
    window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  let navigate = useNavigate();
  var data = sessionStorage.getItem("user");
  data = JSON.parse(data);
  const { activeNavItem, setActiveNavItem } = useContext(MyContext);
  setActiveNavItem(null)
  /*  const [IsLoading, setIsLoading] = useState(); */
  const [fullForm, setfullForm] = useState({
    search: "",
  });
  const { search } = fullForm;

  const handelInput = (e) => {
    setfullForm({ ...fullForm, [e.target.name]: e.target.value });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    // console.log(fullForm);
    navigate("/product", { state: { product_data: fullForm.search } });
  };

  const [category, setcategory] = useState([]);
  const [about_us, setabout_us] = useState({})
  const [offer_slider, setoffer_slider] = useState([]);
  const [mobileCategory, setMobileCategory] = useState([]);
  const defineWord = () => {
    axios.post(globeVar + "category_list").then((response) => {
      if (response.data.type === "success") {
        setcategory(response.data.data);
        var Arr = [];
        var childArr = [];
        var cate = response.data.data;
        cate.map((cat, i) => {
          let modulusCheck = i % 4;
          childArr.push(cat);
          if (modulusCheck === 3 || i === cate.length-1) {
            Arr.push(childArr);
            childArr=[];
          };
        })
        setMobileCategory(Arr);
      }
    });
  };

  const prdByCate = (id) => {
    sessionStorage.setItem("cate_id", id);
    window.location.href = "/product";
  }

  const prdBySubCate = (id) => {
    sessionStorage.setItem("sub_id", id);
    window.location.href = "/product";
  }


  // useEffect(() => {
  //   if (window.innerWidth < 960) {
  //     setTimeout(() => {
  //       window.$("#exampleModalToggle3").modal("hide")
  //       window.$("#exampleModalToggle3").modal("show")
  //     }, 4000);
  //   }
  // }, [""]);

  function AppCrossbtn() {
    window.$("#exampleModalToggle3").modal("hide")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    defineWord();
    handleWindowResize();

    axios.post(globeVar + "aboutus_list").then((response) => {
      if (response.data.type === "success") {
        // console.log(response.data.data)
        setabout_us(response.data.data);
      }
    })
    axios.post(globeVar + "offers_page").then((response) => {
      if (response.data.type === "success") {
        setoffer_slider(response.data.cms.offer_banner_img)
      }
    })
  }, []);

// responsive hide slider
  return (
    //body
    <div>
      <div
        className="modal fade modal-size mobile-app-popup"
        id="exampleModalToggle3"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content login-modal-resize">
            <div className="d-flex appModal justify-content-center ">
              {/* <a href="https://app.cottage-cart.com/login" className="position-relative"> */}
                <img src={appImage} className="img-fluid " alt="" />
              {/* </a> */}
              <div className="appCrossbtn" onClick={AppCrossbtn}>
                <span>x</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
      {/* <TawkTo /> */}
      <a href='https://api.whatsapp.com/send/?phone=919096099303&text=Hi'><img src={whatsapp} alt="" style={{ height: '78px', position: 'fixed', bottom: '2%', left: '0', zIndex: '1000', margin: '0px' }} /></a>


      {/* <TawkTo1 /> */}

      <ToastContainer style={{ width: "fit-content", marginTop: "3%" }} />

      <Banner />
     
      <ShopByCategory />

      <OfferBanner />
      
      <Collection />

      <section className="py-3 d-flex justify-content-center  d-md-none">
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.cottagecart">
            <img src={appImageSmall} className="img-fluid" alt="" />
          </a>
        </div>
      </section>

      <ShopByRegion />

      <Subscription />

      <ComboOffer />

      <Events />

      <VoiceOfCustomers />

      <section className="reviews-banner-section">
        <Swiper className="mySwiper" modules={[Autoplay, Pagination]}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}>
          {offer_slider.map((data, i) => {
            return <SwiperSlide key={i}>
              <a href="/offers"><img src={data} alt="" className="review-banner-img" /></a>
            </SwiperSlide>
          })}
        </Swiper>
      </section>

      <section className="who-section">
        <div className="container">
          <div className="who_we_are_underline mb-5">
            <h5>Our Vision and Mission</h5>
            <img src={who_we_are} alt="" />
          </div>
          <img className="w-100" src={VisionMissionBanner} alt=""/>
          {/* <div className="vsn-msn-ct-bnr">
            <div className="vmcImg">
              <div className="vsn-bbl-img">
                <img src={visionBubble} alt="" />
              </div>
              <img src={about_us.vision_gif} alt="" className='vsn-gif-img' />
              <p className='vsn-para' dangerouslySetInnerHTML={{ __html: about_us.vision }}></p>
              <div className="msn-bbl-img">
                <img src={missionBubble} alt="" />
              </div>
              <img src={about_us.mission_gif} alt="" className='msn-gif-img' />
              <p className='msn-para' dangerouslySetInnerHTML={{ __html: about_us.mission }}></p>
              <div className="ct-bbl-img">
                <img src={coretopicsBubble} alt="" />
              </div>
              <img src={about_us.core_topics_gif} alt="" className='ct-gif-img' />
              <ul className="ul-left">
                <li><img src={Bullet} alt="" /> {about_us.core_topics1}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics2}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics3}</li>
              </ul>
              <ul className="ul-right">
                <li><img src={Bullet} alt="" /> {about_us.core_topics4}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics5}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics6}</li>
              </ul>
            </div>
          </div> */}
        </div>
      </section>

      {!isMobile && (<section className="who-section-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-md-4 mt-sm-5 section" >
              <div className="row text-row-1" style={{ cursor: "pointer" }} onClick={() => navigate('/faq')}>
              </div>
              <img src="../../assets/img/FAQ.png" alt="" />
            </div>
            <div className="col-lg-4 col-md-6 mt-md-4 mt-sm-5 section">
              <div className="row text-row-2">
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-md-4 mt-sm-5 section">
              <div className="row text-row-3">
              </div>
            </div>
          </div>
        </div>
      </section>)}

      {!isLaptop && (<section className="home-slider">
        <div className="container container-el">

          <div className="row">
            <div className="">
              <Swiper className="py-4" modules={[Autoplay, Pagination]}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  slidesPerView: 1,
                  slidesPerGroup: 1
                }}
                // install Swiper modules
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  950: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1000: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1200: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },
                  1400: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                  },

                }}
                /* loop={true} */
                centerslide="false"
                effect={"fade"}
                grabCursor={true}
                spaceBetween={30}
                slidesPerView={4}

                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".button2",
                  prevEl: ".button1",
                }}
              >
                <div className="swiper-wrapper ">
                  <SwiperSlide >
                    <img src={Faq1} alt='banner' className="img-fluid border-radius-20" />
                  </SwiperSlide>
                  <SwiperSlide >
                    <img src={Faq2} alt='banner' className="img-fluid border-radius-20" />
                  </SwiperSlide>
                  <SwiperSlide >
                    <img src={Faq3} alt='banner' className="img-fluid border-radius-20" />
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>

          </div>
        </div>
      </section>)}

    </div>
  );
};

export default Home;
