import React, { useState, useEffect } from 'react'
import './SuccessPayment.css';
import { BsCheckCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';


const OrderSuccess = (response) => {
   /*  const [user, setUser] = useState({
       name: "",
       email:""
    })
    const {name,email} = user;
    const onInputChange = e => {
       setUser({...user,[e.target.name]: e.target.value})
   };
 
    const onSubmit = async e => {
       console.log(user);
   } */
   /* function handleClick(e){
         e.preventDefault();
         let data = {name: "stepOne", values: [this.state.user]};
         this.context.router.push("/registration/step-two");
    } */


   /*  
    axios(config)
   .then(function (response) {
     console.log(JSON.stringify(response.data));
     console.log(response);
     
   .catch(function (error) {
     console.log(error);
   });  
    */
   const navigate = useNavigate();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [])
   return (
      <div>
         <div className="container">
            <div className="row">
               <div className="col-md-6 mx-auto mt-5">
                  <div className="order_success">
                     <div className="payment_header">
                        <div className="check"><BsCheckCircleFill className='icons' /></div>
                     </div>
                     <div className="content">
                        <h6 className='py-4'>Order Success!</h6>
                        <button className='mt-4' onClick={() => navigate("/")}>Continue Shopping</button>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         {/* 
      <form onSubmit={e => onSubmit(e)} method='GET'>
        Name:
        <input type="text" name="name"  value={name} onChange={e => onInputChange(e)}/><br/><br/>
        Email:
        <input type="text" name="email" value={email} onChange={e => onInputChange(e)}/><br/><br/>

        <input type="submit" name="submit" value="Submit" />
   </form> */}
      </div>


   )
}

export default OrderSuccess;  