import axios from 'axios';
import React, { useEffect, useState } from 'react'
import globeVar from '../../GlobeApi';
import { useNavigate } from 'react-router-dom';

const HeaderCart = () => {
    const [productCart, setproductCart] = useState([]);
    const navigate = useNavigate();
    var user = JSON.parse(sessionStorage.getItem("user"));
    useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.getItem("user")) {
        const user_id = user._id;
        axios.post(globeVar + "getCart/" + user_id).then((response) => {
          if (response.data.type === "success") {
            setproductCart(response.data.cart.products);
            /* setproductCart(cart_product); */
          }
        });
      } else {
        const cartItem = JSON.parse(localStorage.getItem("cart"));
        /* console.log(cartItem); */
        if (cartItem !== null) {
          axios
            .post(globeVar + "getCart_without_user/" + cartItem)
            .then((response) => {
              if (response.data.type === "success") {
                setproductCart(response.data.cart.products);
                /* setproductCart(cart_product); */
              }
            });
        } else {
          setTimeout(() => {
            fetchData();
          }, 2000);
        }
      }
    };

    fetchData();
  }, [productCart]);
    return (
        <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/my-cart")}
        >
            <span className="cart-section icon-cart">
            {productCart.length === 0 ? (
                ""
            ) : (
                <span className="quantity">
                {productCart.length}
                </span>
            )}
            </span>
            <span className="login-cart-hide">Cart</span>
        </div>
    )
}

export default HeaderCart;