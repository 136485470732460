import React, {useState, useEffect} from 'react'
import logo from '../../assets/img/cottage-cart-Logo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
function Payment() {
  var user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  const [productCart, setproductCart] = useState([]);
  var totalCartPrice = 0;

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_TxPF78wfnf4BfV",
      currency: "INR",
      amount: amount * 100,
      name: user.first_name,
      description: "Thanks for purchasing",
      image:
        {logo},

      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert("Payment Successfully");
      },
      prefill: {
        name: user.first_name,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const user_id =user._id;
    axios.post( globeVar+"getCart/"+user_id).then((response)=>{
      if(response.data.type === "success")
      {
        // console.log(response.data.cart.products);
        setproductCart(response.data.cart.products);
      } 
  }) ;
   }, [])
  return (
    <div>
      <section className="address payment">
        <div className="container">
            <div className="row address-head">
                <div className="text-center">
                    <p><a href="#">MY CART</a></p><span>---------</span>
                    <p><a href="#">ADDRESS</a></p><span>---------</span>
                    <p className="my-active"><a href="#">PAYMENT</a></p>
                </div>
            </div>
            <div className="address-heading">
                <h3>Choose Payment Mode</h3>
            </div>
            <div className="row">
                <div className="col-lg-8 col-md-7 adjust-accord">
                    <h4 className="default mb-3">PAYMENT OPTIONS</h4>
                    <div className="row address-first">
                      <div className="col-12 pt-3 pb-3 adjust-accord">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <input type="radio" name="optradio" id="upi"/> <label htmlFor="upi" className="w-100 h-100">UPI</label>
                              </button>
                              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body px-5 pt-0">
                                  <p className="fw-bolder">Choose Option</p>
                                  <div>
                                    <input type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                      Phonepe
                                    </label>
                                  </div>
                                  <div>
                                    <input type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      Your UPI ID
                                    </label>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  <input type="radio" name="optradio" id="credit"/> <label htmlFor="credit" className="w-100 h-100">Credit / Debit / ATM Card</label>
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p className="pt-2"><input className="credit-input" type="text" placeholder="Enter Card Number" /></p>
                                <div className="d-flex">
                                  <div className="d-flex align-items-center valid-year">
                                    <span>Valid thru</span>
                                    <div className="input-group justify-content-evenly">
                                      <button className="btn dropdown-toggle btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">MM</button>
                                      <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">01</a></li>
                                        <li><a className="dropdown-item" href="#">02</a></li>
                                        <li><a className="dropdown-item" href="#">03</a></li>
                                      </ul>
                                      <button className="btn dropdown-toggle btn-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">YY</button>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p className="ms-2"><input className="credit-input" type="text" placeholder="CVV"/></p>
                                </div>
                                <p><button  className="pay-button">PAY ₹ 2100</button></p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  <input type="radio" name="optradio" id="netbank"/> <label htmlFor="netbank" className="w-100 h-100">Net Banking</label>
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body  px-5 pt-0">
                                  <p className="fw-bolder">Popular Banks</p>
                                  <div className="d-flex justify-content-between mb-3 align-bank">
                                    <div>
                                      <input type="radio" name="netbankradio" id="flexRadioDefault3"/>
                                      <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        HDFC Bank
                                      </label>
                                    </div>
                                    <div>
                                      <input type="radio" name="netbankradio" id="flexRadioDefault4"/>
                                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        ICICI Bank
                                      </label>
                                    </div>
                                    <div>
                                      <input type="radio" name="netbankradio" id="flexRadioDefault4"/>
                                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        SBI Bank
                                      </label>
                                    </div>
                                    <div>
                                      <input type="radio" name="netbankradio" id="flexRadioDefault4"/>
                                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        Axis Bank
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <p className="fw-bolder">Other Banks</p>
                                    <select className="form-select" aria-label="Default select example">
                                      <option defaultValue>---Select Bank---</option>
                                      <option value="1">Bank Of India</option>
                                      <option value="2">Bank Of Marashtra</option>
                                      <option value="3">Punjab National Bank</option>
                                    </select>
                                  </div>
                                  <p><button  className="pay-button">PAY ₹ 2100</button></p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item border-bottom-0">
                            <h2 className="accordion-header" id="headingFour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  <input type="radio" name="optradio" id="cod"/> <label htmlFor="cod" className="w-100 h-100">Cash On Delivery</label>
                              </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p><button  className="pay-button">CONTINUE</button></p>
                              </div>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                </div>
                {productCart.map(data =>{
                totalCartPrice += parseInt(data.price) * parseInt(data.quantity);
                })}
                <div className="col-lg-4 col-md-5 address-second">
                    <div className="address-form pb-1">
                        <h4 className="options">DELIVERY ESTIMATES</h4>
                        {/* <div className="d-flex justify-content-between mb-5 align-items-center"><div className="delivery-img"><img src={pikkle} alt=""/></div><span className="estimated">Estimated Delivery By 24 Jan 2023</span></div> */}
                        <p className="price">PRICE DETAILS (3 item)</p>
                        <div className="d-flex justify-content-between mb-2 px-2 "><p className="mrp">Total MRP</p><span>₹ {totalCartPrice}</span></div>
                        <div className="d-flex justify-content-between mb-2 px-2 "><p className="mrp">Discount</p><span>0</span></div>
                        <div className="d-flex justify-content-between mb-2 px-2 "><p className="mrp">Tax</p><span>₹ 300</span></div>
                        <div className="d-flex justify-content-between mb-2 px-2 "><p className="mrp">Delivery Charges</p><span>FREE</span></div>
                        <hr/>
                        <div className="d-flex justify-content-between"><p className="total-2">Total</p><span><p className="total-2">₹ {totalCartPrice}</p></span></div>
                        <button className="place-order" onClick={() => displayRazorpay(totalCartPrice)}>CheckOut</button>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Payment
