import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import image30 from "../../assets/img/homepage/Image130.png";
import ScrollToTop from './ScrollToTop';
const Event = () => {
  const [event_list, setevent_list] = useState([])
  const [event_data, setevent_data] = useState({})
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post(globeVar + "event_list").then((response) => {
      if (response.data.type === "success") {
        setevent_list(response.data.data);
        setevent_data(response.data.banner);
      }
    })
  }, [])
  return (
    <div>
      <ScrollToTop />
      <section className="privacy-banner event-banner" style={{ backgroundImage: `url(${event_data.event_banner_img})` }}>
        <h1 className="text-center">{event_data.event_banner_title}</h1>
        <ul className="privacy-path">
          {/* <li><Link to={`/`}>Home</Link></li> */}
          {/* <li> {`>`} </li> */}
          {/* <li>{event_data.event_banner_title}</li> */}
        </ul>
      </section>
      <section className='event-page'>
        <div className="container container-el">
          <div className="grid-container">
            {event_list.map(data => {
              return <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative" key={data._id}  >
                {/* <div className="event-top-text d-flex justify-content-between">
                  <h6>{data.event_title}</h6>
                  <span>{data.event_date}</span>
                </div> */}
                <img src={data.event_img} className='img-fluid' />
                <div className="openingEvent-bottom-text d-flex flex-column  align-items-center">
                  <p className='w-100' dangerouslySetInnerHTML={{ __html: data.event_desc }}></p>
                  { data.iseventdetail === "true" && <a href={`/event_detail/${data._id}`}><button>Read More</button></a> }
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Event