import React, {useState, useEffect} from 'react'
import underline from '../../assets/img/Group 442.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
import parse from 'html-react-parser';
function Return_Policy() {
    const [ReturnPolicy, setReturnPolicy] = useState([])
    useEffect(() => {
      window.scrollTo(0, 0);
      axios.post( globeVar + "return_policy").then((response)=>{
        if(response.data.type === "success")
          {
            // console.log(response.data.data)
            setReturnPolicy(response.data.data); 
          }      
        }) 
    }, [])
  return (
    <div>
      <ScrollToTop />
    <section className="privacy-banner" style={{backgroundImage: `url('${ReturnPolicy.privacy_img}')`}}>
      <h1 className="text-center">{ReturnPolicy.title}</h1>
        <ul className="privacy-path">
          <li><Link to={`/`}>Home</Link></li>
          <li> {`>`} </li>
          <li>{ReturnPolicy.title}</li>
        </ul>
  </section>

  <section className="Privacy-policy pb-5 pt-5">
      <div className="container">
          <div className="product-headeing-section container retun_policy-underline">
              <h5>{ReturnPolicy.title}</h5>
              <img src={underline} alt="" />
          </div>
          <div className="row mt-5">
              <div className="col-lg-12 col-md-12 Privacy-condition tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">     
                      <div className="privacy-term">
                          <p>{ReturnPolicy.sub_title ? parse(ReturnPolicy.sub_title) : ""}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </div>
  )
}

export default Return_Policy
