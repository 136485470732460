import React, { useState, useEffect, useRef, useContext } from "react";
import basket from "../../assets/img/homepage/basket.svg";
import like from "../../assets/img/homepage/like.png";
import underline from "../../assets/img/Group 444.svg";
import { WhatsappShareButton, FacebookShareButton } from "react-share";
import { WhatsappIcon, FacebookIcon } from "react-share";
import instagram from "../../assets/img/logos/Instagram.svg";
import copylink from "../../assets/img/copy.png";
import facebookmessenger from "../../assets/img/facebook-messenger.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import globeVar from "../../GlobeApi";
import $, { data } from "jquery";
import ScrollToTop from "./ScrollToTop";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkImage from "../../assets/img/homepage/copy.jpg";
import parse from 'html-react-parser';

function ProductCart() {
  const navigate = useNavigate();
  const videoRef = useRef(false);
  var user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  const { prod_id } = useParams();
  // console.log(prod_id);
  const [productDetails, setproductDetails] = useState([]);
  const [productBreadcrumb, setproductBreadcrumb] = useState([]);

  const [alert_qty, setalert_qty] = useState("");
  const [avaliable_qty, setquantity] = useState("");

  const [qty, setqty] = useState([1]);
  const [cityState, setcityState] = useState("");
  const [service, setservice] = useState("");
  const [msg, setmsg] = useState("");

  var data = avaliable_qty - alert_qty;

  const resp = {
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },

      520: {
        items: 2,
      },

      768: {
        items: 3,
        margin: 13,
      },
      950: {
        items: 3,
        margin: 13,
      },

      1000: {
        items: 4,
        margin: 13,
      },
      1200: {
        items: 4,
        margin: 50,
      },
      1400: {
        items: 5,
        margin: 21,
      },
    }
  };
  // INCREMENT
  function incrementValue() {
    var value = parseInt(document.getElementById("number").value, 10);
    if (value < data) {
      value = isNaN(value) ? 0 : value;
      value++;
      setqty(value);
    } else {
      // console.log("No More QTY Avaliable");
      setmsg("No More QTY Avaliable");
    }
    document.getElementById("number").value = value;
  }

  // DECREMENT
  function decrementValue() {
    var value = parseInt(document.getElementById("number").value, 10);
    if (value > 1) {
      value = isNaN(value) ? 0 : value;
      value--;
      setqty(value);
    }
    document.getElementById("number").value = value;
  }
  const [wishlist, setwishlist] = useState([]);

  const [currentView, setCureentView] = useState({ url: "", type: "" });

  function changeIcon(anchor, productId) {
    if (sessionStorage.getItem("user")) {
      var icon = anchor.target;
      icon.classList.toggle("icon-wishlist");
      icon.classList.toggle("icon-wishlist-fill");
      const item = { userId: user._id, productId: productId };
      axios.post(globeVar + "add_to_wishlist", item).then((response) => {
        if (response.data.type === "success") {
          var wishlist_id = [];
          if (response.data.newWish) {
            response.data.newWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          } else if (response.data.updatedWish) {
            response.data.updatedWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          }
          var x = wishlist.includes(productId);
          if (x === true) {
            toast.success("Removed from Wishlist", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: "#c9184a" },
            });
          } else {
            toast.success("Added to Wishlist", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: "#c9184a" },
            });
          }
          setwishlist(wishlist_id);
        }
      });
    } else {
      window.$("#exampleModalToggle").modal("show");
      localStorage.setItem("wishlist", JSON.stringify(productId));
    }
  }

  function imageActive() {
    const allHoverImages = document.querySelectorAll(".small-img");
    const imgContainer = document.querySelector(".MainImg");

    window.addEventListener("DOMContentLoaded", () => {
      allHoverImages[0].parentElement.classList.add("active");
    });
    allHoverImages.forEach((image) => {
      image.addEventListener("click", () => {
        imgContainer.src = image.src;
        resetActiveImg();
        image.parentElement.classList.add("active");
      });
    });
    function resetActiveImg() {
      allHoverImages.forEach((img) => {
        img.parentElement.classList.remove("active");
      });
    }
  }

  const opti = {
    responsive: {
      0: {
        items: 1,
      },

      520: {
        items: 2,
      },
      950: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  };

  const add_card = (id, sub_plan) => {
    // console.log(id);
    if (sessionStorage.getItem("user")) {
      const user_id = user._id;
      const product_data = id;
      const product_qty = $("#number").val();
      let items = {
        productId: product_data,
        quantity: parseInt(product_qty),
        sub_plan: sub_plan,
      };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        if (response.data.type === "success") {
          window.location.href = "/my-cart";
        }
      });
    } else {
      const product_data = id;
      const product_qty = $("#number").val();
      /* if(!localStorage.getItem("cart")){
            var cart =[];
            localStorage.setItem("cart", JSON.stringify(cart))
            }
            var cart = JSON.parse(localStorage.getItem("cart")); */
      var items;
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
          sub_plan: sub_plan,
        };
      } else {
        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          sub_plan: sub_plan,
        };
      }
      /*  cart.push(items);
            localStorage.setItem("cart", JSON.stringify(cart) )
            window.location.href="/my-cart"; */
      // console.log(items);
      // console.log("items");

      axios.post(globeVar + "addtocart", items).then((response) => {
        if (response.data.type === "success") {
          // console.log(response.data);
          if (response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }
          if (response.data.updatedCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          }

          window.location.href = "/my-cart";
        }
      });
    }
  };

  const [suscrib, setsuscrib] = useState("");
  const subscribe = (e) => {
    if (e.target.value !== "") {
      if (e.target.value === "Every 1 months") {
        setsuscrib(1);
      } else if (e.target.value === "Every 3 months") {
        setsuscrib(3);
      } else if (e.target.value === "Every 6 months") {
        setsuscrib(6);
      } else if (e.target.value === "Every 8 months") {
        setsuscrib(8);
      } else if (e.target.value === "Every 12 months") {
        setsuscrib(12);
      }
    } else {
      setsuscrib("");
    }
    /*    let item = { prod_id: prod_id };
       console.log(item);
       axios.post(globeVar + "product_detail", item).then((response) => {
         if (response.data.type === "success") {
           console.log(response.data.data.product);
           setproductDetails(response.data.data.product);
           setCureentView({
             url: response.data.data.product?.product_video,
             type: "video",
           });
 
           setreletedProduct(response.data.data.related_product);
           imageActive();
         }
       }); */
  };
  const add_card_data = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = user._id;
      const product_data = id;
      const product_qty = 1;
      let items = { productId: product_data, quantity: parseInt(product_qty) };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        if (response.data.type === "success") {
          toast.success("Added to Cart", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: "#c9184a" },
          });
        }
      });
    } else {
      const product_data = id;
      const product_qty = 1;
      /* if(!localStorage.getItem("cart")){
            var cart =[];
            localStorage.setItem("cart", JSON.stringify(cart))
            }
            var cart = JSON.parse(localStorage.getItem("cart")); */
      var items;
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
        };
      } else {
        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
        };
      }
      /*  cart.push(items);
            localStorage.setItem("cart", JSON.stringify(cart) )
            window.location.href="/my-cart"; */
      // console.log(items);
      axios.post(globeVar + "addtocart", items).then((response) => {
        if (response.data.type === "success") {
          // console.log(response.data);
          if (!response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          } else {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }

          toast.success("Added to Cart", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: "#c9184a" },
          });
        }
      });
    }
  };

  const prdByCate = (id) => {
    id === undefined ? sessionStorage.setItem("functionality", "Combos") : sessionStorage.setItem("cate_id", id);
    window.location.href = "/product";
  }

  const [reletedProduct, setreletedProduct] = useState([]);
  function GetData(product_ids) {
    if (sessionStorage.getItem("user")) {
      var data = sessionStorage.getItem("user");
      data = JSON.parse(data);
      const item = { userId: data._id };
      axios.post(globeVar + "get_Wishlist", item).then((response) => {
        if (response.data.type === "success") {
          var wishlist_id = [];
          response.data.wishlist.products.forEach((rev) => {
            wishlist_id.push(rev.productId);
          });
          // console.log(wishlist_id);
          setwishlist(wishlist_id);
        }
      });
    }
    let items = { prod_id: product_ids };
    axios.post(globeVar + "related_product", items).then((response) => {
      if (response.data.type === "success") {
        // console.log(response.data.data);
        setreletedProduct(response.data.data);
      }
    });
  }

  const [value, setvalue] = useState("");
  const [tcs, settcs] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    const product_ids = prod_id;
    //const value = avaliable_qty - alert_qty;
    ///console.log(value);
    async function getPost(product_ids) {
      let item = { prod_id: product_ids };
      // console.log(item);
      axios.post(globeVar + "product_detail", item).then((response) => {
        if (response.data.type === "success") {
          // console.log(response.data.data.product);
          setvalue(avaliable_qty - alert_qty);
          setquantity(response.data.data.product.qty);
          setalert_qty(response.data.data.product.alert_qty);
          setproductDetails(response.data.data.product);
          setproductBreadcrumb(response.data.data.category)
          // console.log(response.data.data);
          sessionStorage.setItem(value, product_ids);
          setCureentView({
            url: response.data.data.product?.product_video,
            type: "video",
          });
          //sessionStorage.setItem(value);
          setreletedProduct(response.data.data.related_product);
          imageActive();
        }
      });
    }
    getPost(product_ids);
    GetData(product_ids);

    sessionStorage.removeItem("functionality");
    settcs(sessionStorage.getItem("tcs"));

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
      //blah blah blah
    };
  }, []);
  const [copySuccess, setCopySuccess] = useState("");
  function handleCopyClick() {
    handleCopy(
      `https://www.cottage-cart.com/product-cart/${productDetails._id}`
    );
  }
  function handleCopy(textToCopy) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess("Copied");
        setTimeout(() => {
          setCopySuccess("");
        }, 1000);
        // console.log("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  }

  const [pincode, setpincode] = useState();

  function handleClick() {
    let item = { pincode: $("#pincode").val() };
    //console.log(item);
    axios.post(globeVar + "check_pincode", item).then((response) => {
      if (response.data.data.success === true) {
        // console.log("hi");
        // console.log(response.data.data.data);
        // setpincode(response.data.data.data);
        let service = response.data.data.data.serviceable;
        setservice("Service Available");
        // console.log(service);
        let citystatedata =
          response.data.data.data.city +
          "," +
          " " +
          response.data.data.data.state;
        setcityState(citystatedata);
        // console.log(citystatedata);
      } else {
        setservice("Service Not Available");
        setcityState("");
      }
    });
  }

  return (
    <div>
      <ScrollToTop />
      <ToastContainer style={{ width: "fit-content", marginTop: "3%" }} />

      <section className="container container-el cart-section">
        <div className="products">
          <nav
            style={{ "--bs-breadcrumb-divider": "'>'" }}
            aria-label="breadcrumb"
            className="products-first"
          >
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <a onClick={() => prdByCate(productDetails.product_category)}>{productBreadcrumb.category_name ? productBreadcrumb.category_name : ""}</a>
              </li>
              {/* {tcs ? (
                <li className="breadcrumb-item" aria-current="page">
                  <Link to="/product">{tcs}</Link>
                </li>
              ) : (
                <li className="breadcrumb-item" aria-current="page">
                  <Link to="/product">Product</Link>
                </li>
              )} */}
              <li className="breadcrumb-item" aria-current="page">
                {productDetails.product_name}
              </li>
            </ul>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-auto adjust-product d-flex">
            <div className="col-md-auto  d-flex flex-column  small-img-group">
              {productDetails.prod_gal === "Video" ? (
                <div
                  className={`small-img-col mb-2 position-relative ${
                    currentView.url === productDetails?.product_video &&
                    "active"
                  }`}
                  onClick={() =>
                    setCureentView({
                      type: "video",
                      url: productDetails?.product_video,
                    })
                  }
                >
                  <span className="icon-playbutton position-absolute"></span>
                  <img
                    src={productDetails.video_thambnail}
                    className="small-img"
                    alt=""
                  />
                </div>
              ) : (
                <div
                  className={`small-img-col my-2 ${
                    productDetails.product_img ? "active" : "false"
                  }`}
                  onClick={() =>
                    setCureentView({
                      type: "img",
                      url: productDetails.product_zoom_img,
                    })
                  }
                >
                  <img
                    src={productDetails.product_img}
                    className="small-img"
                    alt=""
                  />
                </div>
              )}

              {productDetails.product_gallery
                ? productDetails.product_gallery.slice(0, 3).map((rew, i) => {
                    return (
                      <div
                        className={`small-img-col my-2 ${
                          currentView.url === rew && "active"
                        }`}
                        onClick={() =>
                          setCureentView({
                            type: "img",
                            url: rew,
                          })
                        }
                        key={rew}
                      >
                        <img src={rew} className="small-img" alt="" />
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="col-md-auto   big-image main-image-product  d-flex ">
              {/*  <div className="search-input">
                <span className="icon-search"></span>
              </div> */}
              {productDetails.prod_gal === "Video" ? (
                currentView?.type === "video" ? (
                  <video
                    style={{
                      width: "100%",
                    }}
                    controls={true}
                    autoPlay={true}
                    loop={true}
                    ref={videoRef}
                    muted={true}
                  >
                    <source src={currentView.url} type="video/mp4" />
                  </video>
                ) : (
                  <img className="MainImg" src={currentView.url} alt="" />
                )
              ) : (
                <img
                  className="MainImg"
                  src={productDetails.product_img}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="col-md  col-12  adjust-product-detail">
            <div className="d-flex justify-content-between">
              <p className="product-code mt-0">
                {productDetails.product_name}
                {productDetails.variants}
              </p>
              <div className="text-end wishlist-btn">
                <button>
                  {" "}
                  {wishlist.length > 0 ? (
                    wishlist.includes(productDetails._id) ? (
                      <span
                        className="icon-wishlist-fill"
                        onClick={(e) => changeIcon(e, productDetails._id)}
                      ></span>
                    ) : (
                      <span
                        className="icon-wishlist"
                        onClick={(e) => changeIcon(e, productDetails._id)}
                      ></span>
                    )
                  ) : (
                    <span
                      className="icon-wishlist"
                      onClick={(e) => changeIcon(e, productDetails._id)}
                    ></span>
                  )}
                </button>
              </div>
            </div>

            {/* <div className="rating-sec mt-2">
              <div className="d-flex ">
                <span className="icon-starfill text-warning"></span>
                <span className="icon-starfill text-warning"></span>
                <span className="icon-starfill text-warning"></span>
                <span className="icon-starfill text-warning"></span>
                <span className="icon-starhalf text-warning me-1"></span>

                <p className="mb-0">4.5/5</p>
              </div>
              <p className="mb-0 mt-1">203 Ratings & 31 Reviews</p>
            </div> */}
            <div className="mt-3 amount-sec">
              <div className="d-flex align-items-end ">
                <h5 className="mrp m-0">
                  {user?.role === "retailer" ? "Retailer Price : ₹" + productDetails.retailerPrice * qty + " ( Customer Price : " : ""}
                  &#x20B9;
                  {suscrib !== ""
                    ? productDetails.sub_price * suscrib * qty
                    : productDetails.saving
                    ? (productDetails.price - productDetails.saving) * qty
                    : productDetails.price * qty
                  }
                  {user?.role === "retailer" ? " )" : ""}
                </h5>

                {user?.role !== "retailer" && productDetails.discount ? (
                  <del className="off">
                    &#x20B9;{productDetails.price * qty}
                  </del>
                ) : (
                  ""
                )}
                {suscrib === "" ? (
                  productDetails.discount ? (
                    user?.role !== "retailer" && <p className="disc">{productDetails.discount}%off</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <p className="m-0 taxes-sec">(Incl. of all taxes)</p>
            </div>
            <hr />

            {parseInt(avaliable_qty) > parseInt(alert_qty) ? (
              <div className="input-sect" key={data}>
                <p className="mb-2">Check Delivery service availability</p>
                <div className="d-flex">
                  <form></form>
                  <input type="text" placeholder="Enter Pincode" id="pincode" />
                  <button onClick={() => handleClick()}>Check</button>
                </div>
              </div>
            ) : (
              <div className="input-sect" key={data}>
                <p className="mb-2">Check Delivery service availability</p>
                <div className="d-flex">
                  <form></form>

                  <input
                    type="text"
                    placeholder="Enter Pincode"
                    id="pincode"
                    readOnly
                  />
                  <button style={{ backgroundColor: "gray" }}>Check</button>
                </div>
              </div>
            )}
            <p className="mb-2">{service}</p>
            <p className="mb-2">{cityState}</p>

            {/* <hr /> */}

            {/* {parseInt(avaliable_qty) > parseInt(alert_qty) ? (
              <p className="mb-2" style={{ color: "green" }} id="qty">
                Remaning QTY : {avaliable_qty - alert_qty}
              </p>
            ) : (
              <p className="mb-2" style={{ color: "red" }}>
                {" "}
                Out Of Stock
              </p>
            )} */}

            {/* <p className="mb-2">Avaliable : {qty1}</p>
              <p className="mb-2">Alert QTY: {alert_qty}</p> */}
            {productDetails.subscription === "true" ? (
              parseInt(avaliable_qty) > parseInt(alert_qty) ? (
                <div className="input-sect select-sub-plan mt-3">
                  <select
                    className="form-select input mb-3"
                    name="sub_plan"
                    id="sub_plan"
                    onChange={(e) => subscribe(e)}
                    style={{width : 'fit-content'}}
                  >
                    <option value="">Select Subscription Plans </option>
                    <option value="Every 3 months">Every 3 months</option>
                    <option value="Every 6 months">Every 6 months</option>
                    <option value="Every 8 months">Every 8 months</option>
                    <option value="Every 12 months">Every 12 months</option>
                  </select>
                </div>
              ) : (
                <div className="input-sect select-sub-plan mt-3">
                  <select
                    className="form-select input mb-3"
                    name="sub_plan"
                    id="sub_plan"
                    onChange={(e) => subscribe(e)}
                    style={{width : 'fit-content'}}
                  >
                    <option value="">Select Subscription Plans </option>
                  </select>
                </div>
              )
            ) : (
              ""
            )}

            {/* {productDetails.subscription === "true" ?
              <div className="dropdown-red">
                <div className="dropdown">
                  <button className="btn btn-secondary w-100 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Select Subscription Plans
                  </button>
                  
                  <ul className="dropdown-menu w-100 " name="sub_plan" id="sub_plan" onSelect={(e) => subscribe(e)} >
                    <li><a className="dropdown-item">Every 3 months</a></li>
                    <li><a className="dropdown-item">Every 6 months</a></li>
                    <li><a className="dropdown-item">Every 9 months</a></li>
                    <li><a className="dropdown-item">Every 12 months</a></li>
                  </ul>
                </div>
              </div>
              : ""} */}

            {parseInt(avaliable_qty) > parseInt(alert_qty) ? (
              <div className="quantity-sec mt-3">
                <p className="mb-2">Quantity</p>
                <div className="d-flex">
                  <button className="dec" onClick={decrementValue}>
                    -
                  </button>
                  <input defaultValue="1" name="qty" id="number" readOnly />
                  <button className="inc" onClick={incrementValue}>
                    +
                  </button>
                </div>
                {msg}
              </div>
            ) : (
              <div className="quantity-sec mt-3" style={{ display: "none" }}>
                <p className="mb-2">Quantity12</p>
                <div className="d-flex">
                  <button className="dec" onClick={decrementValue}>
                    -
                  </button>
                  <input defaultValue="1" name="qty" id="number" readOnly />
                  <button className="inc" onClick={incrementValue}>
                    +
                  </button>
                </div>
                {msg}
              </div>
            )}

            {parseInt(avaliable_qty) > parseInt(alert_qty) ? (
              <button
                className="add-to-cart-btn"
                onClick={() =>
                  add_card(productDetails._id, $("#sub_plan").val())
                }
                id="cart"
              >
                <span className="icon-bascket"></span>Add To Cart
              </button>
            ) : (
              <button
                className="add-to-cart-btn"
                id="cart"
                style={{ backgroundColor: "gray" }}
              >
                <span className="icon-bascket"></span>Out Of Stock
              </button>
            )}

            <div className=" mt-3">
              <p className="mb-2">Share</p>
              <div className="d-flex">
                {/* copylink */}
                {copySuccess === "Copied" ? (
                  copySuccess
                ) : (
                  <img
                    src={LinkImage}
                    onClick={handleCopyClick}
                    alt=""
                    className="cursor-pointer"
                    width={32}
                    height={32}
                    title={`Link Copy: ${productDetails.product_name}`}
                    style={{ marginRight: "10px" }}
                  />
                )}

                <WhatsappShareButton
                  url={`https://www.cottage-cart.com/product-cart/${productDetails._id}`}
                  title={`Check out this awesome product: ${productDetails.product_name}`}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <FacebookShareButton
                  url={`https://www.cottage-cart.com/product-cart/${productDetails._id}`}
                  title={`Check out this awesome product: ${productDetails.product_name}`}
                >
                  <FacebookIcon
                    size={32}
                    round
                    style={{ marginLeft: "10px" }}
                  />
                </FacebookShareButton>

                {/* <a href={`https://www.instagram.com/?url=https://www.cottage-cart.com/product-cart/${productDetails._id}`}>
                  <img src={instagram} title={`Check out this awesome product: ${productDetails.product_name}`}
                    style={{ marginLeft: "13%" }} width="45" height="45" /></a>


                <a href={`https://www.instagram.com/?url=https://www.cottage-cart.com/product-cart/${productDetails._id}`}>
                  <img src={facebookmessenger} title={`Check out this awesome product: ${productDetails.product_name}`}
                    style={{ marginLeft: "3%", width: "75%", height: "95%" }} /></a> */}
              </div>
              {/* <div className="my-5 py-3"></div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="blogs">
        <div className="container container-el px-0">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Description
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Specifications
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Additional Details
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {productDetails.description ? parse(productDetails.description) : ""}
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              {" "}
              {productDetails.specification ? parse(productDetails.specification) : ""}
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              {" "}
              {productDetails.additional_details ? parse(productDetails.additional_details) : ""}
            </div>
          </div>
        </div>
      </section>

      <section className="popular-section">
        <div className="container">
          <div className="product-headeing-section releted-product-underline">
            <h5>Related Products</h5>
            <img src={underline} alt="" />
          </div>
          <div className="popular-slider-row swiper">
            <div className="coloured-bg">
              <div className=" popularSwiper">
                <div className="swiper-wrapper">
                  <OwlCarousel
                    items={5}
                    margin={20}
                    rewind={true}
                    {...opti}
                    autoplay={true}
                    autoplayTimeout={2000}
                  >
                    {reletedProduct.map((data) => {
                      return (
                        <div
                          className="swiper-slide slide-width"
                          key={data._id}
                        >
                          <div className="card">
                            {user?.role !== "retailer" && data.discount ? (
                              <span className="price-tag">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="69.521"
                                  height="74.698"
                                  viewBox="0 0 69.521 74.698"
                                >
                                  <defs>
                                    <filter
                                      id="_281639d9032fd35ab631d5064a2c1da9"
                                      x="0"
                                      y="5.177"
                                      width="69.521"
                                      height="69.521"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feOffset dy="3" input="SourceAlpha" />
                                      <feGaussianBlur
                                        stdDeviation="2"
                                        result="blur"
                                      />
                                      <feFlood
                                        floodColor="#7a6b6b"
                                        floodOpacity="0.361"
                                      />
                                      <feComposite operator="in" in2="blur" />
                                      <feComposite in="SourceGraphic" />
                                    </filter>
                                  </defs>
                                  <g
                                    id="Component_59_1"
                                    data-name="Component 59 – 1"
                                    transform="translate(6.865 0.507)"
                                  >
                                    <g
                                      id="Group_272"
                                      data-name="Group 272"
                                      transform="translate(-357.104 -1330.704)"
                                    >
                                      <g
                                        id="Group_269"
                                        data-name="Group 269"
                                        transform="translate(278 -41)"
                                      >
                                        <g id="Group_268" data-name="Group 268">
                                          <g
                                            id="Group_267"
                                            data-name="Group 267"
                                            className="discount"
                                          >
                                            <g
                                              transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                              filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                                            >
                                              <path
                                                id="_281639d9032fd35ab631d5064a2c1da9-2"
                                                data-name="281639d9032fd35ab631d5064a2c1da9"
                                                d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                                transform="translate(5.45 35.39) rotate(-45)"
                                                fill="#fff1f5"
                                                stroke="#ee7e9e"
                                                strokeWidth="1"
                                                className="rotate-tag"
                                              />
                                            </g>
                                            <text
                                              id="_15_"
                                              className="_15_"
                                              data-name="15%"
                                              transform="translate(98 1413)"
                                              fontSize="12"
                                              fontFamily="Comfortaa-Bold, Comfortaa"
                                              fontWeight="700"
                                            >
                                              <tspan x="0" y="0">
                                                15%
                                              </tspan>
                                            </text>
                                          </g>
                                        </g>
                                      </g>
                                      <g
                                        id="Group_270"
                                        data-name="Group 270"
                                        className="hook"
                                        transform="translate(277.674 -41)"
                                      >
                                        <path
                                          id="Path_156"
                                          data-name="Path 156"
                                          d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                          transform="translate(1997.472 4421.696)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_155"
                                          data-name="Path 155"
                                          d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                          transform="translate(1980.552 4427.111)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              ""
                            )}
                            <a href={`/product-cart/${data._id}`}>
                              <img
                                src={data.product_img}
                                className="card-img-top"
                                alt="..."
                              />
                            </a>

                            <button className="like-btn border-0 bg-transparent">
                              {wishlist.length > 0 ? (
                                wishlist.includes(data._id) ? (
                                  <span
                                    className="icon-wishlist-fill"
                                    onClick={(e) => changeIcon(e, data._id)}
                                  ></span>
                                ) : (
                                  <span
                                    className="icon-wishlist"
                                    onClick={(e) => changeIcon(e, data._id)}
                                  ></span>
                                )
                              ) : (
                                <span
                                  className="icon-wishlist"
                                  onClick={(e) => changeIcon(e, data._id)}
                                ></span>
                              )}
                            </button>
                            <div className="card-body">
                              <h4>{data.product_name}</h4>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  {user?.role === "retailer" ? "₹" + data.retailerPrice + " ( CP : " : ""}
                                  ₹
                                  {data.saving
                                    ? data.price - data.saving
                                    : data.price}{" "}
                                  {data.discount ? (
                                    user?.role !== "retailer" && <del className="ms-1">₹{data.price}</del>
                                  ) : (
                                    ""
                                  )}
                                  {user?.role === "retailer" ? " )" : ""}
                                </span>
                                <button
                                  onClick={() => add_card_data(data._id)}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <img src={basket} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

window.onload = function () {};

export default ProductCart;
