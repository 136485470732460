import React, { useState, useEffect } from "react";
import logo from "../../assets/img/cottage-cart-Logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import globeVar from "../../GlobeApi";
import ScrollToTop from "./ScrollToTop";
import promoIcon from '../../assets/img/Address/promos.png'
import couponIconImg from '../../assets/img/Address/coupon-icon.png'
import offerAppliedIcon from '../../assets/img/Address/offer-applied-icon.png'
import basket from "../../assets/img/homepage/basket.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Address() {
 
  const navigate = useNavigate();
  var user = sessionStorage.getItem("user");
  user = JSON.parse(user);

  const [productCart, setproductCart] = useState([]);
  const [offerlist, setofferlist] = useState([]);
  const [open, setOpen] = useState(false);
  const [wallet, setwallet] = useState('');
  const [usedWalletAmount, setusedWalletAmount] = useState(0);
  const [user_wallet, setuser_wallet] = useState('');
  const [refferal_data, setrefferal_data] = useState({});
  const [refferal_Code, setrefferal_Code] = useState("");
  var [totalAmount, settotalAmount] = useState("");
  var [checkoutAmount, setcheckoutAmount] = useState(0);
  var totalCartPrice = 0;

  const [Address, setAddress] = useState({
    user_id: user._id,
    fname: "",
    name: "",
    phone: "",
    pincode: "",
    address: "",
    town: "",
    country: "",
    state: "",
    check: ""
  });

  const [cityState, setcityState] = useState('');
  const [service, setservice] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setphoneError] = useState('');
  const [pincodeError, setpincodeError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [townError, settownError] = useState('');
  const [countryError, setcountryError] = useState('');
  const [stateError, setstateError] = useState('');
  // const [checkErr, setcheckErr] = useState('');

  const [EditnameError, setEditNameError] = useState('');
  const [EditfnameError, setEditfNameError] = useState('');
  const [EditphoneError, setEditphoneError] = useState('');
  const [EditpincodeError, setEditpincodeError] = useState('');
  const [EditaddressError, setEditAddressError] = useState('');
  const [EdittownError, setEdittownError] = useState('');
  const [EditcountryError, setEditcountryError] = useState('');
  const [EditstateError, setEditstateError] = useState('');
  // const [EditcheckError, setEditcheckError] = useState('');


  const [refferalCode, setrefferalCode] = useState({
    refferal_code: "",
  });
  const { refferal_code } = refferalCode;

  const [couponCode, setcouponCode] = useState({
    coupon_code: "",
  });

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidPhone(phone) {
    return /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phone);
  }
  const { coupon_code } = couponCode;                 //check//
  const { user_id, fname, name, phone, pincode, address, town, country, state } = Address;

  const [login, setlogin] = useState({
    phone: "",
  });
  const handelInput = (e) => {
   
    setAddress({ ...Address, [e.target.name]: e.target.value });
  };


  const handelInput1 = (e) => {
    var error_new = $('.error_new');
    if (!(isValidEmail(e.target.value) || (isValidPhone(e.target.value)))) {
      //console.log('Email is invalid');
      error_new.css("color", "Red");
      error_new.html('Phone No is Invalid');
    } else {
      //setError(null);
      error_new.css("color", "green");
      error_new.html('Valid');
    }
    // console.log(e.target.value);
    setlogin({ ...Address, [e.target.name]: e.target.value });
    setAddress({ ...Address, [e.target.name]: e.target.value });
  };

  const handelInput_refferalCode = (e) => {
    setrefferalCode({ ...refferalCode, [e.target.name]: e.target.value });
  };

  const handelInput_couponCode = (e) => {
    setcouponCode({ ...coupon_code, [e.target.name]: e.target.value });
  };
  
  //var totalAmount = '';
  //var checkoutAmount = '';


  const setTotalCheckoutAmount = () => {
    // console.log(totalCartPrice, refferalAmount, couponAmount);
    // console.log("refferal_data", refferal_data);
    // console.log("coupon_data", coupon_data);

    // let preTotal = 0;
    // if(refferal_data.refferal_type){
    //   console.log("refferal_data", refferal_data.referral_type);
    //   if(refferal_data.refferal_type === "Percentage"){
    //     setRefferalAmount( (totalCartPrice.toFixed(2) * refferal_data.value) / 100 > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * refferal_data.value) / 100 )
    //   }else if(refferal_data.refferal_type === "Amount"){
    //     setRefferalAmount( parseFloat(refferal_data.value) )
    //   }
    // }

    // if(coupon_data.coupon_type){
    //   console.log("coupon_data", coupon_data.coupon_type);
    //   if(coupon_data.coupon_type === "Percentage"){
    //     const cal_price = (totalCartPrice.toFixed(2) * coupon_data.value) / 100;
    //     console.log('cal_price', cal_price);
    //     const coup_amt = cal_price > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : cal_price ;
    //     setCouponAmount (coup_amt);
    //   }else if(coupon_data.coupon_type === "Amount"){
    //     const coup_amt = parseFloat(coupon_data.value) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : parseFloat(coupon_data.value);
    //     setCouponAmount(coup_amt);
    //   }
    // }

    // preTotal = totalCartPrice.toFixed(2) - refferalAmount.toFixed(2) - couponAmount.toFixed(2);

    // const total = preTotal > 0 ? preTotal : 1;
    
    const total = (refferal_data.refferal_type || coupon_data.coupon_type
      ? refferal_data.refferal_type === "Percentage" &&
        coupon_data.coupon_type === "Percentage"
        ? totalCartPrice.toFixed(2) -
          ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
          + 
          ((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
        : refferal_data.refferal_type === "Percentage" &&
          coupon_data.coupon_type === "Amount"
          ? totalCartPrice.toFixed(2) -
            (((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
            +
            (parseFloat(coupon_data.value)))
          : refferal_data.refferal_type === "Amount" &&
            coupon_data.coupon_type === "Percentage"
            ? totalCartPrice.toFixed(2) -
              (((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
              +
              (parseFloat(refferal_data.value)))
            : refferal_data.refferal_type === "Amount" &&
              coupon_data.coupon_type === "Amount"
              ? totalCartPrice.toFixed(2) -
                ((parseFloat(refferal_data.value))
                +
                (parseFloat(coupon_data.value)))
              : refferal_data.refferal_type
                ? refferal_data.refferal_type === "Percentage"
                  ? totalCartPrice.toFixed(2) -
                  ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )

                  : refferal_data.refferal_type === "Amount"
                    ? totalCartPrice.toFixed(2) - (parseFloat(refferal_data.value))
                    : 0
                : coupon_data.coupon_type
                  ? coupon_data.coupon_type === "Percentage"
                    ? totalCartPrice.toFixed(2) -
                    ( ((totalCartPrice.toFixed(2) * coupon_data.value) /
                    100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : ((totalCartPrice.toFixed(2) * coupon_data.value) / 100) )
                    : coupon_data.coupon_type === "Amount"
                      ? totalCartPrice.toFixed(2) - (parseFloat(coupon_data.value))
                      : totalCartPrice.toFixed(2)
                  : totalCartPrice.toFixed(2)
      : totalCartPrice.toFixed(2)) < 0 ? 1
      :

      (refferal_data.refferal_type || coupon_data.coupon_type
      ? refferal_data.refferal_type === "Percentage" &&
        coupon_data.coupon_type === "Percentage"
        ? totalCartPrice.toFixed(2) -
          ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
          + 
          ((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
        : refferal_data.refferal_type === "Percentage" &&
          coupon_data.coupon_type === "Amount"
          ? totalCartPrice.toFixed(2) -
            (((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
            +
            (parseFloat(coupon_data.value)))
          : refferal_data.refferal_type === "Amount" &&
            coupon_data.coupon_type === "Percentage"
            ? totalCartPrice.toFixed(2) -
              (((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
              +
              (parseFloat(refferal_data.value)))
            : refferal_data.refferal_type === "Amount" &&
              coupon_data.coupon_type === "Amount"
              ? totalCartPrice.toFixed(2) -
                ((parseFloat(refferal_data.value))
                +
                (parseFloat(coupon_data.value)))
              : refferal_data.refferal_type
                ? refferal_data.refferal_type === "Percentage"
                  ? totalCartPrice.toFixed(2) -
                  ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )

                  : refferal_data.refferal_type === "Amount"
                    ? totalCartPrice.toFixed(2) - (parseFloat(refferal_data.value))
                    : 0
                : coupon_data.coupon_type
                  ? coupon_data.coupon_type === "Percentage"
                    ? totalCartPrice.toFixed(2) -
                    ( ((totalCartPrice.toFixed(2) * coupon_data.value) /
                    100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : ((totalCartPrice.toFixed(2) * coupon_data.value) / 100) )
                    : coupon_data.coupon_type === "Amount"
                      ? totalCartPrice.toFixed(2) - (parseFloat(coupon_data.value))
                      : totalCartPrice.toFixed(2)
                  : totalCartPrice.toFixed(2)
      : totalCartPrice.toFixed(2));


    if (total !== totalAmount) {
      // console.log(totalCartPrice, refferalAmount, couponAmount);
      setcheckoutAmount(total);
      settotalAmount(total);
      // console.log((total));
    }
  };


  const handelSubmit_refferal = async (e) => {
    e.preventDefault();
    const item = { userId: user._id, referral_code: refferal_code };
    // console.log(item);
    await axios.post(globeVar + "add_referral_code", item).then((response) => {
      if (response.data.type === "success") {
        if(checkoutAmount >= parseFloat(response.data.data[0].min_purchase_value)){
          response.data._id = response.data.data[0]._id;
          response.data.value = response.data.data[0].value;
          response.data.max_value = response.data.data[0].max_value;
          response.data.min_purchase_value = response.data.data[0].min_purchase_value;
          response.data.refferal_type = response.data.data[0].referral_type;
          response.data.title = response.data.data[0].title;
          setrefferal_data(response.data);
          setrefferal_Code(refferal_code);

          $(".show_referral").show();
          $("#refferal_form").hide();
        }else{
          toast.error(`To apply this refferal minimun order value should be ₹ ${response.data.data[0].min_purchase_value}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} alt="basket" />,
            style: { background: '#c9184a' },
          });
        }
      } else {
        setrefferal_data(response.data);
        setrefferal_Code("");

      }
    });
    /* navigate("/dashboard"); */
  };


   

  const [coupon_data, setcoupon_data] = useState({});
  const [checkout_amonut, setcheckout_amonut] = useState(0);

  const [coupon_Code, setcoupon_Code] = useState("");
  const handelSubmit_coupon = async (e) => {
    e.preventDefault();
    const item = { userId: user._id, coupon_code: coupon_code };
    // console.log(item);
    await axios.post(globeVar + "add_coupon_code", item).then((response) => {
      if (response.data.type === "success") {
        response.data._id = response.data.data._id;
        response.data.value = response.data.data.value;
        response.data.coupon_type = response.data.data.coupon_type;
        response.data.coupon_img = response.data.data.coupon_img;
        response.data.title = response.data.data.title;
        setcoupon_data(response.data);
        setcoupon_Code(coupon_code);
        $(".show_coupon").show();
        $("#coupon_form").hide();
      } else {
        setcoupon_data(response.data);
        setcoupon_Code("");
      }
    });
    /* navigate("/dashboard"); */
  };
  const remove_refferal = () => {
    $(".show_referral").hide();
    setrefferalCode({ ...refferalCode, refferal_code: "" });
    $("#refferal_form").show();
    setrefferal_data({})
  };
  const remove_coupon = () => {
    $(".show_coupon").hide();
    setcouponCode({ ...coupon_code, coupon_code: "" });
    $("#coupon_form").show();
    setcoupon_data({});
  };
  const handelSubmit = async (e) => {
    
    e.preventDefault();
    // console.log("Address");
    // validate inputs
    if (name === '') {
      setNameError('Please enter a name.');
    } else if (!name.match(/^[a-zA-Z, ]+$/)) {
      setNameError('Please enter a character');
    } else {
      setNameError('');
    }

    if (fname === '') {
      setNameError('Please enter a name.');
    } else if (!name.match(/^[a-zA-Z, ]+$/)) {
      setNameError('Please enter a character');
    } else {
      setNameError('');
    }

    if (address === '') {
      setAddressError('Please enter an address.');
    } else {
      // alert(address);
      setAddressError('');
    }

    if (phone === '') {
      setphoneError('Please enter a phone.');
    } else if (phone.length != 10) {
      setphoneError('Please enter a valid number');
    } else {
      setphoneError('');
    }

    if (town === '') {
      settownError('Please enter a town.');
    } else {
      settownError('');
    }

    if (pincode === '') {
      setpincodeError('Please enter a pincode code.');
    } else if (pincode.length != 6) {
      setpincodeError('Enter pincode must be 6 digit.');
    } else {
      setpincodeError('');
    }

    if (country === '') {
      setcountryError('Please enter a your country.');
    } else {
      setcountryError('');
    }

    if (state === '') {
      setstateError('Please enter a your state.');
    } else {
      setstateError('');
    }
    // if (check === '') {
    //   setcheckErr('Please enter a your correct pincode .');
    // } else {
    //    setcheckErr('');
    // }
                                                                                                                                                  // check
    
    if ((name.match(/^[a-zA-Z, ]+$/)) && fname && address && (phone.length != 10) == false && town && country && state && (pincode.length != 6) == false     )  {
     
    
      const item = {
        userID: Address.user_id,
        firstName: Address.fname,
        lastName: Address.name,
        mobno: Address.phone,
        pincode: Address.pincode,
        address1: Address.address,
        city: Address.town,
        country: Address.country,
        state: Address.state
      };
      // console.log(item);
      await axios.post(globeVar + "create_add", item).then((response) => {
        if (response.data.type === "success") {
          // console.log(response.data.data);
          window.location.href = "/address";
        }
      });

    }
    /* navigate("/dashboard"); */
  };

  //  Pincode Check 
  function handleClick1() {
    let item = { pincode: $('#pin_code').val() };
    axios.post(globeVar + "check_pincode", item).then((response) => {
      
      if (response.data.data.success === true) {
        $('#add_address').show();
        $('#add').hide();
         $('#check').val('1');
        let service = response.data.data.data.serviceable;
        setservice("Service Available");
       // console.log(service);
        let citystatedata = response.data.data.data.city + ',' + ' ' + response.data.data.data.state;
        setcityState(citystatedata)
       // console.log(citystatedata);
      }
      else {
        $('#add').show();
        $('#add_address').hide();
        $('#check').val('');
        setservice("Service Not Available");
        setcityState('');
       
      }
    });
  }
  const [addList, setaddList] = useState([]);
  const Addresslist = () => {
    const item = { userID: user._id };

    axios.post(globeVar + "address_listing", item).then((response) => {
      if (response.data.type === "success") {
        setaddList(response.data.data);
        // console.log(response.data.data);
      }
    });
  };

  const deleteAddress = (id, user_id) => {
    const item = { _id: user_id };
    axios.post(globeVar + "delete_address/" + id, item).then((response) => {
      // console.log(response);
      if (response.data.type === "success") {
        setaddList(response.data.data);
      }
    });
  };
  const [edit_data, setedit_data] = useState([]);
  const [tempAddress, settempAddress] = useState([]);
  const editAddress = async (id) => {
    $('.address_form_error').html("")
    await axios.post(globeVar + `edit_address/${id}`).then((response) => {
      if (response.data.type === "success") {
        setedit_data(response.data.data);
        settempAddress(response.data.data);

        if (response.data.data.default_status === "on") {
          $("#edt_checkdefault").prop("checked", true);
        }
      }
    });
  };

  const handelSubmitAddress = async (e) => {
    e.preventDefault();
    // console.log("edit_data", edit_data);
    // console.log("tempAddress", tempAddress);
    // validate inputs
    if (tempAddress.firstName === '') {
      setEditNameError('Please enter a name.');
    } else if (!tempAddress.firstName.match(/^[a-zA-Z, ]+$/)) {
      setEditNameError('Please enter a character.');
    }
    else {
      setEditNameError('');
    }


    if (tempAddress.lastName === '') {
      setEditfNameError('Please enter a name.');
    } else if (!tempAddress.lastName.match(/^[a-zA-Z, ]+$/)) {
      setEditfNameError('Please enter a character.');
    }
    else {
      setEditfNameError('');
    }

    if (tempAddress.address1 === '') {
      setEditAddressError('Please enter an address.');
    } else {
      setEditAddressError('');
    }

    if (tempAddress.mobno === '') {
      setEditphoneError('Please enter a mobile number.');
    } else if (tempAddress.mobno.length != 10) {

      setEditphoneError('Please enter valid  Mobile Number')
    } else {
      setEditphoneError('');
    }

    if (tempAddress.city === '') {
      setEdittownError('Please enter a town.');
    } else {
      setEdittownError('');
    }

    if (tempAddress.pincode === '') {
      setEditpincodeError('Please enter a pincode code.');
    } else if (tempAddress.pincode.length != 6) {
      setEditpincodeError('Enter pincode must be 6 digit.');
    } else {
      setEditpincodeError('');
    }

    if (tempAddress.country === '') {
      setEditcountryError('Please enter a your Country.');
    } else {
      setEditcountryError('');
    }

    if (tempAddress.state === '') {
      setEditstateError('Please enter a your state.');
    } else {
      setEditstateError('');
    }
    /* const defaultAddress = $("input[name='edt_checkdefault']:checked").val();
    if (defaultAddress) {
      tempAddress.defaultAddress = defaultAddress;
    } else {
      tempAddress.defaultAddress = "off";
    } */
    if ((!tempAddress.firstName.match(/^[a-zA-Z, ]+$/)) == false && (!tempAddress.lastName.match(/^[a-zA-Z, ]+$/)) == false && (tempAddress.mobno.length != 10) == false && (tempAddress.pincode.length != 6) == false && tempAddress.address1 && tempAddress.city) {

      const item = {
        _id: tempAddress._id,
        userID: tempAddress.userID,
        firstName: tempAddress.firstName,
        lastName: tempAddress.lastName,
        mobno: tempAddress.mobno,
        pincode: tempAddress.pincode,
        address1: tempAddress.address1,
        city: tempAddress.city,
        country: tempAddress.country,
        state: tempAddress.state
      };
      // console.log(item);
      await axios.post(globeVar + "update_address", item).then((response) => {
        if (response.data.type === "success") {
          setaddList(response.data.data);
          // console.log(response.data.data);
          window.$("#exampleModal_edit").modal("hide");
          /* window.location.href = '/address'; */
        }
      });
    }
    /* navigate("/dashboard"); */
  };



  const applyCode = async (coupon_code) => {
    const item = { userId: user._id, coupon_code: coupon_code };
    // console.log(item);
    // console.log("checkout Amount",checkoutAmount);
    await axios.post(globeVar + "add_coupon_code", item).then((response) => {
      // console.log("api resp", response);
      if (response.data.type === "success") {
        if(checkoutAmount >= parseFloat(response.data.data.min_purchase_value)){
          response.data._id = response.data.data._id;
          response.data.value = response.data.data.value;
          response.data.max_value = response.data.data.max_value;
          response.data.min_purchase_value = response.data.data.min_purchase_value;
          response.data.coupon_type = response.data.data.coupon_type;
          response.data.coupon_img = response.data.data.coupon_img;
          response.data.title = response.data.data.title;
          setcoupon_data(response.data);
          setcoupon_Code(coupon_code);
          $(".show_coupon").show();
          $("#coupon_form").hide();
          // setTotalCheckoutAmount();
        }else{
          toast.error(`To apply this coupon minimun order value should be ₹ ${response.data.data.min_purchase_value}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} alt="basket" />,
            style: { background: '#c9184a' },
          });
        }
      } else {
        setcoupon_data(response.data);
        setcoupon_Code("");
      }
    });
  }
  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };


  const displayRazorpay = async (amount, discount, coupon_dis) => {


    if (Checked) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
        return;
      }


      const options = {
        key: "rzp_test_TxPF78wfnf4BfV",
        currency: "INR",
        amount: Math.ceil(amount) * 100,
        name: user.first_name,
        description: `Thanks for purchasing`,
        image: { logo },

        handler: function (response) {
          // console.log(response);
          const item = {
            userId: user_id, amount: parseFloat(amount), Address: Checked, paymentId: response.razorpay_payment_id,
            referral_code: refferal_Code, coupon_code: coupon_Code, discount: JSON.stringify(discount), saving: coupon_dis
          }
          // console.log(item);


          axios.post(globeVar + "create_order", item).then((response) => {

            if (response.data.type === "success") {
              axios.post(globeVar + "empty_cart/" + user_id).then((response) => {
                if (response.data.type === "success") {
                  navigate('/payment-success');
                }
              })
            }
          });
        },
        prefill: {
          name: user.first_name,
        },
      };



      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      toast.error('Select / Enter Address', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: <img src={basket} />,
        style: { background: '#c9184a' },
      });
    }
  };

  // CC-Avnue code 
  const [hash, sethash] = useState([]);
  const [shash, setshash] = useState([]);



  function handleClick2(user_id, amount, discount, coupon_dis) {
    // console.log('hi');
    if (Checked) {


      const item = {
        amount: Math.ceil(amount) * 100,
        "userId": user_id,
        "amount": parseFloat(checkoutAmount),
        "Address": Checked,
        // "paymentId": "",
        "referral_code": refferal_Code,
        "coupon_code": coupon_Code,
        "discount": discount,
        "saving": coupon_dis,
        "iswallet": $("#flexCheckIndeterminate").prop('checked'),
        "walletamt": wallet

        //   "userId": user_id,
        //  "amount":  checkoutAmount,
        //   "Address": Checked,
        //   "referral_code": refferal_Code,
        //   "coupon_code": coupon_Code,
        //   "discount": discount,
        //   "saving": coupon_dis,
        //   "iswallet": $("#flexCheckIndeterminate").prop('checked'),
        //   "walletamt": wallet
      }
      // console.log(item);
      // console.log(user_id);





      axios.post(globeVar + "create_order", item).then((response) => {
        // console.log(response.data.data.orderid);
        if (response.data.type === "success") {
          axios.post(globeVar + "empty_cart/" + user_id).then((response) => {
            // console.log(response);
            window.location.href = '/order-success';
          })
        }
      });


    }
    else {
      toast.error('Select / Enter Address', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: <img src={basket} />,
        style: { background: '#c9184a' },
      });
    }
  }

  function handleClick(user_id, amount, discount, coupon_dis) {
    if (Checked) {
      document.getElementById('checkout').disabled = true;
      document.getElementById('checkout').style.opacity = "0.5";
      // parseFloat(amount)
      toast.error('We are processing your order please wait...', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: <img src={basket} />,
        style: { background: '#c9184a' },
      });
      const item = {
        amount: Math.ceil(amount) * 100,
        "userId": user_id,
        "amount": parseFloat(amount),
        "Address": Checked,
        // "paymentId": "",
        "referral_code": refferal_Code,
        "coupon_code": coupon_Code,
        "discount": discount,
        "saving": coupon_dis,
        "iswallet": $("#flexCheckIndeterminate").prop('checked'),
        "walletamt": wallet

        //   "userId": user_id,
        //  "amount":  checkoutAmount,
        //   "Address": Checked,
        //   "referral_code": refferal_Code,
        //   "coupon_code": coupon_Code,
        //   "discount": discount,
        //   "saving": coupon_dis,
        //   "iswallet": $("#flexCheckIndeterminate").prop('checked'),
        //   "walletamt": wallet
      }
      // console.log(item);
      // console.log(user_id);

      axios.post(globeVar + "create_order", item).then((response) => {

        if (response.data.type === "success") {

          // console.log(response.data.secureHash);
          sethash(response.data.secureHash);
          //  $('#aweberform').submit();
          $("#aweberform").trigger('click');
          axios.post(globeVar + "empty_cart/" + user_id).then((response) => {
            // console.log(response);
          })

        }
      });
    }
    else {
      toast.error('Select / Enter Address', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: <img src={basket} />,
        style: { background: '#c9184a' },
      });
    }
  }
  const [Checked, setChecked] = useState("");
  const isChecked = (values) => values === Checked;
  const selectAddress = ({ target: { value } }) => {
    // console.log(value);
    setChecked(value);
  };



  // walet code 


  const [amounts, setamounts] = useState('');
  const [isCheckeds, setIsChecked] = useState(false);

  const selectwallet = (e) => {

    // console.log(totalCartPrice.toFixed(2)-10);
    const value = wallet;
    const checkBox = $(e.target);
    const walletAmount = checkBox.val();
    // console.log(wallet);
    if (checkBox.prop('checked')) {
        
      setcheckoutAmount(parseFloat((((totalAmount - walletAmount).toFixed(2) >= 0) ? totalAmount - walletAmount : 0)).toFixed(2));
      setwallet((((walletAmount - totalAmount) >= 0) ? walletAmount - totalAmount : 0));
      setusedWalletAmount((((walletAmount - totalAmount) >= 0) ? totalAmount : walletAmount));
      // console.log("--------------------------------",(((walletAmount - totalAmount) >= 0) ? totalAmount : walletAmount));
    } else {

      setwallet(user_wallet);
      setusedWalletAmount(0);
      // setwallet(walletAmount + totalAmount);
      setcheckoutAmount(totalAmount);
    }
    /*  totalCartPrice = totalCartPrice.toFixed(2)-wallet;
      console.log(value);
     setamounts(totalCartPrice);
    setIsChecked(value);*/


  };


  

  useEffect(() => {
    window.scrollTo(0, 0);
    const user_id = user._id;
    axios.post(globeVar + "getCart/" + user_id).then((response) => {
      if (response.data.type === "success") {
        setproductCart(response.data.cart.products);
      }
    });
    axios.post(globeVar + "coupon_list").then((response) => {
      if (response.data.type === "success") {
        setofferlist(response.data.data);
      }
    });

    const item = { userId: user_id };
    axios.post(globeVar + "current_user", item).then((response) => {
      setwallet(response.data.data.wallet);
      setuser_wallet(response.data.data.wallet);
    });
    Addresslist();
  }, []);

  return (
    <div>
      <ScrollToTop />
      <ToastContainer style={{ width: "fit-content", marginTop: "3%" }} />
      <section className="address">
        <div className="container">
          <div className="row address-head">
            <div className="text-center">
              <p>
                <Link to={`/my-cart`}>MY CART</Link>
              </p>
              <span>---------</span>
              <p className="my-active">
                <Link to={`/address`}>ADDRESS</Link>
              </p>
              <span>---------</span>
              <p>
                <Link to={`#`}>PAYMENT</Link>
              </p>
            </div>
          </div>
          <div className="address-heading">
            <h3>Select Delivery Address</h3>
          </div>
          <div className="row address-main">
            <div className="col-md-6">
              {/* <h4 className="default">DEFAULT ADDRESS</h4> */}
              {addList.map((data, i) => {
                return (
                  <div className="row address-first" key={data._id}>
                    <div className="col-12 pt-3 ps-3">
                      <p className="input-button">
                        <input
                          type="radio"
                          name="default_Address"
                          id={"default_Address" + i}
                          checked={isChecked(data._id)}
                          value={data._id}
                          onChange={selectAddress}
                        />{" "}
                        <span className="name ms-2">{data.firstName} {data.lastName}</span>{" "}
                        {/* <span className="home ms-2">Home</span> */}
                      </p>
                      <p className="prestige">
                        {data.address1}, <br /> {data.city} - {data.pincode}
                      </p>
                      <p className="prestige">
                        {data.country} - {data.state}
                      </p>
                      <p className="prestige">Mobile - {data.mobno}</p>
                      <p className="ms-4">
                        <span className="romove" style={{ cursor: "pointer" }}>
                          <a
                            onClick={() => deleteAddress(data._id, data.userID)}
                          >
                            REMOVE
                          </a>{" "}
                        </span>
                        <span
                          className="edit ms-3"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            onClick={() => editAddress(data._id)}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal_edit"
                          >
                            EDIT
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
              
              <div
                className="row add-address my-4"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <p href="#" className="m-0 p-3">
                  + Add new address
                </p>
              </div>
              <div
                className="modal"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="d-flex justify-content-center pt-3 modal-dialog"   >
                  <div className="card col-lg-12 col-sm-8 py-4 bg-white shadow border-0 add-new-address-popup modal-content">
                    <div className="d-flex justify-content-between px-4 header">
                      <h6>Add New Address</h6>
                      <div>
                        <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(true)}></span>
                      </div>
                    </div>
                    <hr />
                    <form className="px-4" onSubmit={(e) => handelSubmit(e)} id="detail">
                      <label htmlFor="name" className="mb-3">
                        Contact Details
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="fname"
                          id="fname"
                          onChange={(e) => handelInput(e)}
                          value={fname}
                          placeholder="First Name*"
                        />
                        <span className="address_form_error">{nameError}</span>
                        <input type="hidden" name="user_id" value={user_id} />
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          onChange={(e) => handelInput(e)}
                          value={name}
                          placeholder="Last Name*"
                        />
                        <span className="address_form_error">{nameError}</span>
                        <input type="hidden" name="user_id" value={user_id} />
                      </div>

                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="phone"
                          id="emailpass"
                          min={0}
                          maxLength={10}
                          onChange={(e) => handelInput1(e)}
                          value={phone}
                          placeholder="Mobile No*"
                        />
                        <span className="address_form_error">{phoneError}</span>
                        <p className="error_new"></p>
                      </div>

                      <label htmlFor="pin_code" className="mb-3">
                        Address
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          onChange={(e) => handelInput(e)}
                          value={address}
                          placeholder="Address (House No, Building, Street, Area)*"
                        />
                        <span className="address_form_error">{addressError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          onChange={(e) => handelInput(e)}
                          value={country}
                          placeholder="Country*"
                        />
                        <span className="address_form_error">{countryError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          onChange={(e) => handelInput(e)}
                          value={state}
                          placeholder="State*"
                        />
                        <span className="address_form_error">{stateError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="town"
                          onChange={(e) => handelInput(e)}
                          value={town}
                          placeholder="City*"
                        />
                        <span className="address_form_error">{townError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="pincode"
                          id="pin_code"
                          min={0}
                          onChange={(e) => handelInput(e)}
                          value={pincode}
                          placeholder="Pin Code*"
                        />
                        <span className="address_form_error">{pincodeError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="hidden"
                          className="form-control"
                          name="check"
                          id="check"
                          onChange={(e) => handelInput(e)}   
                           
                        />
                        {/* <span className="address_form_error">{checkErr}</span> */}
                      </div>
                      <p className="mb-2" style={{ 'color': 'black' }}>{service}</p>
                      <p className="mb-2" style={{ 'color': 'black' }}>{cityState}</p>
                      <button type="button" className="btn btn-primary mx-0 mb-1" onClick={() => handleClick1()}>Check</button>
                      {/* <button onClick={() => handleClick1()}>Check</button> <br /><br /> */}
                      <button type="submit" className="btn btn-primary mx-0 w-100" id="add_address">
                        ADD ADDRESS  
                      </button>
                      <button type="button" className="btn btn-primary mx-0 w-100" id="add" style={{display:"none"}} >
                        ADD ADDRESS   
                      </button>
                    </form>
                    
                  </div>
                </div>
              </div>
              <div
                className="modal"
                id="exampleModal_edit"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="d-flex justify-content-center pt-3 modal-dialog">
                  <div className="card col-lg-12 col-sm-8 py-4 bg-white shadow border-0 add-new-address-popup modal-content">
                    <div className="d-flex justify-content-between px-4 header">
                      <h6>Edit Address</h6>
                      <div>
                        <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close"></span>
                      </div>
                    </div>
                    <hr />
                    <form
                      className="px-4"
                      onSubmit={(e) => handelSubmitAddress(e)}
                    >
                      <label htmlFor="name" className="mb-3">
                        Contact Details
                      </label>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="names"
                          id="names"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              firstName: e.target.value,
                            })
                          }
                          value={tempAddress.firstName}
                          placeholder="First Name*"
                        />
                        <span className="address_form_error">{EditnameError}</span>
                        <input type="hidden" name="_id" value={tempAddress._id} />
                      </div>



                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="names"
                          id="names"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              lastName: e.target.value,
                            })
                          }
                          value={tempAddress.lastName}
                          placeholder=" Last Name*"
                        />
                        <span className="address_form_error">{EditfnameError}</span>
                        <input type="hidden" name="_id" value={tempAddress._id} />
                      </div>

                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="phones"
                          min={0}
                          maxLength={10}
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              mobno: e.target.value,
                            })
                          }
                          value={tempAddress.mobno}
                          placeholder="Mobile No*"
                        />
                        <span className="address_form_error">{EditphoneError}</span>
                      </div>

                      <label htmlFor="pin_code" className="mb-3">
                        Address
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="addresss"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              address1: e.target.value,
                            })
                          }
                          value={tempAddress.address1}
                          placeholder="Address (House No, Building, Street, Area)*"
                        />
                        <span className="address_form_error">{EditaddressError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              country: e.target.value,
                            })
                          }
                          value={tempAddress.country}
                          placeholder="Country*"
                        />
                        <span className="address_form_error">{EditcountryError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              state: e.target.value,
                            })
                          }
                          value={tempAddress.state}
                          placeholder="State*"
                        />
                        <span className="address_form_error">{EditstateError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="towns"
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              city: e.target.value,
                            })
                          }
                          value={tempAddress.city}
                          placeholder="Locality / City*"
                        />
                        <span className="address_form_error">{EdittownError}</span>
                      </div>

                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="pincodes"
                          id="pin_codes"
                          min={0}
                          onChange={(e) =>
                            settempAddress({
                              ...tempAddress,
                              pincode: e.target.value,
                            })
                          }
                          value={tempAddress.pincode}
                          placeholder="Pin Code*"
                        />
                        <span className="address_form_error">{EditpincodeError}</span>
                      </div>
                      {/* <div className="form-check mb-3 d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          name="edt_checkdefault"
                          id="edt_checkdefault"
                        />
                        <span
                          className="form-check-label mb-0"
                          htmlFor="checkdefault"
                        >
                          Save this my default address
                        </span>
                      </div> */}
                      <button type="submit" className="btn btn-primary w-100">
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {productCart.map((data) => {
              if (user?.role !== "retailer" && data.sub_plan) {
                totalCartPrice += parseInt(data.sub_price) * parseInt(data.quantity) * parseInt(data.sub_plan.substring(6, 8))
              } else if(user?.role !== "retailer") {
                data.saving
                  ? (totalCartPrice +=
                    (parseInt(data.price) - data.saving) *
                    parseInt(data.quantity))
                  : (totalCartPrice +=
                    parseInt(data.price) * parseInt(data.quantity));
              } else {
                totalCartPrice += parseInt(data.retailerPrice) * parseInt(data.quantity);
              }
            })}
            <div className="col-md-6 address-second">
              <div className="address-form">
                {user?.role !== "retailer" && <h4 className="options">SAVING CORNER</h4>}
                {user?.role !== "retailer" && <div className="success_referral show_referral col-md-12" style={{ display: "none" }}>
                  <h4 className="options">APPLIED REFERRAL</h4>
                  <div className="applied-coupon-cntnr d-flex align-items-center justify-content-between">
                    <div className="coupon-main-div d-flex">
                      <div className="coupon-icon-block d-flex align-self-center">
                        <img src={couponIconImg} alt="" />
                      </div>
                      <div className="coupon-code-offer d-flex flex-column justify-content-between">
                        <span className="cco-text-1">{refferal_data.title}</span>
                        <span className="cco-text-2">{/* You Save <b>₹00.00</b> */}</span>
                      </div>
                    </div>
                    <div className="tag-dlt-main d-flex">
                      <div className="coupon-code-applied d-flex flex-column justify-content-between">
                        <div className="coupon-code-tag">{refferal_Code}</div>
                        <div className="offer-applied d-flex align-items-baseline justify-content-between">
                          <div className="ofr-applied-icon">
                            <img src={offerAppliedIcon} alt="" />
                          </div>
                          <span className="ofr-applied-text">Offer Applied</span>
                        </div>
                      </div>
                      <span className="icon-delete d-flex align-self-start dlt-bucket" onClick={() => remove_refferal()}></span>
                    </div>
                  </div>
                </div>
                }
                {user?.role !== "retailer" && <form
                  action=""
                  className="mt-4 form"
                  id="refferal_form"
                  onSubmit={(e) => handelSubmit_refferal(e)}
                >
                  <div className="d-flex justify-content-between ">
                    <input
                      type="text"
                      className="form-control mb-3 refferal-code-input"
                      name="refferal_code"
                      id="refferal_code"
                      onChange={(e) => handelInput_refferalCode(e)}
                      value={refferalCode.refferal_code}
                      placeholder="Referral Code"
                    />
                    {/*  {refferal_data.type === "success" ?
                      <span>
                        <button type='button' className="btn btn-primary w-100" onClick={() =>remove_refferal()}>X</button>
                      </span>: */}
                    <span>
                      <button type="submit" className="btn btn-primary w-100 refferal-code-btn">
                        Apply
                      </button>
                    </span>
                    {/* } */}
                  </div>
                </form> }
                {/* {refferal_data.type === "success" ? (
                  <p className="discount mb-2">{refferal_data.message}</p>
                ) : (
                  ""
                )} */}
                {refferal_data.type === "failed" ? (
                  <p className="discount mb-2" style={{ color: "#c9184a" }}>{refferal_data.message}</p>
                ) : (
                  ""
                )}
                {/*  <p className="discount mb-2">My First Order 50% Discount</p> */}
                {user?.role !== "retailer" && <hr />}
                {user?.role !== "retailer" && <div className="success_coupon show_coupon col-md-12" style={{ display: "none" }}>
                  <h4 className="options">APPLIED COUPON</h4>
                  <div className="applied-coupon-cntnr d-flex align-items-center justify-content-between">
                    <div className="coupon-main-div d-flex">
                      <div className="coupon-icon-block d-flex align-self-center">
                        <img src={coupon_data.coupon_img} alt="" />
                      </div>
                      <div className="coupon-code-offer d-flex flex-column justify-content-between">
                        <span className="cco-text-1">{coupon_data.title}</span>
                        <span className="cco-text-2">{/* You Save <b>₹00.00</b> */}</span>
                      </div>
                    </div>
                    <div className="tag-dlt-main d-flex">
                      <div className="coupon-code-applied d-flex flex-column justify-content-between">
                        <div className="coupon-code-tag">{coupon_Code}</div>
                        <div className="offer-applied d-flex align-items-baseline justify-content-between">
                          <div className="ofr-applied-icon">
                            <img src={offerAppliedIcon} alt="" />
                          </div>
                          <span className="ofr-applied-text">Offer Applied</span>
                        </div>
                      </div>
                      <span className="icon-delete d-flex align-self-start dlt-bucket" onClick={() => remove_coupon()}></span>
                    </div>
                  </div>
                </div>
                }
                {user?.role !== "retailer" && <form
                  action=""
                  className="mt-4 form"
                  id="coupon_form"
                  onSubmit={(e) => handelSubmit_coupon(e)}
                >
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      className="form-control mb-3 refferal-code-input"
                      name="coupon_code"
                      id="coupon_code"
                      onChange={(e) => handelInput_couponCode(e)}
                      value={couponCode.coupon_code}
                      placeholder="Coupon Code"
                    />
                    <span>
                      <button className="btn btn-primary w-100 refferal-code-btn">Apply</button>
                    </span>
                  </div>
                </form>
                } 
                {/* {coupon_data.type === "success" ? (
                  <p className="discount mb-2">{coupon_data.message}</p>
                ) : (
                  ""
                )} */}
                {coupon_data.type === "failed" ? (
                  <p className="discount mb-2" style={{ color: "#c9184a" }}>{coupon_data.message}</p>
                ) : (
                  ""
                )}
                {/* <p className="discount mb-2">My First Order 50% Discount</p> */}
                {user?.role !== "retailer" && <hr />}
                {user?.role !== "retailer" && <div className="accordion" id="offers-promos-ddExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button op-accord-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#offers-promos-ddOne" aria-expanded="true" aria-controls="offers-promos-ddOne">
                        <div className="promo-icon-div">
                          <img src={promoIcon} alt="" />
                        </div>
                        <span>View All Offers/Promos for you !</span>
                      </button>
                    </h2>
                    <div id="offers-promos-ddOne" className="accordion-collapse collapse" data-bs-parent="#offers-promos-ddExample">
                      <div className="accordion-body  op-accord-body">
                        <h6>BEST OFFERS FOR YOU</h6>
                        {offerlist.map((data, i) => {
                          return <div className="card mb-3" key={data._id}>
                            <div className="card-body">
                              <div className="percent-off-div d-flex align-items-center">
                                <div className="percent-off-img">
                                  <img src={data.coupon_img} alt="" width={32} height={32} />
                                </div>
                                <span className="percent-off-text">{data.title}</span>
                              </div>
                              {/* <p>Use Standard Chartered DigiSmart Credit Card</p> */}
                              {/* <span className="rs-save-text">Save ₹23.9 with this code</span> */}
                              <div className="code-detail-div">

                                <div className="accordion view-detail-accord" id="view-detail-accordExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header d-flex align-items-center justify-content-between">
                                      <div className="promo-code">{data.coupon_code}</div>
                                      <button className="accordion-button view-details-btn" type="button" data-bs-toggle="collapse" data-bs-target={"#view-detail-accordOne" + i} aria-expanded="true" aria-controls="view-detail-accordOne" onClick={() => setOpen(!open)}>
                                        {open ? "View Details" : "Hide Details"}
                                      </button>
                                    </h2>
                                    <div id={"view-detail-accordOne" + i} className="accordion-collapse collapse" data-bs-parent="#view-detail-accordExample">
                                      <div className="accordion-body" dangerouslySetInnerHTML={{ __html: data.terms_and_conditions }}>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer d-flex justify-content-center tap-to-apply" type="button" data-bs-toggle="collapse" data-bs-target="#offers-promos-ddOne" aria-expanded="false" onClick={() => applyCode(data.coupon_code)}>
                              Tap To Apply
                            </div>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>}
                {user?.role !== "retailer" && <hr />}
                {user?.role !== "retailer" && <div>
                  <p className="price">WAllET PAYMENT</p>
                  {

                    wallet <= 0 ?

                      <div className="form-check">
                        <input className="form-check-input me-3 " type="checkbox" value={wallet} onChange={selectwallet} id="flexCheckIndeterminate" />
                        <label className="mrp" for="flexCheckIndeterminate">
                          iCottageCart Wallet <br />
                          Available Balance  : ₹ 0
                        </label>
                      </div>
                      :
                      <div className="form-check">
                        <input className="form-check-input me-3 " type="checkbox" value={wallet} onChange={selectwallet} id="flexCheckIndeterminate" />
                        <label className="mrp" for="flexCheckIndeterminate">
                          iCottageCart Wallet <br />
                          Available Balance  : ₹  {wallet}
                        </label>
                      </div>
                  }
                  {/*                     
                    :
                    wallet <= 0 ?  
                    
                    <div className="form-check">
                      <input className="form-check-input me-3 " type="checkbox"  value={wallet}  onChange={selectwallet}  id="flexCheckIndeterminate" disabled/>
                      <label className="mrp" for="flexCheckIndeterminate">
                      iCottageCart Wallet <br />
                      Available Balance  : ₹ {wallet}  
                      </label>
                    </div>
                    : 
                    <div className="form-check">
                      <input className="form-check-input me-3 " type="checkbox"  value={wallet}  onChange={selectwallet}  id="flexCheckIndeterminate" />
                      <label className="mrp" for="flexCheckIndeterminate">
                      iCottageCart Wallet <br />
                      Available Balance  : ₹ 0  
                      </label>
                    </div> */}

                </div>}

                {user?.role !== "retailer" && <hr />}
                {/* <h4 className="options">DELIVERY ESTIMATES</h4>
                        <div className="d-flex justify-content-between mb-5 align-items-center"><div className="delivery-img"><img src={productCart.product_img} alt=""/></div><span className="estimated">Estimated Delivery By 24 Jan 2023</span></div> */}
                <p className="price">
                  PRICE DETAILS ({productCart.length} item)
                </p>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="mrp">Total MRP</p>
                  <span>₹ {parseInt(totalCartPrice)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="mrp">Refferal Discount</p>
                  <span>
                    - ₹{" "}
                    {refferal_data.refferal_type === "Percentage"
                      ? (((totalCartPrice.toFixed(2) * refferal_data.value) /
                      100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : ((totalCartPrice.toFixed(2) * refferal_data.value) / 100))
                      : refferal_data.refferal_type === "Amount"
                        ? totalCartPrice.toFixed(2) -
                        parseFloat(refferal_data.value)
                        : 0}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="mrp">Coupon Saving</p>
                  <span>
                    - ₹{" "}
                    {coupon_data.coupon_type
                      ? coupon_data.coupon_type === "Percentage"
                        ? ((totalCartPrice.toFixed(2) * coupon_data.value) /
                        100) > coupon_data.max_value ?  coupon_data.max_value : (totalCartPrice.toFixed(2) * coupon_data.value) / 100
                        : coupon_data.coupon_type === "Amount"
                          ? parseInt(coupon_data.value)
                          : 0
                      : 0}
                  </span>
                </div>
                {/* <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="mrp">Tax</p>
                  <span>₹ 0</span>
                </div> */}
                <div className="d-flex justify-content-between mb-2 px-2">
                  <p className="mrp">Delivery Charges</p>
                  <span>₹ 40</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between mb-2">
                  <p className="total-2">Total</p>
                  <span>
                    <p className="total-2">
                      ₹{" "}
                      {setTotalCheckoutAmount()}
                      {parseInt(checkoutAmount) + 40}
                    </p>
                  </span>
                </div>
                {/* <Link to={"/payment"}> */}
                {/* <button
                  className="place-order"
                  onClick={() =>
                    displayRazorpay(
                      (refferal_data.refferal_type || coupon_data.coupon_type
                        ? refferal_data.refferal_type === "Percentage" &&
                          coupon_data.coupon_type === "Percentage"
                          ? totalCartPrice.toFixed(2) -
                          (totalCartPrice.toFixed(2) *
                            (parseFloat(refferal_data.value) + parseFloat(coupon_data.value))) /
                          100
                          : refferal_data.refferal_type === "Percentage" &&
                            coupon_data.coupon_type === "Amount"
                            ? totalCartPrice.toFixed(2) -
                            ((totalCartPrice.toFixed(2) * refferal_data.value) /
                              100 +
                              parseFloat(coupon_data.value))
                            : refferal_data.refferal_type === "Amount" &&
                              coupon_data.coupon_type === "Percentage"
                              ? totalCartPrice.toFixed(2) -
                              ((totalCartPrice.toFixed(2) *
                                parseFloat(coupon_data.value)) /
                                100 +
                                parseFloat(refferal_data.value))
                              : refferal_data.refferal_type === "Amount" &&
                                coupon_data.coupon_type === "Amount"
                                ? totalCartPrice.toFixed(2) -
                                (parseFloat(refferal_data.value) +
                                  parseFloat(coupon_data.value))
                                : refferal_data.refferal_type
                                  ? refferal_data.refferal_type === "Percentage"
                                    ? totalCartPrice.toFixed(2) -
                                    (totalCartPrice.toFixed(2) *
                                      refferal_data.value) /
                                    100
                                    : refferal_data.refferal_type === "Amount"
                                      ? totalCartPrice.toFixed(2) - refferal_data.value
                                      : 0
                                  : coupon_data.coupon_type
                                    ? coupon_data.coupon_type === "Percentage"
                                      ? totalCartPrice.toFixed(2) -
                                      (totalCartPrice.toFixed(2) * coupon_data.value) /
                                      100
                                      : coupon_data.coupon_type === "Amount"
                                        ? totalCartPrice.toFixed(2) - coupon_data.value
                                        : totalCartPrice.toFixed(2)
                                    : totalCartPrice.toFixed(2)
                        : totalCartPrice.toFixed(2)) < 0 ? "1" :
                        (refferal_data.refferal_type || coupon_data.coupon_type
                          ? refferal_data.refferal_type === "Percentage" &&
                            coupon_data.coupon_type === "Percentage"
                            ? totalCartPrice.toFixed(2) -
                            (totalCartPrice.toFixed(2) *
                              (parseFloat(refferal_data.value) + parseFloat(coupon_data.value))) /
                            100
                            : refferal_data.refferal_type === "Percentage" &&
                              coupon_data.coupon_type === "Amount"
                              ? totalCartPrice.toFixed(2) -
                              ((totalCartPrice.toFixed(2) * refferal_data.value) /
                                100 +
                                parseFloat(coupon_data.value))
                              : refferal_data.refferal_type === "Amount" &&
                                coupon_data.coupon_type === "Percentage"
                                ? totalCartPrice.toFixed(2) -
                                ((totalCartPrice.toFixed(2) *
                                  parseFloat(coupon_data.value)) /
                                  100 +
                                  parseFloat(refferal_data.value))
                                : refferal_data.refferal_type === "Amount" &&
                                  coupon_data.coupon_type === "Amount"
                                  ? totalCartPrice.toFixed(2) -
                                  (parseFloat(refferal_data.value) +
                                    parseFloat(coupon_data.value))
                                  : refferal_data.refferal_type
                                    ? refferal_data.refferal_type === "Percentage"
                                      ? totalCartPrice.toFixed(2) -
                                      (totalCartPrice.toFixed(2) *
                                        refferal_data.value) /
                                      100
                                      : refferal_data.refferal_type === "Amount"
                                        ? totalCartPrice.toFixed(2) - refferal_data.value
                                        : 0
                                    : coupon_data.coupon_type
                                      ? coupon_data.coupon_type === "Percentage"
                                        ? totalCartPrice.toFixed(2) -
                                        (totalCartPrice.toFixed(2) * coupon_data.value) /
                                        100
                                        : coupon_data.coupon_type === "Amount"
                                          ? totalCartPrice.toFixed(2) - coupon_data.value
                                          : totalCartPrice.toFixed(2)
                                      : totalCartPrice.toFixed(2)
                          : totalCartPrice.toFixed(2)),
                      refferal_data.refferal_type === "Percentage"
                        ? (totalCartPrice.toFixed(2) * refferal_data.value) /
                        100
                        : refferal_data.refferal_type === "Amount"
                          ? totalCartPrice.toFixed(2) -
                          (totalCartPrice.toFixed(2) - refferal_data.value)
                          : 0,
                      coupon_data.coupon_type
                        ? coupon_data.coupon_type === "Percentage"
                          ? (totalCartPrice.toFixed(2) * coupon_data.value) /
                          100
                          : coupon_data.coupon_type === "Amount"
                            ? coupon_data.value

                            : 0
                        : 0
                    )
                  }
                >
                  CheckOut
                </button> */}
                <br /><br />
                {
                  (checkoutAmount + 40) > 0 ?
                    <button className="place-order" id="checkout"
                      onClick={() => handleClick((user_id),
                        parseFloat(parseFloat(refferal_data.refferal_type || coupon_data.coupon_type
                          ? refferal_data.refferal_type === "Percentage" &&
                            coupon_data.coupon_type === "Percentage"
                            ? totalCartPrice.toFixed(2) -
                              ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
                              + 
                              ((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
                            : refferal_data.refferal_type === "Percentage" &&
                              coupon_data.coupon_type === "Amount"
                              ? totalCartPrice.toFixed(2) -
                                (((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )
                                +
                                (parseFloat(coupon_data.value)))
                              : refferal_data.refferal_type === "Amount" &&
                                coupon_data.coupon_type === "Percentage"
                                ? totalCartPrice.toFixed(2) -
                                  (((totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : (totalCartPrice.toFixed(2) * parseFloat(coupon_data.value)/ 100) )
                                  +
                                  (parseFloat(refferal_data.value)))
                                : refferal_data.refferal_type === "Amount" &&
                                  coupon_data.coupon_type === "Amount"
                                  ? totalCartPrice.toFixed(2) -
                                    ((parseFloat(refferal_data.value))
                                    +
                                    (parseFloat(coupon_data.value)))
                                  : refferal_data.refferal_type
                                    ? refferal_data.refferal_type === "Percentage"
                                      ? totalCartPrice.toFixed(2) -
                                      ((totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) > parseFloat(refferal_data.max_value) ? parseFloat(refferal_data.max_value) : (totalCartPrice.toFixed(2) * (parseFloat(refferal_data.value))/100) )

                                      : refferal_data.refferal_type === "Amount"
                                        ? totalCartPrice.toFixed(2) - (parseFloat(refferal_data.value))
                                        : 0
                                    : coupon_data.coupon_type
                                      ? coupon_data.coupon_type === "Percentage"
                                        ? totalCartPrice.toFixed(2) -
                                        ( ((totalCartPrice.toFixed(2) * coupon_data.value) /
                                        100) > parseFloat(coupon_data.max_value) ? parseFloat(coupon_data.max_value) : ((totalCartPrice.toFixed(2) * coupon_data.value) / 100) )
                                        : coupon_data.coupon_type === "Amount"
                                          ? totalCartPrice.toFixed(2) - (parseFloat(coupon_data.value))
                                          : totalCartPrice.toFixed(2)
                                      : totalCartPrice.toFixed(2)
                          : totalCartPrice.toFixed(2)) - usedWalletAmount + 40 ).toFixed(2)
                      )} >
                      CheckOut
                    </button>
                    : ''
                  /*  <>
                  <input type="hidden" name="amount0"  id="" value={0} />

            <button className='place-order' id="amountes" name="" type='submit' onClick={() => handleClick2(user_id)}>Amount 0</button>
            </>  */
                }
                <br /><br />

                {/* CC-Avnue Payment*/}

                {/* {
                      checkoutAmount > 0 ?
                      
                      <form id='nonseamless' method='post' name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction'>

                      <input type='hidden' id='encRequest' name='encRequest' value={hash} />
                      <input type='hidden' name='access_code' id='access_code' value='AVGY84KG30AF71YGFA' />

                      <button className='place-order' id="aweberform" name="aweberform" type='submit' style={{ backgroundColor: '#f9f9f9' }}></button>

                      <script language='javascript'>document.redirect.submit();</script>
                      </form> 
                       
                       : 
                      
                        <>
                        <input type="hidden" name="amount0"  id="" value={0} />

                  <button className='place-order' id="" name="" type='submit' onClick={() => handleClick2(user_id)}>Checkout</button>
                  </>
           
                    }  */}


                {
                  <form id='nonseamless' method='post' name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction'>

                    <input type='hidden' id='encRequest' name='encRequest' value={hash} />
                    <input type='hidden' name='access_code' id='access_code' value='AVGY84KG30AF71YGFA' />

                    <button className='place-order' id="aweberform" name="aweberform" type='submit' style={{ backgroundColor: '#f9f9f9' }}></button>

                    <script language='javascript'>document.redirect.submit();</script>
                  </form>
                }



              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Address;
