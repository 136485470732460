import React, { useState, useEffect } from 'react'
import './SuccessPayment.css';
import { BsFillXCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';


const FailedPayment = (response) => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto mt-5 payment_failed">
                        <div className="payment_success">
                            <div className="payment_header">
                                <div className="check"><BsFillXCircleFill className='icons' /></div>
                            </div>
                            <div className="content">
                                <h6 className='py-4'>Payment Failed!</h6>
                                <button className='mt-4' onClick={() => navigate("/")}>Try Again</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* 
      <form onSubmit={e => onSubmit(e)} method='GET'>
        Name:
        <input type="text" name="name"  value={name} onChange={e => onInputChange(e)}/><br/><br/>
        Email:
        <input type="text" name="email" value={email} onChange={e => onInputChange(e)}/><br/><br/>

        <input type="submit" name="submit" value="Submit" />
   </form> */}
        </div>


    )
}

export default FailedPayment