// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  /* apiKey: "AIzaSyDmTo4Z8rlVlG4kJP65cJkN0jctJwZ2Fjs",
  authDomain: "fir-4d68c.firebaseapp.com",
  projectId: "fir-4d68c",
  storageBucket: "fir-4d68c.appspot.com",
  messagingSenderId: "675352997610",
  appId: "1:675352997610:web:2675ed9384a09d61533193",
  measurementId: "G-R3VJ8HLS2G" */

  apiKey: "AIzaSyA3s37V3LLXwUK4LM9hwNWejHlduU5oAXk",
  authDomain: "cottage-cart-1f421.firebaseapp.com",
  projectId: "cottage-cart-1f421",
  storageBucket: "cottage-cart-1f421.appspot.com",
  messagingSenderId: "320840523861",
  appId: "1:320840523861:web:1cbe03fc1287db46d574dc",
  measurementId: "G-G12ND9YW96"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase