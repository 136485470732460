import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import axios from 'axios';
import globeVar from '../../GlobeApi';
import { EmailShareButton, TwitterShareButton, WhatsappShareButton, FacebookShareButton, PinterestShareButton } from 'react-share';
import { TwitterIcon, WhatsappIcon, EmailIcon, FacebookIcon, PinterestIcon } from 'react-share';
import cross from '../../assets/img/homepage/cross.svg';
import basket from '../../assets/img/homepage/basket.svg';
import like from "../../assets/img/homepage/like.png";
import walletImg from '../../assets/img/Refer&Earn/wallet.png'
import referEarn from '../../assets/img/Refer&Earn/refer-and-earn.avif'
import phoneShare from '../../assets/img/Refer&Earn/phone-share.png'
import copyImg from '../../assets/img/Refer&Earn/copy.png'
import sharePng from '../../assets/img/Refer&Earn/share.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyContext from "../Layout/MyContext";
import Modal from 'react-bootstrap/Modal';
import ProductImage from '../../assets/img/homepage/slider-long-1.png';
import img_not_available from "../../assets/img/Image_not_available.png";
function Dashboard() {
    const [user, setuser] = useState(null);
    const navigate = useNavigate();
    var data = sessionStorage.getItem('user');
    data = JSON.parse(data);
    useEffect(() => {
        if (sessionStorage.getItem('user')) {
            setuser(JSON.parse(sessionStorage.getItem('user')));
        }
    }, [])
    // console.log(data.name);
    const { activeNavItem, setActiveNavItem } = useContext(MyContext);
    setActiveNavItem(null)
    const [order, setorder] = useState([])
    const [subscription_list, setsubscription_list] = useState([])
    const [wishlist, setwishlist] = useState([])
    const [tracking, settracking] = useState([])
    const [cancle, setcancle] = useState([])
    const [curent_user, setcurent_user] = useState({})
    const [referralInfo, setreferralInfo] = useState({})
    const [Homebreadcrum, setHomebreadcrum] = useState("My Profile")
    const [orders, setorders] = useState([])

    const [cityState, setcityState] = useState('');
  const [service, setservice] = useState('');

    const [fullForm, setfullForm] = useState({
        _id: data._id,
        f_name: data.first_name,
        l_name: data.last_name,
        email: data.email,
        contact_nu: data.phone,
        birthday: data.dob,
        gender: data.gender,
        address: data.address
    });

    const [first_nameError, setfirst_nameError] = useState('');
    const [last_nameError, setlast_nameError] = useState('');
    const [emailError, setemailError] = useState('');

    const { _id, f_name, l_name, email, contact_nu, birthday, gender, address } = fullForm;
    // console.log(f_name);
    const handelInput = e => {

        setfullForm({ ...fullForm, [e.target.name]: e.target.value });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setproduct_order([]);
        settrack_product([]);
        setShow(false);
    }

    //    function handleClick(orderid) {
    //     setShow(true);
    //        const item = orderid;
    //         console.log(item);
    //           axios.post(globeVar + "list_order" ,  { id: item }).then((response) => {
    //             console.log(response.data.data.customer);
    //         });
    //    }

    const [product_order, setproduct_order] = useState([]);
    const [track_product, settrack_product] = useState([]);
    function handleClick(orderid) {
        setShow(true);
        const item = orderid;
        // console.log(orderid);
        // alert(orderid);
        axios.post(globeVar + "orderDetails", { orderID: orderid }).then((response) => {
            console.log(response);
            // alert(response.data.product_list[0].product_name);
            setproduct_order(response.data.product_list);
            // console.log(response.data.track);
            settrack_product(response.data.track);

        });
    }


    const handelSubmit = async e => {
        e.preventDefault();
        if (fullForm.f_name === '') {
            setfirst_nameError('Please enter a first name.');
        } else {
            setfirst_nameError('');
        }

        if (fullForm.l_name === '') {
            setlast_nameError('Please enter an last name.');
        } else {
            setlast_nameError('');
        }

        if (fullForm.email === '') {
            setemailError('Please Enter Valid Email Address.');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fullForm.email)) {
            setemailError('Invalid email address');
        }

        // console.log(fullForm);
        if (fullForm.f_name && fullForm.l_name && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fullForm.email)) == false) {
            const item = { _id: fullForm._id, first_name: fullForm.f_name, last_name: fullForm.l_name, email: fullForm.email, gender: fullForm.gender, dob: fullForm.birthday }
            await axios.post(globeVar + "update_user", item).then((response) => {
                if (response.data.type === "success") {
                    // console.log(response.data.data);
                    sessionStorage.setItem('user', JSON.stringify(response.data.data));
                    window.location.href = '/dashboard';
                }
            });
        }
        /* navigate("/dashboard"); */
    }

    const handleLogout = async (e) => {
        e.preventDefault();


        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/'
    };
    const edit_form = () => {
        var form_status = $(form_ele).hasClass('form--disabled') ? 'disabled' : 'enabled';
        if (form_status) {
            if (form_status === 'disabled') {
                enableFormEdit(form_ele);
                $(".js-toggleForm").hide();
                // console.log($(this));
                $('#save').show();
                $('#cancel').show();
            } else if (form_status === 'enabled') {
                disableFormEdit(form_ele);
                $(".js-toggleForm").show();
            }
        }
    }


    const cancel_form = () => {

        var form_status = $(form_ele).hasClass('form--disabled') ? 'enabled' : 'disabled';
        if (form_status) {
            if (form_status === 'enabled') {
                enableFormEdit(form_ele);
                $("#cancel").show();
            } else if (form_status === 'disabled') {
                disableFormEdit(form_ele);
                $(this).show();
                // console.log($(this));
                $('.js-toggleForm').show();
                $('#save').hide();
                $('#cancel').hide();
            }
        }
    };

    /* const purchase_item = (id) => {
        sessionStorage.setItem("product_id", id);
    }; */


    //  Pincode Check 
  function handleClick1() {
    let item = { pincode: $('#pin_code').val() };
    axios.post(globeVar + "check_pincode", item).then((response) => {
      
      if (response.data.data.success === true) {
        $('#add_address').show();
        $('#add').hide();
         $('#check').val('1');
        let service = response.data.data.data.serviceable;
        setservice("Service Available");
       // console.log(service);
        let citystatedata = response.data.data.data.city + ',' + ' ' + response.data.data.data.state;
        setcityState(citystatedata)
       // console.log(citystatedata);
      }
      else {
        $('#add').show();
        $('#add_address').hide();
        $('#check').val('');
        setservice("Service Not Available");
        setcityState('');
       
      }
    });
  }

    const add_card = (id) => {
        const user_id = data._id;
        const product_data = id;
        const product_qty = 1;
        let items = { productId: product_data, quantity: parseInt(product_qty) };
        axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
            if (response.data.type === "success") {
                toast.success('Added to Cart', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    icon: <img src={basket} />,
                    style: { background: '#c9184a' },
                });
                /* window.location.href = "/dashboard"; */

            }
        });
    }
    const removeWishlist = (prod_id) => {
        const item = { userId: data._id, productId: prod_id }
        axios.post(globeVar + "item_remove", item).then((response) => {
            if (response.data.type === "success") {
                setwishlist(response.data.updatedWish.products);
                toast.success('Removed from Wishlist', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    icon: <img src={like} />,
                    style: { background: '#c9184a' },
                });
            }
        });
    };
    const [copySuccess, setCopySuccess] = useState('');
    const homebreadcrum = (name) => {
        setHomebreadcrum(name)
    }
    function handleCopyClick() {
        handleCopy(curent_user.referral_code);
    }

    function handleCopy(textToCopy) {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopySuccess('Copied');
                setTimeout(() => {
                    setCopySuccess('');
                }, 4000);
                // console.log('Text copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying text: ', error);
            });
    }

    //   My Address 
    const [Address, setAddress] = useState({
        user_id: data._id,
        fname: "",
        name: "",
        phone: "",
        pincode: "",
        addresss: "",
        town: "",
        country: "",
        state: ""
    });

    const [nameError, setNameError] = useState('');
    const [phoneError, setphoneError] = useState('');
    const [pincodeError, setpincodeError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [townError, settownError] = useState('');
    const [countryError, setcountryError] = useState('');
    const [stateError, setstateError] = useState('');

    const [EditnameError, setEditNameError] = useState('');
    const [EditfnameError, setEditfNameError] = useState('');
    const [EditphoneError, setEditphoneError] = useState('');
    const [EditpincodeError, setEditpincodeError] = useState('');
    const [EditaddressError, setEditAddressError] = useState('');
    const [EdittownError, setEdittownError] = useState('');
    const [EditcountryError, setEditcountryError] = useState('');
    const [EditstateError, setEditstateError] = useState('');

    const { user_id, fname, name, phone, pincode, addresss, town, country, state } = Address;

    const handelInputs = (e) => {
     
        var error_new = $('.error_new');
        if (!(isValidEmail(e.target.value) || (isValidPhone(e.target.value)))) {
            //console.log('Email is invalid');
            error_new.css("color", "Red");
            error_new.html('Phone are invalid');
        } else {
            //setError(null);
            error_new.css("color", "green");
            error_new.html('Valid');
        }
        // console.log(e.target.value);
        setlogin({ ...Address, [e.target.name]: e.target.value });
        setAddress({ ...Address, [e.target.name]: e.target.value });
    };
    const handelInput1 = (e) => {
      
        setAddress({ ...Address, [e.target.name]: e.target.value });
    };

    const handelSubmitn = async (e) => {
        e.preventDefault();
        // console.log("Address");
        // validate inputs
        if (name === '') {
            setNameError('Please enter a name.');
        } else if (!name.match(/^[a-zA-Z, ]+$/)) {
            setNameError('Please enter a valid name');
        } else {
            setNameError('');
        }

        if (fname === '') {
            setNameError('Please enter a name.');
        } else if (!name.match(/^[a-zA-Z, ]+$/)) {
            setNameError('Please enter a character');
        } else {
            setNameError('');
        }

        if (addresss === '') {
            setAddressError('Please enter an address.');
        } else {
            setAddressError('');
        }

        if (phone === '') {
            setphoneError('Please enter a phone.');
        } else if (phone.length != 10) {
            setphoneError('Please enter a valid number');
        } else {
            setphoneError('');
        }

       if (town === '') {
            settownError('Please enter a town.');
        } else {
            settownError('');
        } 

        if (pincode === '') {
            setpincodeError('Please enter a pincode code.');
        } else if (pincode.length != 6) {
            setpincodeError('Enter pincode must be 6 character.');
        } else {
            setpincodeError('');
        }

        if (country === '') {
            setcountryError('Please enter a your country.');
        } else {
            setcountryError('');
        }

        if (state === '') {
            setstateError('Please enter a your state.');
        } else {
            setstateError('');
        }

        if ((name.match(/^[a-zA-Z, ]+$/)) && fname && addresss && (phone.length != 10) == false && town && country && state && (pincode.length != 6) == false) {

            const item = {
                userID: Address.user_id,
                firstName: Address.fname,
                lastName: Address.name,
                mobno: Address.phone,
                pincode: Address.pincode,
                address1: Address.addresss,
                city: Address.town,
                country: Address.country,
                state: Address.state
            };
            // console.log(item);
            await axios.post(globeVar + "create_add", item).then((response) => {
                if (response.data.type === "success") {
                    // console.log(response.data.data);
                    window.location.href = "/dashboard";
                }
            });

        }
        /* navigate("/dashboard"); */
    };

    const [addList, setaddList] = useState([]);
    const Addresslist = () => {
        const item = { userID: data._id };

        axios.post(globeVar + "address_listing", item).then((response) => {
            if (response.data.type === "success") {
                setaddList(response.data.data);
            }
        });
    };

    const deleteAddress = (id, user_id) => {
        const item = { _id: user_id };
        axios.post(globeVar + "delete_address/" + id, item).then((response) => {
            // console.log(response);
            if (response.data.type === "success") {
                setaddList(response.data.data);
            }
        });
    };
    const [edit_data, setedit_data] = useState([]);
    const [tempAddress, settempAddress] = useState([]);
    const editAddress = async (id) => {
        $('.address_form_error').html("")
        await axios.post(globeVar + `edit_address/${id}`).then((response) => {
            if (response.data.type === "success") {
                setedit_data(response.data.data);
                settempAddress(response.data.data);

                if (response.data.data.default_status === "on") {
                    $("#edt_checkdefault").prop("checked", true);
                }
            }
        });
    };

    const handelSubmitAddress = async (e) => {
        e.preventDefault();
        // console.log("edit_data", edit_data);
        // console.log("tempAddress", tempAddress);
        // validate inputs
        if (tempAddress.firstName === '') {
            setEditNameError('Please enter a name.');
        } else if (!tempAddress.firstName.match(/^[a-zA-Z, ]+$/)) {
            setEditNameError('Please enter a character.');
        }
        else {
            setEditNameError('');
        }


        if (tempAddress.lastName === '') {
            setEditfNameError('Please enter a name.');
        } else if (!tempAddress.lastName.match(/^[a-zA-Z, ]+$/)) {
            setEditfNameError('Please enter a character.');
        }
        else {
            setEditfNameError('');
        }

        if (tempAddress.mobno === '') {
            setEditphoneError('Please enter a mobile number.');
        } else if (tempAddress.mobno.length != 10) {

            setEditphoneError('Please enter valid  Mobile Number')
        } else {
            setEditphoneError('');
        }

        if (tempAddress.city === '') {
           setEdittownError('Please enter a town.');
       } else {
           setEdittownError('');
       }

        if (tempAddress.pincode === '') {
            setEditpincodeError('Please enter a pincode code.');
        } else if (tempAddress.pincode.length != 6) {
            setEditpincodeError('Enter pincode must be 6 character.');
        } else {
            setEditpincodeError('');
        }

        if (tempAddress.country === '') {
            setEditcountryError('Please enter a your Country.');
        } else {
            setEditcountryError('');
        }

        if (tempAddress.state === '') {
            setEditstateError('Please enter a your state.');
        } else {
            setEditstateError('');
        }

        if ((!tempAddress.firstName.match(/^[a-zA-Z, ]+$/)) == false && (!tempAddress.lastName.match(/^[a-zA-Z, ]+$/)) == false && (tempAddress.mobno.length != 10) == false && (tempAddress.pincode.length != 6) == false && tempAddress.address1 && tempAddress.city) {
            const item = {
                _id: tempAddress._id,
                userID: tempAddress.userID,
                firstName: tempAddress.firstName,
                lastName: tempAddress.lastName,
                mobno: tempAddress.mobno,
                pincode: tempAddress.pincode,
                address1: tempAddress.address1,
                city: tempAddress.city,
                country: tempAddress.country,
                state: tempAddress.state
            };

            // console.log(item);
            await axios.post(globeVar + "update_address", item).then((response) => {
                if (response.data.type === "success") {
                    setaddList(response.data.data);
                    window.$("#exampleModal_edit").modal("hide");
                    /* window.location.href = '/address'; */
                }
            });
        }
        /* navigate("/dashboard"); */
    };

    const [Checked, setChecked] = useState("");
    const isChecked = (values) => values === Checked;
    const selectAddress = ({ target: { value } }) => {
        // console.log(value);
        setChecked(value);
    };


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function isValidPhone(phone) {
        return /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phone);
    }

    const [login, setlogin] = useState({
        phone: "",
    });
    // function handleClick(orderid) {
    //     console.log('hi');
    //     const item = orderid;
    //     console.log(item);
    //     axios.post(globeVar + "cancel_orders", { id: item }).then((response) => {
    //         if (response.data.type === "success") {
    //             console.log(response.data.data);
    //             setcancle(response.data.data);
    //             navigate("/dashboard");
    //         }
    //     });
    // } 

    useEffect(() => {
        window.scrollTo(0, 0);
        const user_id = data._id;
        const item = { userId: user_id }

        axios.post(globeVar + "list_order", item).then((response) => {
            // console.log(response);
            if (response.data.type === "success") {
                setorder(response.data.data);
            }
        });

        axios.post(globeVar + "get_Wishlist", item).then((response) => {
            if (response.data.type === "success") {
                setwishlist(response.data.wishlist.products);
            }
        });

        axios.post(globeVar + "user_tracking", item).then((response) => {
            if (response.data.type === "success") {
                settracking(response.data.data);
            }
        });

        axios.post(globeVar + "current_user", item).then((response) => {
            if (response.data.type === "success") {
                setcurent_user(response.data.data);
            }
        });

        axios.post(globeVar + "referral_list").then((response) => {
            if (response.data.type === "success") {
                setreferralInfo(response.data.data);
            }
        });

        axios.post(globeVar + "subscription_list", item).then((response) => {
            if (response.data.type === "success") {
                setsubscription_list(response.data.data);
            }
        });
        Addresslist();
    }, [])
    return (
        <div>
            <ToastContainer style={{ width: "fit-content", marginTop: "3%" }} />
            <section className="Privacy-policy dashboard" style={{/* paddingTop: "117px" */paddingBottom: "50px" }}>
                <div className="container">
                    <div className="row position-relative">
                        <div className="products ps-3">
                            <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb" className="products-first">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page" id="breadcrum">{Homebreadcrum}</li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3 col-md-4 Privacy-topic" style={{ top: "100px" }}>
                            <ul className="nav nav-tabs flex-column" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Profile")} aria-controls="home" aria-selected="true">My Profile</button>
                                    <hr className='d-none d-md-block' />
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#Address"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Address")} aria-controls="my-address" aria-selected="true">My Address</button>
                                    <hr className='d-none d-md-block' />
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Order")} aria-controls="profile" aria-selected="false">My Order</button>
                                    <hr className='d-none d-md-block' />
                                </li>
                                {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                                type="button" role="tab" aria-controls="contact" aria-selected="false">Top Ordered
                                Products</button>
                            <hr />
                        </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="wishlist-tab" data-bs-toggle="tab" data-bs-target="#wishlist"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Wishlist")} aria-controls="wishlist" aria-selected="false">My
                                        Wishlist</button>
                                    <hr className='d-none d-md-block' />
                                </li>
                                {user?.role !== "retailer" && <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="subscriptions-tab" data-bs-toggle="tab" data-bs-target="#subscriptions"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Subscriptions")} aria-controls="subscriptions" aria-selected="false">My
                                        Subscriptions</button>
                                    <hr className='d-none d-md-block' />
                                </li>}
                                {user?.role !== "retailer" && <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="refer-earn-tab" data-bs-toggle="tab" data-bs-target="#referearn"
                                        type="button" role="tab" onClick={() => homebreadcrum("My Refer & Earn")} aria-controls="referearn" aria-selected="false">My Refer & Earn</button>
                                    <hr className='d-none d-md-block' />
                                </li>}
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                                        type="button" role="tab" onClick={handleLogout} aria-controls="contact" aria-selected="false">Logout</button>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-lg-9 col-md-8 Privacy-condition tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="profile my-order rounded">
                                    <div className="my-order-header d-flex justify-content-between align-items-center py-4 px-4 rounded-top">
                                        <p className="m-0">Your Details</p>
                                    </div>
                                    <div className="px-4 py-2" style={{ cursor: "pointer" }} onClick={() => edit_form()}>
                                        <p className="buttonss js-toggleForm"><FaEdit size={25} className='edit_icon' /></p>
                                    </div>
                                    <form action="" className="mt-4 form" id="Demo" onSubmit={e => handelSubmit(e)}>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="f_name" className="col-lg-3 col-6 form-label mb-0">First Name :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="text" className="form-control" name="f_name" onChange={e => handelInput(e)} id="f_name" disabled
                                                    placeholder="Enter Your First Name" value={f_name} />
                                                <span className="address_form_error">{first_nameError}</span>
                                                <input type="hidden" name="_id" value={_id} />
                                            </div>
                                        </div>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="l_name" className="col-lg-3 col-6 form-label mb-0">Last Name :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="text" className="form-control" name="l_name" onChange={e => handelInput(e)} id="l_name" disabled
                                                    placeholder="Enter Your Last Name" value={l_name} />
                                                <span className="address_form_error">{last_nameError}</span>
                                            </div>
                                        </div>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="email" className="col-lg-3 col-6 form-label mb-0">Email :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="email" className="form-control" name="email" onChange={e => handelInput(e)} id="email" disabled
                                                    placeholder="Enter Your Email" value={email} />
                                                <span className="address_form_error">{emailError}</span>
                                            </div>
                                        </div>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="contact_nu" className="col-lg-3 col-6 form-label mb-0">Contact Number
                                                :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="text" className="form-control" name="contact_nu" onChange={e => handelInput(e)} id="contact_nu" disabled readOnly
                                                    placeholder="Enter Your Contact Number" value={contact_nu} />
                                            </div>
                                        </div>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="birthday" className="col-lg-3 col-6 form-label mb-0">Birthday :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="date" className="form-control" name='birthday' onChange={e => handelInput(e)} id='birthday' value={birthday} disabled />
                                            </div>
                                        </div>
                                        <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="gender" className="col-lg-3 col-6 form-label mb-0">Gender :</label>
                                            <div className="col-lg-5 col-6">
                                                <select className="form-select" name='gender' onChange={e => handelInput(e)} value={gender} id='gender' aria-label="Default select example" disabled>
                                                    <option defaultValue>Select Gender</option>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                    <option value="3">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="row px-4 py-2 align-items-center">
                                            <label htmlFor="birthday" className="col-lg-3 col-6 form-label mb-0">Address :</label>
                                            <div className="col-lg-5 col-6">
                                                <input type="text" className="form-control" name='address' onChange={e => handelInput(e)} id='address' value={address} disabled 
                                                    placeholder='Enter Address'
                                                />

                                            </div>
                                        </div> */}

                                        <div className="px-4 py-2">
                                            <button className="buttons" type='submit' id="save">Save</button>
                                            <button type='button' className="buttons" id="cancel" onClick={() => cancel_form()}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="Address" role="tabpanel" aria-labelledby="my-address">
                                <div className="profile my-order rounded">
                                    <div className="my-order-header d-flex justify-content-between align-items-center py-4 px-4 rounded-top">
                                        <p className="m-0">Your Address</p>
                                    </div>
                                    <div className='my-address'>
                                        <div className="address-heading">
                                            <h3>Manage Address</h3>
                                        </div>
                                        <div className="row address-main">
                                            <div className="col-12">
                                                {/* <h4 className="default">DEFAULT ADDRESS</h4> */}
                                                {addList.map((data, i) => {
                                                    return (
                                                        <div className="row address-first" key={data._id}>
                                                            <div className="col-12 pt-3 ps-3">
                                                                <p className="input-button">
                                                                    {" "}
                                                                    <span className="name ms-2">{data.firstName} {data.lastName}</span>{" "}
                                                                    {/* <span className="home ms-2">Home</span> */}
                                                                </p>
                                                                <p className="prestige">
                                                                    {data.address1}, <br /> {data.city} - {data.pincode}
                                                                </p>
                                                                <p className="prestige">
                                                                    {data.country} - {data.state}
                                                                </p>
                                                                <p className="prestige">Mobile - {data.mobno}</p>
                                                                <p className="ms-4">
                                                                    <span className="romove" style={{ cursor: "pointer" }}>
                                                                        <a
                                                                            onClick={() => deleteAddress(data._id, data.userID)}
                                                                        >
                                                                            REMOVE
                                                                        </a>{" "}
                                                                    </span>
                                                                    <span
                                                                        className="edit ms-3"
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <a
                                                                            onClick={() => editAddress(data._id)}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#exampleModal_edit"
                                                                        >
                                                                            EDIT
                                                                        </a>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                                <div
                                                    className="row add-address my-4"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                >
                                                    <p href="#" className="m-0 p-3">
                                                        + Add new address
                                                    </p>
                                                </div>
                                                <div
                                                    className="modal"
                                                    id="exampleModal"
                                                    tabIndex="-1"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div className="d-flex justify-content-center pt-3 modal-dialog">
                                                        <div className="card col-lg-12 col-sm-8 py-4 bg-white shadow border-0 add-new-address-popup modal-content">
                                                            <div className="d-flex justify-content-between px-4 header">
                                                                <h6>Add New Address</h6>
                                                                <div>
                                                                    <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(true)}></span>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <form className="px-4" onSubmit={(e) => handelSubmitn(e)}>
                                                                <label htmlFor="name" className="mb-3">
                                                                    Contact Details
                                                                </label>
                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="fname"
                                                                        id="fname"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={fname}
                                                                        placeholder="First Name*"
                                                                    />
                                                                    <span className="address_form_error">{nameError}</span>
                                                                    <input type="hidden" name="user_id" value={user_id} />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="name"
                                                                        id="name"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={name}
                                                                        placeholder="Last Name*"
                                                                    />
                                                                    <span className="address_form_error">{nameError}</span>
                                                                    <input type="hidden" name="user_id" value={user_id} />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="phone"
                                                                        min={0}
                                                                        maxLength={10}
                                                                        onChange={(e) => handelInputs(e)}
                                                                        value={phone}
                                                                        placeholder="Mobile No*"
                                                                    />
                                                                    <span className="address_form_error">{phoneError}</span>
                                                                    <p className="error_new"></p>
                                                                </div>

                                                                <label htmlFor="pin_code" className="mb-3">
                                                                    Address
                                                                </label>
                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="addresss"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={addresss}
                                                                        placeholder="Address (House No, Building, Street, Area)*"
                                                                    />
                                                                    <span className="address_form_error">{addressError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="country"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={country}
                                                                        placeholder="Country*"
                                                                    />
                                                                    <span className="address_form_error">{countryError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="state"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={state}
                                                                        placeholder="State*"
                                                                    />
                                                                    <span className="address_form_error">{stateError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="town"
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={town}
                                                                        placeholder="City*"
                                                                    />
                                                                    <span className="address_form_error">{townError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="pincode"
                                                                        id="pin_code"
                                                                        min={0}
                                                                        onChange={(e) => handelInput1(e)}
                                                                        value={pincode}
                                                                        placeholder="Pin Code*"
                                                                    />
                                                                    <span className="address_form_error">{pincodeError}</span>
                                                                </div>



                                                                {/* <div className="form-check mb-3 d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input me-2"
                                                                        name="defaultAddress"
                                                                        id="checkdefault"
                                                                    />
                                                                    <span
                                                                        className="form-check-label mb-0"
                                                                        htmlFor="checkdefault"
                                                                    >
                                                                        Save this my default address
                                                                    </span>
                                                                    </div> */}
                                                                    <p className="mb-2" style={{ 'color': 'black' }}>{service}</p>
                                                                    <p className="mb-2" style={{ 'color': 'black' }}>{cityState}</p>
                                                                    <button type="button" className="btn btn-primary mx-0 mb-1" onClick={() => handleClick1()}>Check</button>
                                                                    {/* <button onClick={() => handleClick1()}>Check</button> <br /><br /> */}
                                                                    <button type="submit" className="btn btn-primary mx-0 w-100" id="add_address">
                                                                        ADD ADDRESS  
                                                                    </button>
                                                                    <button type="button" className="btn btn-primary mx-0 w-100" id="add" style={{display:"none"}} >
                                                                        ADD ADDRESS   
                                                                    </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="modal"
                                                    id="exampleModal_edit"
                                                    tabIndex="-1"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div className="d-flex justify-content-center pt-3 modal-dialog">
                                                        <div className="card col-lg-12 col-sm-8 py-4 bg-white shadow border-0 add-new-address-popup modal-content">
                                                            <div className="d-flex justify-content-between px-4 header">
                                                                <h6>Edit Address</h6>
                                                                <div>
                                                                    <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close"></span>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <form className="px-4" onSubmit={(e) => handelSubmitAddress(e)}>
                                                                <label htmlFor="name" className="mb-3">
                                                                    Contact Details
                                                                </label>
                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="names"
                                                                        id="names"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                firstName: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.firstName}
                                                                        placeholder="First Name*"
                                                                    />
                                                                    <span className="address_form_error">{EditnameError}</span>
                                                                    <input type="hidden" name="_id" value={tempAddress._id} />
                                                                </div>



                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="names"
                                                                        id="names"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                lastName: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.lastName}
                                                                        placeholder=" Last Name*"
                                                                    />
                                                                    <span className="address_form_error">{EditfnameError}</span>
                                                                    <input type="hidden" name="_id" value={tempAddress._id} />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="phones"
                                                                        min={0}
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                mobno: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.mobno}
                                                                        placeholder="Mobile No*"
                                                                    />
                                                                    <span className="address_form_error">{EditphoneError}</span>
                                                                </div>

                                                                <label htmlFor="pin_code" className="mb-3">
                                                                    Address
                                                                </label>
                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="addresss"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                address1: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.address1}
                                                                        placeholder="Address (House No, Building, Street, Area)*"
                                                                    />
                                                                    <span className="address_form_error">{EditaddressError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="country"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                country: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.country}
                                                                        placeholder="Country*"
                                                                    />
                                                                    <span className="address_form_error">{EditcountryError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="state"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                state: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.state}
                                                                        placeholder="State*"
                                                                    />
                                                                    <span className="address_form_error">{EditstateError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="hidden"
                                                                        className="form-control"
                                                                        name="towns"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                city: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.city}
                                                                        placeholder="Locality / City*"
                                                                    />
                                                                     <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="towns"
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                city: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.city}
                                                                        placeholder="Locality / City*"
                                                                    />
                                                                    <span className="address_form_error">{EdittownError}</span>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="pincodes"
                                                                        id="pin_codes"
                                                                        min={0}
                                                                        onChange={(e) =>
                                                                            settempAddress({
                                                                                ...tempAddress,
                                                                                pincode: e.target.value,
                                                                            })
                                                                        }
                                                                        value={tempAddress.pincode}
                                                                        placeholder="Pin Code*"
                                                                    />
                                                                    <span className="address_form_error">{EditpincodeError}</span>
                                                                </div>


                                                                {/* <div className="form-check mb-3 d-flex">
                                                                    <input
                                                                    type="checkbox"
                                                                    className="form-check-input me-2"
                                                                    name="edt_checkdefault"
                                                                    id="edt_checkdefault"
                                                                    />
                                                                    <span
                                                                    className="form-check-label mb-0"
                                                                    htmlFor="checkdefault"
                                                                    >
                                                                    Save this my default address
                                                                    </span>
                                                                </div> */}
                                                                <button type="submit" className="btn btn-primary w-100">
                                                                    Update
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="my-order rounded">
                                    <div
                                        className="my-order-header d-flex justify-content-between align-items-center py-3 px-4 rounded-top">
                                        <div>
                                            <p className="m-0">Orders</p>
                                            {/*  <p className="m-0">October 18 2022</p> */}
                                        </div>
                                        {/* <div>
                                    <p className="m-0">Grand Total</p>
                                    <p className="m-0">₹ 2100</p>
                                </div> */}
                                    </div>
                                    {/* <div className="d-flex align-items-center justify-content-between status py-3 px-4">
                                            <p className="m-0">Fullfullment Status : <span>Fullfulled</span></p>
                                            <p className="m-0">Payment : <span>Paid</span></p>
                                        </div> */}
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Order Date</th>
                                                    <th>Amount</th>
                                                    {/* <th>Bank Ref No</th>
                                                    <th>Payment Mode</th> */ }
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                    {/* <th>PaymentId</th>
                                                    <th>Name</th> */}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.map((value, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{value.orderid}</td>
                                                            <td>{value.order_date}</td>
                                                            <td>{value.amount}</td>
                                                            {/*  <td>{value.bank_ref_no}</td>
                                                            <td>{value.payment_mode}</td> */ }
                                                            <td>{value.order_status}</td>
                                                            {/* <td>{value.paymentId}</td>
                                                            <td>{value.customer}</td> */}
                                                            <td>
                                                                <button className='primary-btn' onClick={() => handleClick(value.orderid)}>
                                                                    View Details
                                                                </button>
                                                                {/* <button className='secondary-btn  mt-3' onClick={() => handleClick(value.orderid)}>Cancle</button> */}


                                                                {/* <Modal show={show} onHide={handleClose}>
                                                                    <div className="profile bg-white order-detail rounded">
                                                                        <div className='row'>
                                                                            <div className='col-12 order-detail-card order-detail-card-product p-0'>
                                                                                <div className='card mb-3'>
                                                                                    <div className='row'>
                                                                                        <div className='col-4 col-sm-2'>
                                                                                            <div className='card-img col'>
                                                                                                <img src={'https://cottagecart.s3.ap-south-1.amazonaws.com/uploads/32e16853-082b-4a47-8c95-0d57db65b578-5%20Benefits%20Of%20Castor%20Oil%20For%20The%20Face%2C%20How%20To%20Use%20It%2C%20%26%20Risk.jpg'} className='img-fluid' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-8 col-sm-7'>
                                                                                            <div className='card-body py-0'>
                                                                                                <div className='mb-2 mb-sm-4'>
                                                                                                    <h5>Groundnut Oil</h5>
                                                                                                    <p className='text-dark'> 250 Gms</p>
                                                                                                </div>
                                                                                                <h5>₹ 38</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='card mb-3'>
                                                                                    <div className='row'>
                                                                                        <div className='col-4 col-sm-2'>
                                                                                            <div className='card-img col'>
                                                                                            <img src={'https://cottagecart.s3.ap-south-1.amazonaws.com/uploads/32e16853-082b-4a47-8c95-0d57db65b578-5%20Benefits%20Of%20Castor%20Oil%20For%20The%20Face%2C%20How%20To%20Use%20It%2C%20%26%20Risk.jpg'} className='img-fluid' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-8 col-sm-7'>
                                                                                            <div className='card-body py-0'>
                                                                                                <div className='mb-2 mb-sm-4'>
                                                                                                <h5>Groundnut Oil</h5>
                                                                                                    <p className='text-dark'> 250 Gms</p>
                                                                                                </div>
                                                                                                <h5>₹ 38</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className='col-12 order-detail-card p-0'>

                                                                                <div className='line active'>
                                                                                    <div className='circle'></div>
                                                                                    <div className='d-flex'>
                                                                                        <h5>Order Conformed</h5>
                                                                                        <span>Mon, 15 Apr 2023</span>
                                                                                    </div>
                                                                                    <p>Your order has been placed.</p>
                                                                                    <span>Mon, 15 Apr 2023 - 1:53pm</span>
                                                                                    <p>Seller processed your ordered.</p>
                                                                                    <span>Mon, 15 Apr 2023</span>
                                                                                    <p>Your item has been picked up by courier partner.</p>
                                                                                    <span>Tue, 16 Apr 2023 - 10:00am</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 order-detail-card p-0 '>
                                                                                <div className='line inactive'>
                                                                                    <div className='circle'></div>
                                                                                    <div className='d-flex'>
                                                                                        <h5>Shipped</h5>
                                                                                        <span>Tue, 16 Apr 2023</span>
                                                                                    </div>
                                                                                    <p>Ekart Logistics</p>
                                                                                    <p>Your item has been shipped</p>
                                                                                    <span>Mon, 16 Apr 2023 - 1:53pm</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 order-detail-card p-0 '>
                                                                                <div className='line inactive'>
                                                                                    <div className='circle'></div>
                                                                                    <div className='d-flex'>
                                                                                        <h5>Out For Delivery</h5>
                                                                                        <span>Fri, 19 Apr 2023</span>
                                                                                    </div>
                                                                                    <p>Your item is out for delivery.</p>
                                                                                    <span>Fri, 19 Apr 2023 - 11:00am</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 order-detail-card  p-0 '>
                                                                                <div className='line inactive'>
                                                                                    <div className='circle'></div>
                                                                                    <div className='d-flex'>
                                                                                        <h5>Order Delivered</h5>
                                                                                        <span>Fri, 19 Apr 2023</span>
                                                                                    </div>
                                                                                    <p>Your item has been delivered.</p>
                                                                                    <span>Fri, 19 Apr 2023 - 11:00am</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal> */}


                                                                <Modal className='model-order-detail' show={show} onHide={handleClose}>
                                                                    <div className="profile bg-white order-detail rounded">
                                                                        <div className='row'>
                                                                            <div className='col-lg-6' style={{ maxHeight: "316px",overflowY: "auto"}}>
                                                                                {product_order && product_order.map((prod) => {
                                                                                    return (
                                                                                        <div className='col-12 order-detail-card order-detail-card-product p-0'>
                                                                                            <div className='card mb-3 shadow p-3 pb-lg-0'>
                                                                                                <div className='row'>
                                                                                                    <div className='col-4 col-sm-2 col-lg-4'>
                                                                                                        <div className='card-img col'>
                                                                                                            <img src={prod.product_img} className='img-fluid' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='col-8 col-sm-7'>
                                                                                                        <div className='card-body py-0'>
                                                                                                            <div className='mb-2 mb-sm-4'>
                                                                                                                <h5>{prod.product_name}</h5>
                                                                                                                <p className='text-dark'> Weight : {prod.weight}</p>
                                                                                                                <p className='text-dark'> Quantity : {prod.quantity}</p>
                                                                                                            </div>
                                                                                                            <h5>₹ {prod.price}</h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                            <div className='col-lg-6'>

                                                                                {track_product.length > 0 && track_product.map((valued, key) => {
                                                                                    return (

                                                                                        <div className='col-12 order-detail-card p-0'>
                                                                                            <div className=
                                                                                                {valued.city == undefined ?
                                                                                                    'line inactive'
                                                                                                    :
                                                                                                    'line active'
                                                                                                } >
                                                                                                <div className='circle'></div>
                                                                                                <div className='d-flex'>
                                                                                                    <h5>Order {valued.status}</h5>
                                                                                                </div>
                                                                                                {valued.city == undefined ?
                                                                                                    <p></p>
                                                                                                    :
                                                                                                    <p>City: {valued.city}</p>
                                                                                                }
                                                                                                <span>{valued.time}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}





                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="wishlist" role="tabpanel" aria-labelledby="wishlist-tab">
                                <div className="my-wishlist my-order rounded">
                                    <div className="my-order-header d-flex justify-content-between align-items-center py-3 px-4 rounded-top">
                                        <p className="m-0">My Wishlist</p>
                                    </div>
                                    <div className="row g-0 popular-section product-popular adjust-wishlist">
                                        {wishlist.length > 0 ? 
                                       ''   
                                        : <p className='text-center'><img src='./assets/img/homepage/nowishlist.png' className="card-img-top w-50" alt="No product In your Wishlist"/></p>  }            
                                        {wishlist.map(data => {
                                            return <div className="popular-slider-row col-lg-4 col-md-6 col-sm-6 col-6" key={data._id}>
                                                <div className="card wishlist-card-adjust">
                                                    {user?.role !== "retailer" && data.discount ? <span className="price-tag">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            width="69.521" height="74.698" viewBox="0 0 69.521 74.698">
                                                            <defs>
                                                                <filter id="_281639d9032fd35ab631d5064a2c1da9" x="0" y="5.177"
                                                                    width="69.521" height="69.521" filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="3" input="SourceAlpha" />
                                                                    <feGaussianBlur stdDeviation="2" result="blur" />
                                                                    <feFlood floodColor="#7a6b6b" floodOpacity="0.361" />
                                                                    <feComposite operator="in" in2="blur" />
                                                                    <feComposite in="SourceGraphic" />
                                                                </filter>
                                                            </defs>
                                                            <g id="Component_59_1" data-name="Component 59 – 1"
                                                                transform="translate(6.865 0.507)">
                                                                <g id="Group_272" data-name="Group 272"
                                                                    transform="translate(-357.104 -1330.704)">
                                                                    <g id="Group_269" data-name="Group 269" transform="translate(278 -41)">
                                                                        <g id="Group_268" data-name="Group 268">
                                                                            <g id="Group_267" data-name="Group 267" className="discount">
                                                                                <g transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                                                                    filter="url(#_281639d9032fd35ab631d5064a2c1da9)">
                                                                                    <path id="_281639d9032fd35ab631d5064a2c1da9-2"
                                                                                        data-name="281639d9032fd35ab631d5064a2c1da9"
                                                                                        d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                                                                        transform="translate(5.45 35.39) rotate(-45)"
                                                                                        fill="#fff1f5" stroke="#ee7e9e" strokeWidth="1"
                                                                                        className="rotate-tag" />
                                                                                </g>
                                                                                <text id="_15_" className="_15_" data-name="15%"
                                                                                    transform="translate(98 1413)" fontSize="12"
                                                                                    fontFamily="Comfortaa-Bold, Comfortaa"
                                                                                    fontWeight="700">
                                                                                    <tspan x="0" y="0">15%</tspan>
                                                                                </text>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Group_270" data-name="Group 270" className="hook"
                                                                        transform="translate(277.674 -41)">
                                                                        <path id="Path_156" data-name="Path 156"
                                                                            d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                                                            transform="translate(1997.472 4421.696)" fill="none"
                                                                            stroke="#bbb" strokeWidth="1" />
                                                                        <path id="Path_155" data-name="Path 155"
                                                                            d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                                                            transform="translate(1980.552 4427.111)" fill="none"
                                                                            stroke="#bbb" strokeWidth="1" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span> : ""}
                                                    <Link
                                                        to={`/product-cart/${data.productId}`}
                                                    >
                                                        {data.prod_gal == "Video" ? (
                                                            <img
                                                                src={
                                                                data.video_thambnail ? data.video_thambnail : img_not_available
                                                                }
                                                                className="card-img-top"
                                                                alt="..."
                                                                onError={(e) => {
                                                                // console.log("2", e);
                                                                e.target.onerror = null; // prevents looping
                                                                e.target.src =
                                                                    "../../assets/img/homepage/Image_not_available.png";
                                                                }}
                                                            />
                                                            ) : (
                                                            <img
                                                                src={data.product_img ? data.product_img : img_not_available}
                                                                className="card-img-top"
                                                                alt="..."
                                                                onError={(e) => {
                                                                // console.log("2", e);
                                                                e.target.onerror = null; // prevents looping
                                                                e.target.src =
                                                                    "../../assets/img/homepage/Image_not_available.png";
                                                                }}
                                                            />
                                                            )}
                                                        {/* <img src={data.product_img} className="card-img-top" alt="..." /> */}
                                                    </Link>
                                                    <button onClick={() => removeWishlist(data.productId)} className="border-0 bg-transparent cross-btn"><img src={cross} alt="" /></button>
                                                    <div className="card-body">
                                                        <h4>{data.product_name}</h4>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span>{user && user?.role === "retailer" ? "₹" + data?.retailerPrice + " ( CP : "  :""} ₹{data.saving ? data.price - data.saving : data.price} {user?.role !== "retailer" && data.discount ? <del className="ms-1">₹{data.price}</del> : ""} {user && user?.role === "retailer" ? " )" :" "}</span>

                                                            <button onClick={() => add_card(data.productId)} className="d-flex align-items-center justify-content-center">
                                                                <img src={basket} alt="" />
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="subscriptions" role="tabpanel" aria-labelledby="subscriptions-tab">
                                <div className="my-order rounded">
                                    <div
                                        className="my-order-header d-flex justify-content-between align-items-center py-3 px-4 rounded-top">
                                        <div>
                                            <p className="m-0">Subscriptions List</p>
                                            {/*  <p className="m-0">October 18 2022</p> */}
                                        </div>
                                        {/* <div>
                                    <p className="m-0">Grand Total</p>
                                    <p className="m-0">₹ 2100</p>
                                </div> */}
                                    </div>
                                    {/*   <div className="d-flex align-items-center justify-content-between status py-3 px-4">
                                <p className="m-0">Fullfullment Status : <span>Fullfulled</span></p>
                                <p className="m-0">Payment : <span>Paid</span></p>
                            </div> */}
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Subscription Plan</th>
                                                    <th>Quantity</th>
                                                    <th>Amount</th>
                                                    {/* <th>Delivered Status</th> */}
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subscription_list.map((value, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{value.product_name}</td>
                                                            <td>{value.sub_plan}</td>
                                                            <td>{value.quantity}</td>
                                                            <td>{value.sub_price}</td>
                                                            {/* <td>{value.delivered_status}</td> */}
                                                            <td>{value.order_date}</td>
                                                            <td>{value.end_date}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="referearn" role="tabpanel" aria-labelledby="refer-earn-tab">
                                <div className="refer-and-earn my-order rounded">
                                    <div className="row align-items-start m-0">
                                        <div className="col-md-6">
                                            <div className="wallet-cntnr d-flex justify-content-between align-items-center">
                                                <div className="wallet-left d-flex align-items-center">
                                                    <div className="wallet-img">
                                                        <img src={walletImg} alt="wallet" />
                                                    </div>
                                                    <span className='wallet-text'>WALLET</span>
                                                </div>
                                                <div className="wallet-right">
                                                    <span>₹ {curent_user.wallet}</span>
                                                </div>
                                            </div>
                                            <div className="wallet-cntnr-img">
                                                <img src={referEarn} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="cash-rewards-cntnr">
                                                <div className="cash-rewards-text d-flex align-items-center flex-column">
                                                    <span>REFER MORE FRIENDS </span>
                                                    <h3 className='text-center'>{referralInfo.title}</h3>
                                                    <span>ON EVERY REFERRAL</span>
                                                </div>
                                                <div className="d-flex align-items-end justify-content-end">
                                                    <span className='termCondition' type="button" data-bs-toggle="modal" data-bs-target="#termConditionModal">T&C</span>
                                                    <div className="modal fade" id="termConditionModal" tabindex="-1" aria-labelledby="termConditionModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                            <div className="modal-content">
                                                                <div className="modal-header tc-modal-header">
                                                                    <h2 className="modal-title fs-5 tc-modal-title" id="termConditionModalLabel">TERMS AND CONDITIONS</h2>
                                                                    <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close"></span>
                                                                </div>
                                                                <div className="modal-body" dangerouslySetInnerHTML={{ __html: referralInfo.description }}>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dashed-border'></div>
                                            <div className="invite-friend-cntnr">
                                                <div className="invite-friend-div d-flex">
                                                    <div className="invite-friend-icon">
                                                        <img src={phoneShare} alt="" />
                                                    </div>
                                                    <p className="invite-friend-text"><b>Invite A Friend</b> Who Loves Taste ( Friend Gets <b>{referralInfo.referral_type === "Percentage" ? referralInfo.value + "% Off" : "₹ " + referralInfo.value + " Flat"}</b> )</p>
                                                </div>
                                                {/* <div className="invite-friend-div d-flex">
                                                    <div className="invite-friend-icon">
                                                        <img src={phoneCart} alt="" />
                                                    </div>
                                                    <p className="invite-friend-text">Your <b>Friend Shops On The App</b> (You <b>Get Upto ₹50 </b> )</p>
                                                </div> */}
                                            </div>
                                            <div className='dashed-border'></div>
                                            <div className="refer-friends-cntnr">
                                                <h5 className='text-center'>Refer Friends</h5>
                                                <span>Copy this code</span>
                                                <div className="code-whatsapp-cntnr d-flex flex-wrap justify-content-center">
                                                    <div className="copy-code d-flex align-items-center">
                                                        <div className="code-text">{curent_user.referral_code}</div>
                                                        <div className="copy-icon" style={{ color: "red" }}>
                                                            {copySuccess === "Copied" ?
                                                                copySuccess
                                                                :
                                                                (<div className='d-flex align-items-center justify-content-around '>
                                                                    <img className='mr-2' src={copyImg} onClick={handleCopyClick} alt="" style={{marginRight: "5px"}} />
                                                                    {/* <WhatsappShareButton
                                                                        url={`https://www.cottage-cart.com`}
                                                                        title={`Unlock exclusive benefits by using the referral code ${curent_user.referral_code}. Experience the perks of being part of our community! Visit our website at `}
                                                                    >
                                                                        <WhatsappIcon size={28} round />
                                                                    </WhatsappShareButton> */}
                                                                </div>)
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='invite-refer-wa'>
                                                        <WhatsappShareButton
                                                            url={`https://www.cottage-cart.com`}
                                                            title={`Unlock exclusive benefits by using the referral code ${curent_user.referral_code}. Experience the perks of being part of our community! Visit our website at `}
                                                            className='btn btn-secondary'
                                                            style={{ backgroundColor: "#ffebf0", border: "1px dashed #c9184a", boxShadow: "rgb(224, 224, 224) 5px 5px 5px 0px;", borderRadius: "10px", padding: "10px 21px;", height: "50px"}}
                                                        >
                                                            Invite via <WhatsappIcon size={28} round />
                                                        </WhatsappShareButton>
                                                    </div>

                                                    {/* <div className='share-btn d-flex align-items-center justify-content-center' type='button' data-bs-toggle="modal" data-bs-target="#socialIconModal">
                                                        <img src={sharePng} alt="" />
                                                    </div> */}
                                                    <div className="modal fade" id="socialIconModal" tabindex="-1" aria-labelledby="socialIconModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                            <div className="modal-content">
                                                                <div className="modal-header tc-modal-header">
                                                                    <h2 className="modal-title fs-5 tc-modal-title" id="socialIconModalLabel">COTTAGE CART</h2>
                                                                    <span className='icon-circle-cross modal-cross-btn' type="button" data-bs-dismiss="modal" aria-label="Close"></span>
                                                                </div>
                                                                <div className="modal-body d-flex">
                                                                    {/* <div className="social-icon-div">
                                                                        <EmailShareButton url={`https://www.cottage-cart.com/product-cart`} subject={`Check out this awesome product: `} body={`Check out this awesome product: ${`https://www.cottage-cart.com/product-cart`}`}>
                                                                            <EmailIcon size={64} round />
                                                                        </EmailShareButton>
                                                                    </div>
                                                                    <div className="social-icon-div">
                                                                        <TwitterShareButton url={`https://www.cottage-cart.com/product-cart`} title={`Check out this awesome product: `}>
                                                                            <TwitterIcon size={64} round />
                                                                        </TwitterShareButton>
                                                                    </div> */}
                                                                    <div className="social-icon-div">
                                                                        <WhatsappShareButton url={`https://www.cottage-cart.com/product-cart`} title={`Check out this awesome product: `}>
                                                                            <WhatsappIcon size={64} round />
                                                                        </WhatsappShareButton>
                                                                    </div>
                                                                    <div className="social-icon-div">
                                                                        <FacebookShareButton url={`https://www.cottage-cart.com/product-cart`} title={`Check out this awesome product: `}>
                                                                            <FacebookIcon size={64} round />
                                                                        </FacebookShareButton>
                                                                    </div>
                                                                    {/* <div className="social-icon-div">
                                                                        <PinterestShareButton url={`https://www.cottage-cart.com/product-cart`} title={`Check out this awesome product: `}>
                                                                            <PinterestIcon size={64} round />
                                                                        </PinterestShareButton>
                                                                    </div> */}




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="client-records-cntnr">
                                            {/* <h5>Refer Users</h5>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                        <th colspan=""></th> 
                                                        <th colspan="2">Name</th>
                                                        <th colspan="2">Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tracking.map((val,i) => {
                                                            return (
                                                                <>
                                                                    <tr key={i}>
                                                                        <td colspan="2">
                                                                            <div className="client-avtr-div">
                                                                                <img src={clientAvtr1} alt="" />
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="2">
                                                                            {val.username}
                                                                        </td>
                                                                        <td colspan="2">
                                                                            {val.time}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

$('.Privacy-topic .nav-item .nav-link').click(function () {
    $('#home-tab').removeClass('breadcrum');
    $(this).addClass('breadcrum');
    var cc = $('.breadcrum').text();
    $('#breadcrum').text(cc);
    $('.nav-item .nav-link').removeClass('breadcrum');
})

var form_ele = '.form';
var disableFormEdit = function (selector) {
    $(selector).removeClass('form--enabled').addClass('form--disabled');
    $(selector + ' input, ' + selector + ' select, ' + selector + ' button').prop('disabled', true);
}

var enableFormEdit = function (selector) {
    $(selector + ' input, ' + selector + ' select, ' + selector + ' button ').prop('disabled', false);
    $(selector).removeClass('form--disabled').addClass('form--enabled');
}

disableFormEdit(form_ele);





export default Dashboard;

