import React, {useState, useEffect} from 'react'
import underline from "../../assets/img/Group 1358.svg";
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';

function FAQ() {
    const [faqs, setfaqs] = useState([]);
    const [faq, setfaq] = useState([]);
    const faq_que = () => {

        //api call of image slider
        axios.post(globeVar + "faqs").then((response)=>{
            if(response.data.type === "success")
            {
              // console.log(response.data.data.faqs)
              setfaq(response.data.data.faqs); 
              setfaqs(response.data.data); 
            }      
          }) 
       };
       useEffect(() => {
        window.scrollTo(0, 0);
        faq_que();
       }, [])
  return (
    <div>
      <ScrollToTop />
    <section className="privacy-banner" style={{backgroundImage: `url('${faqs.privacy_img}')`}}>
      <h1 className="text-center">{faqs.title}</h1>
        <ul className="privacy-path">
          <li><Link to={`/`}>Home</Link></li>
          <li> {`>`} </li>
          <li>{faqs.title}</li>
        </ul>
  </section>

  <section className="Privacy-policy pb-5 pt-5">
      <div className="container">
          <div className="product-headeing-section container faq-underline">
              <h5>{faqs.title}</h5>
              <img src={underline} alt="" />
          </div>
          <div className="row mt-5">
              <div className="col-lg-12 col-md-12 Privacy-condition tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="accordion" id="accordionExample">
                        {faq.map((rec,i) =>{if(i === 0){
                            return<div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+i} aria-expanded="true" aria-controls="collapse">
                                  {rec.title}
                              </button>
                            </h2>
                            <div id={"collapse"+i} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                 {rec.sub_title}
                              </div>
                            </div>
                          </div>
                        }else if(i !== 0 ){
                            return<div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+i} aria-expanded="true" aria-controls="collapse">
                                  {rec.title}
                              </button>
                            </h2>
                            <div id={"collapse"+i} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                 {rec.sub_title}
                              </div>
                            </div>
                          </div>
                        }
                        })}     
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </div>
  )
}

export default FAQ
