import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import Home from './Components/Pages/Home';
import Product from './Components/Pages/Product';
import ProductCart from './Components/Pages/ProductCart';
import MyCart from './Components/Pages/MyCart';
import Address from './Components/Pages/Address';
import Payment from './Components/Pages/Payment';
import Privacy from './Components/Pages/Privacy';
import FAQ from './Components/Pages/FAQ';
import Term_Condition from './Components/Pages/Term_Condition';
import About_Us from './Components/Pages/About_Us';
import MissionVision from './Components/Pages/MissionVision';
import Blog from './Components/Pages/Blog';
import Return_Policy from './Components/Pages/Return_Policy';
import Shipping_Policy from './Components/Pages/Shipping_Policy';
import Dashboard from './Components/InnerPage/Dashboard';
import OrderSuccess from './Components/InnerPage/OrderSuccess';
import SuccessPayment from './Components/InnerPage/SuccessPayment';
import ChefDetail from './Components/Pages/ChefDetail';
import BlogDetail from './Components/Pages/BlogDetail';
import Event from './Components/Pages/Event';
import EvnetDetail from './Components/Pages/EventDetail'
import Offers from './Components/Pages/Offers'
import CorporateAV from './Components/Pages/CorporateAV';
import MyContextProvider from './Components/Layout/MyContextProvider';
import Firstpage from './Components/InnerPage/Firstpage';
import FailedPayment from './Components/InnerPage/FailedPayment';
function App() {

  if (sessionStorage.getItem("user")) {
    return (
      <>
        <MyContextProvider>
          <Router>
            <Header />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route exact path="/" element={<Home />} />
              <Route path="/product" element={<Product />} />
              <Route path="/product-cart/:prod_id" element={<ProductCart />} />
              <Route path="/my-cart" element={<MyCart />} />
              <Route path="/address" element={<Address />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/term_condition" element={<Term_Condition />} />
              <Route path="/about_us" element={<About_Us />} />
              <Route path="/mission_vision" element={<MissionVision />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/return_policy" element={<Return_Policy />} />
              <Route path="/shipping_policy" element={<Shipping_Policy />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/payment-success/:order_id" element={<SuccessPayment />} />
              <Route path="/payment-cancel" element={<FailedPayment />} />
              <Route path="/chef_detail" element={<ChefDetail />} />
              <Route path="/blog_detail/:id" element={<BlogDetail />} />
              <Route path="/event" element={<Event />} />
              <Route path="/event_detail/:id" element={<EvnetDetail />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/corporateAV" element={<CorporateAV />} />
            </Routes>
            <Footer />
          </Router>
        </MyContextProvider>
      </>
    );
  } else {
    // if (sessionStorage.getItem("valid")) {
      return (
        <>
          <MyContextProvider>
            <Router>
          
              <Header />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/product" element={<Product />} />
                <Route path="/product-cart/:prod_id" element={<ProductCart />} />
                <Route path="/my-cart" element={<MyCart />} />
                <Route path="/address" element={<Address />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/term_condition" element={<Term_Condition />} />
                <Route path="/about_us" element={<About_Us />} />
                <Route path="/mission_vision" element={<MissionVision />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/return_policy" element={<Return_Policy />} />
                <Route path="/shipping_policy" element={<Shipping_Policy />} />
                <Route path="/chef_detail" element={<ChefDetail />} />
                <Route path="/blog_detail/:id" element={<BlogDetail />} />
                <Route path="/event" element={<Event />} />
                <Route path="/event_detail/:id" element={<EvnetDetail />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/corporateAV" element={<CorporateAV />} />
              </Routes>
              <Footer />
            </Router>
          </MyContextProvider>
        </>
      );
    // } else {
    //   return (
    //   <>
    //       <MyContextProvider>
    //         <Router>
    //           <Routes>
    //             <Route exact path="/" element={<Firstpage />} />
    //             <Route path="/term_condition" element={<Term_Condition />} />
    //             <Route path="/privacy" element={<Privacy />} />
    //           </Routes>
    //         </Router>
    //       </MyContextProvider>
    //     </>
    //   );
    // }
  }
}

export default App;
