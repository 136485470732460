import axios from 'axios';
import React, { useEffect, useState } from 'react'
import globeVar from '../../GlobeApi';

const Banner = () => {
    
    const [cms, setcms] = useState([]);
    const [row1, setrow1] = useState([]);
    const [row2, setrow2] = useState([]);
    
    useEffect(() => {

        //api of therapist
        axios.post(globeVar + "home").then((response) => {
            if (response.data.type === "success") {
                setcms(response.data.home_data.cms[0]);
                // setcategory(response.data.home_data.category);

            }
        });


        //api of cms_carousel & cms_region
        axios.post(globeVar + "cms_carousel").then((response) => {
            if (response.data.type === "success") {
                setrow1(response.data.data[0].row1);
                setrow2(response.data.data[0].row2);

            }
        });

    }, [])
  return (
    <div>
        <section className="banner-section" style={{ backgroundImage: `url('${cms.banner_img}')` }} >
          <div className="container">
            <div className="row justify-content-center g-0">
              <div className="col-xl-5 col-lg-6 col-md-7 text-center detail-center">
                <h2
                  className="center-heading"
                  dangerouslySetInnerHTML={{ __html: cms.title }}
                />
                <p
                  className="center-para text-center"
                  dangerouslySetInnerHTML={{ __html: cms.sub_title }}
                />
                {/* <form action="" onSubmit={(e) => handelSubmit(e)}>
                <div className="input-group mb-3 global-search">
                  <span className="input-group-text icon-location"></span>
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Pune
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Pune
                      </a>
                    </li>
                  </ul>
                  <input
                    type="text"
                    className="form-control me-2"
                    name="search"
                    id="search"
                    onChange={(e) => handelInput(e)}
                    value={search}
                    placeholder="Search for products, brands and more"
                    aria-label="Text input with 2 dropdown buttons"
                  />
                  <button className="btn btn-search" type="submit">
                    <i className="icon-search me-2"></i>
                    <span className="d-sm-inline d-none">Search</span>
                  </button>
                </div>
              </form> */}
              </div>
            </div>
          </div>
        </section>


        <section className="brand-slider">
          <div className="cc-slider-container">
            <div className="cc-slider-wrapper">
              <div className="cc-slider-body">
                <div
                  className="cc-slider-section ScrollingCarousel-7a0c7742e021e755"
                  style={{ left: "-66px", animationDuration: "130s" }}
                >
                {row1.map((rowImg) => {
                  const { img, img_type } = rowImg
                  return (
                    <div className={img_type === "long_img" ? "cc-slider-items abc" : "cc-slider-items" }>
                      <img
                        src={img}
                        alt=""
                        className={img_type === "long_img" ? "image-box image-box-secondary" : "image-box" }
                      />
                    </div>
                  )
                })}
                </div>
              </div>
            </div>
            <div className="cc-slider-wrapper">
              <div className="cc-slider-body">
                <div
                  className="cc-slider-section ScrollingCarousel-7a0c7742e021e755"
                  style={{ animationDuration: "130s" }}
                >
                {row2.map((rowImg) => {
                  const { img, img_type } = rowImg
                  return (
                    <div className={img_type === "long_img" ? "cc-slider-items abc" : "cc-slider-items" }>
                      <img
                        src={img}
                        alt=""
                        className={img_type === "long_img" ? "image-box image-box-secondary" : "image-box" }
                      />
                    </div>
                  )
                })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

export default Banner