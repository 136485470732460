import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import underline from "../../assets/img/Group 444.svg";
import axios from "axios";
import basket from "../../assets/img/homepage/basket.svg";
import like from "../../assets/img/homepage/like.png";
import globeVar from "../../GlobeApi";
import img_not_available from "../../assets/img/Image_not_available.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ChefDetail() {
  const [user, setuser] = useState(null);
  var chef_id = sessionStorage.getItem("chef_id");
  var data = sessionStorage.getItem("user");
  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setuser(JSON.parse(sessionStorage.getItem("user")));
    }
  }, []);
  data = JSON.parse(data);
  const [chefDetails, setchefDetails] = useState([]);
  const [BestProduct, setBestProduct] = useState([]);

  const add_card = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = data._id;
      const product_data = id;
      const product_qty = 1;
      let items = { productId: product_data, quantity: parseInt(product_qty) };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        if (response.data.type === "success") {
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: '#c9184a' },
          });
          /* window.location.href = "/chef_detail"; */
        }
      });
    } else {
      const product_data = id;
      const product_qty = 1;
      /* if(!localStorage.getItem("cart")){
        var cart =[];
        localStorage.setItem("cart", JSON.stringify(cart))
        }
        var cart = JSON.parse(localStorage.getItem("cart")); */
      var items;
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
        };
      } else {
        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
        };
      }
      /*  cart.push(items);
        localStorage.setItem("cart", JSON.stringify(cart) )
        window.location.href="/my-cart"; */
      // console.log(items);
      axios.post(globeVar + "addtocart", items).then((response) => {
        if (response.data.type === "success") {
          // console.log(response.data);
          if (!response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          } else {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} />,
            style: { background: '#c9184a' },
          });
          /* window.location.href = "/chef_detail"; */
        }
      });
    }
  };

  const [wishlist, setwishlist] = useState([]);
  function changeIcon(anchor, productId) {
    if (sessionStorage.getItem("user")) {
      var icon = anchor.target;
      icon.classList.toggle("icon-wishlist");
      icon.classList.toggle("icon-wishlist-fill");
      const item = { userId: data._id, productId: productId };
      axios.post(globeVar + "add_to_wishlist", item).then((response) => {

        if (response.data.type === "success") {
          var wishlist_id = [];
          if (response.data.newWish) {
            response.data.newWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          } else if (response.data.updatedWish) {
            response.data.updatedWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          }
          var x = wishlist.includes(productId);
          if (x === true) {
            toast.success('Removed from Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: '#c9184a' },
            });
          } else {
            toast.success('Added to Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} />,
              style: { background: '#c9184a' },
            });
          }
          setwishlist(wishlist_id);

        }
      });
    } else {
      window.$("#exampleModalToggle").modal("show");
      localStorage.setItem("wishlist", JSON.stringify(productId));
    }
  }

  useEffect(() => {
    const items = { chef_id: chef_id }
    axios.post(globeVar + "chef_detail", items).then((response) => {
      if (response.data.type === "success") {
        setchefDetails(response.data.data);
      }
    });
    axios.post(globeVar + "best_product", items).then((response) => {
      if (response.data.type === "success") {
        setBestProduct(response.data.data);
      }
    });

    if (sessionStorage.getItem("user")) {
      // if (data.role !== "retailer") {
        const item = { userId: data._id };
        axios.post(globeVar + "get_Wishlist", item).then((response) => {

          if (response.data.type === "success") {
            var wishlist_id = [];
            response.data.wishlist.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
            setwishlist(wishlist_id);
          }
        });
      // }
    }
    setTimeout(() => {
      sessionStorage.removeItem("functionality");
    }, 2000);
  }, []);
  // console.log("Shweta", chefDetails)
  return (
    <div>
      <ToastContainer style={{ width: "fit-content" }} />
      <section className="chef-detail-banner privacy-banner">
        <div className="container-xxl">
          <div className="row g-0">
            <div className="col-md-3">
              <ul className="privacy-path">
                <li>
                  <a href="/">Home</a>
                </li>
                <li> &gt; </li>
                <li>Home Chefs</li>
              </ul>
            </div>
          </div>
          <div className="row g-0 justify-content-center">
            <div className="col-sm-6 col-md-6 col-lg-3 text-center chef-bnr-cntr-dtl">
              <h1>{chefDetails.title}</h1>
              <p>{chefDetails.sub_title}</p>
              {/* <span className="rating-point">4.6</span>
              <span className="icon-starfill star-rating"></span>
              <span className="icon-starfill star-rating"></span>
              <span className="icon-starfill star-rating"></span>
              <span className="icon-starfill star-rating"></span>
              <span className="icon-starhalf star-rating"></span>
              <span className="ratings">(66 ratings)</span>
              <span className="chef-detail-cstmrs">8,765 customers</span> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container position-relative">
        <div className="chef-profile-pic-cntnr">
          <div className="chef-profile-pic-img">
            <img
              src={chefDetails.avatar}
              alt="Chef Profile Image"
            />
          </div>
        </div>
      </div>
      <section className="chef-my-profile">
        <div className="container container-el">
          <div className="row justify-content-between">
            <div className="col-lg-6 chef-my-profile-dtl">
              <div className="product-headeing-section chef-underline">
                <h5>My Profile</h5>
                <img src={underline} alt="" className="chef-underline" />
              </div>
              <p>
                {chefDetails.profile_desc}
              </p>
            </div>
            <div className="col-lg-5 chef-my-profile-vdo">
              <div className="my-profile-vdo-img">
                <video src={chefDetails.video} autoPlay loop muted controls playsInline></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="chef-best-products popular-section">
        <div className="container container-el">
          <div className="product-headeing-section">
            <h5>Best Products</h5>
            <img src={underline} alt="" className="best-product-underline" />
          </div>
          <div className="popular-slider-row row">
            {BestProduct.map(data => {
              return <div className="col-sm-4 col-6 col-lg-3 best-product-col" key={data._id}>
                <div className="card">
                  { user?.role !== "retailer" && data.discount ? (
                    <span className="price-tag">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="69.521"
                        height="74.698"
                        viewBox="0 0 69.521 74.698"
                      >
                        <defs>
                          <filter
                            id="_281639d9032fd35ab631d5064a2c1da9"
                            x="0"
                            y="5.177"
                            width="69.521"
                            height="69.521"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="2" result="blur" />
                            <feFlood floodColor="#7a6b6b" floodOpacity="0.361" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Component_59_1"
                          data-name="Component 59 – 1"
                          transform="translate(6.865 0.507)"
                        >
                          <g
                            id="Group_272"
                            data-name="Group 272"
                            transform="translate(-357.104 -1330.704)"
                          >
                            <g
                              id="Group_269"
                              data-name="Group 269"
                              transform="translate(278 -41)"
                            >
                              <g id="Group_268" data-name="Group 268">
                                <g
                                  id="Group_267"
                                  data-name="Group 267"
                                  className="discount"
                                >
                                  <g
                                    transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                    filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                                  >
                                    <path
                                      id="_281639d9032fd35ab631d5064a2c1da9-2"
                                      data-name="281639d9032fd35ab631d5064a2c1da9"
                                      d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                      transform="translate(5.45 35.39) rotate(-45)"
                                      fill="#fff1f5"
                                      stroke="#ee7e9e"
                                      strokeWidth="1"
                                      className="rotate-tag"
                                    />
                                  </g>
                                  <text
                                    id="_15_"
                                    className="_15_"
                                    data-name="15%"
                                    transform="translate(98 1413)"
                                    fontSize="12"
                                    fontFamily="Comfortaa-Bold, Comfortaa"
                                    fontWeight="700"
                                  >
                                    <tspan x="0" y="0">
                                      {data.discount}%
                                    </tspan>
                                  </text>
                                </g>
                              </g>
                            </g>
                            <g
                              id="Group_270"
                              data-name="Group 270"
                              className="hook"
                              transform="translate(277.674 -41)"
                            >
                              <path
                                id="Path_156"
                                data-name="Path 156"
                                d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                transform="translate(1997.472 4421.696)"
                                fill="none"
                                stroke="#bbb"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_155"
                                data-name="Path 155"
                                d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                transform="translate(1980.552 4427.111)"
                                fill="none"
                                stroke="#bbb"
                                strokeWidth="1"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  ) : (
                    ""
                  )}
                  <Link to={`/product-cart/${data._id}`}>
                    {
                      data.prod_gal == "Video" ?
                        <img src={data.video_thambnail ? data.video_thambnail : img_not_available} className="card-img-top" alt="..." onError={(e) => {
                          // console.log('1', e);
                          e.target.onerror = null; // prevents looping
                          e.target.src = "../../assets/img/homepage/Image_not_available.png";
                        }} />
                        :
                        <img src={data.product_img ? data.product_img : img_not_available} className="card-img-top" alt="..." onError={(e) => {
                          // console.log('2', e);
                          e.target.onerror = null; // prevents looping
                          e.target.src = "../../assets/img/homepage/Image_not_available.png";
                        }} />
                    }

                  </Link>
                  <button
                    onClick={(e) => changeIcon(e, data._id)}
                    className="like-btn border-0 bg-transparent"
                  >
                    {wishlist.length > 0 ? (
                      wishlist.includes(data._id) ? (
                        <span className="icon-wishlist-fill"></span>
                      ) : (
                        <span className="icon-wishlist"></span>
                      )
                    ) : (
                      <span className="icon-wishlist"></span>
                    )}
                  </button>
                  <div className="card-body">
                    {data.tags === "Trending" ? (
                      <div className="flag1">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    ) : data.tags === "Popular" ? (
                      <div className="flag2">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    ) : data.tags === "New Arrivals" ? (
                      <div className="flag3">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    ) : data.tags === "Combo Offer" ? (
                      <div className="flag4">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    ) : data.tags === "Subscription" ? (
                      <div className="flag5">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    ) : (
                      <div className="flag6">
                        <span className="flag-text">{data.tags}</span>
                      </div>
                    )}
                    <h4>{data.product_name}</h4>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{user && user?.role === "retailer" ? "₹" + data?.retailerPrice + " ( CP : "  :""} ₹{ data.saving ? data.price - data.saving : data.price} { user?.role !== "retailer" && data.discount ? <del className="ms-1">₹{data.price}</del> : ""} {user && user?.role === "retailer" ? " )" :" "}</span>
                      <button
                        onClick={() => add_card(data._id)}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <img src={basket} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChefDetail;
