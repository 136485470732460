import React, { useEffect, useState } from 'react'
import appImage from '../../assets/img/showpopup.png';
import { Link, useNavigate } from 'react-router-dom';
import collection_underline from "../../assets/img/Group 443.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from 'axios';
import globeVar from '../../GlobeApi';
import { toast } from 'react-toastify';
import like from "../../assets/img/homepage/like.png";
import basket from "../../assets/img/homepage/basket.svg";

const Collection = () => {
  const [popular, setPopular] = useState([]);
  const [mobilePopular, setMobilePopular] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const navigate = useNavigate();
  var data = JSON.parse(sessionStorage.getItem('user'));
  
  // const isMobile = window.innerWidth <= 500; 
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 500);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleWindowResize = () => {
    window.innerWidth >= 500 ? setIsLaptop(true) : setIsLaptop(false);
    window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  const opti = {
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },

      520: {
        items: 2,
      },

      768: {
        items: 3,
        margin: 13,
      },
      950: {
        items: 3,
        margin: 13,
      },

      1000: {
        items: 4,
        margin: 13,
      },
      1200: {
        items: 4,
        margin: 50,
      },
      1400: {
        items: 5,
        margin: 21,
      },
    },
  };
  const optiMob = {
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },

      520: {
        items: 2,
      },

      768: {
        items: 3,
        margin: 13,
      },
      950: {
        items: 3,
        margin: 13,
      },

      1000: {
        items: 4,
        margin: 13,
      },
      1200: {
        items: 4,
        margin: 50,
      },
      1400: {
        items: 5,
        margin: 21,
      },
    },
  };

  function changeIcon(anchor, productId) {
    if (sessionStorage.getItem("user")) {
      var icon = anchor.target;
      icon.classList.toggle("icon-wishlist");
      icon.classList.toggle("icon-wishlist-fill");
      const item = { userId: data._id, productId: productId };
      axios.post(globeVar + "add_to_wishlist", item).then((response) => {

        if (response.data.type === "success") {
          var wishlist_id = [];
          if (response.data.newWish) {
            response.data.newWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);
          } else if (response.data.updatedWish) {
            response.data.updatedWish.products.forEach((rev) => {
              wishlist_id.push(rev.productId);
            });
            // console.log(wishlist_id);

          }
          var x = wishlist.includes(productId);
          if (x === true) {
            toast.success('Removed from Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} alt='like'/>,
              style: { background: '#c9184a' },
            });
          } else {
            toast.success('Added to Wishlist', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              icon: <img src={like} alt='like' />,
              style: { background: '#c9184a' },
            });
          }
          setwishlist(wishlist_id);

        }
      });
    } else {
      window.$("#exampleModalToggle").modal("show");
      localStorage.setItem("wishlist", JSON.stringify(productId));
    }
  }

  const add_card = (id) => {
    if (sessionStorage.getItem("user")) {
      const user_id = data._id;
      const product_data = id;
      const product_qty = 1;
      let items = { productId: product_data, quantity: parseInt(product_qty) };
      axios.post(globeVar + "addtocart/" + user_id, items).then((response) => {
        if (response.data.type === "success") {
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} alt='basket' />,
            style: { background: '#c9184a' },
          });
          /* window.location.href = "/"; */
        }
      });
    } else {
      const product_data = id;
      const product_qty = 1;
      /* if(!localStorage.getItem("cart")){
        var cart =[];
        localStorage.setItem("cart", JSON.stringify(cart))
        }
        var cart = JSON.parse(localStorage.getItem("cart")); */
      var items;
      if (localStorage.getItem("cart")) {
        var cart = JSON.parse(localStorage.getItem("cart"));

        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
          cart_id: cart,
        };
      } else {
        items = {
          productId: product_data,
          quantity: parseInt(product_qty),
        };
      }
      /*  cart.push(items);
        localStorage.setItem("cart", JSON.stringify(cart) )
        window.location.href="/my-cart"; */
      // console.log(items);
      axios.post(globeVar + "addtocart", items).then((response) => {
        if (response.data.type === "success") {
          toast.success('Added to Cart', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            icon: <img src={basket} alt='basket' />,
            style: { background: '#c9184a' },
          });
          // console.log(response.data);
          if (!response.data.newCart) {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.updatedCart._id)
            );
          } else {
            localStorage.setItem(
              "cart",
              JSON.stringify(response.data.newCart._id)
            );
          }

          /* window.location.href = "/"; */
        }
      });
    }
  };

  useEffect(() => {
    handleWindowResize();
    if (sessionStorage.getItem("user")) {
      var data = sessionStorage.getItem("user");
      data = JSON.parse(data);
      const item = { userId: data._id };
      axios.post(globeVar + "get_Wishlist", item).then((response) => {

        if (response.data.type === "success") {
          var wishlist_id = [];
          response.data.wishlist.products.forEach((rev) => {
            wishlist_id.push(rev.productId);
          });
          // console.log(wishlist_id);
          setwishlist(wishlist_id);
          axios.post(globeVar + "product_tags_list").then((response) => {
            if (response.data.type === "success") {
              setPopular(response.data.data);
            }
          });
        }
      });
    }

    axios.post(globeVar + "product_tags_list").then((response) => {
      if (response.data.type === "success") {
        setPopular(response.data.data);
        var Arr = [];
        var childArr = [];
        var popChild = response.data.data;
        popChild.map((pop, i) => {
          let modules = i % 4;
          childArr.push(pop);
          if (modules === 3 || i === popChild.length-1) {
            Arr.push(childArr);
            childArr = [];
          }
        })
        setMobilePopular(Arr);
      }
    });

  }, [])
  
  return (
    <>
    {!isMobile && (
      <section className="popular-section d-none d-sm-block">
        <div className="container  container-el">
          <div className="row d-flex justify-content-between shop_underline ">
            <div className="col-4">
              <div className="product-headeing-section">
                <h5>Collections</h5>
                <img src={collection_underline} alt="" className="popular-underline" />
              </div>
            </div>
            <div className="col-4 text-right">
              <li
                className="list-group-item view-all-btn pt-3"
                style={{
                  float: "right",
                  color: "#c9184a",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => navigate('/product')}
              >
                View All
              </li>
            </div>
          </div>


          <div className="popular-slider-row">
            <div className="container  container-el coloured-bg swiper">
              <div className=" popularSwiper">
                <div className="swiper-wrapper">
                  <OwlCarousel
                    items={5}
                    {...opti}
                    autoplayHoverPause={true}
                    loop
                    autoplay={true}
                    autoplayTimeout={3000}
                    nav={true}
                    dots={true}
                  >
                    {popular.map((rec) => {
                      return (
                        <div className="swiper-slide slide-width" key={rec._id}>
                          <div className="card">
                            {data?.role !== "retailer" && rec.discount ? (
                              <span className="price-tag">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="69.521"
                                  height="74.698"
                                  viewBox="0 0 69.521 74.698"
                                >
                                  <defs>
                                    <filter
                                      id="_281639d9032fd35ab631d5064a2c1da9"
                                      x="0"
                                      y="5.177"
                                      width="69.521"
                                      height="69.521"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feOffset dy="3" input="SourceAlpha" />
                                      <feGaussianBlur
                                        stdDeviation="2"
                                        result="blur"
                                      />
                                      <feFlood
                                        floodColor="#7a6b6b"
                                        floodOpacity="0.361"
                                      />
                                      <feComposite operator="in" in2="blur" />
                                      <feComposite in="SourceGraphic" />
                                    </filter>
                                  </defs>
                                  <g
                                    id="Component_59_1"
                                    data-name="Component 59 – 1"
                                    transform="translate(6.865 0.507)"
                                  >
                                    <g
                                      id="Group_272"
                                      data-name="Group 272"
                                      transform="translate(-357.104 -1330.704)"
                                    >
                                      <g
                                        id="Group_269"
                                        data-name="Group 269"
                                        transform="translate(278 -41)"
                                      >
                                        <g id="Group_268" data-name="Group 268">
                                          <g
                                            id="Group_267"
                                            data-name="Group 267"
                                            className="discount"
                                          >
                                            <g
                                              transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                              filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                                            >
                                              <path
                                                id="_281639d9032fd35ab631d5064a2c1da9-2"
                                                data-name="281639d9032fd35ab631d5064a2c1da9"
                                                d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                                transform="translate(5.45 35.39) rotate(-45)"
                                                fill="#fff1f5"
                                                stroke="#ee7e9e"
                                                strokeWidth="1"
                                                className="rotate-tag"
                                              />
                                            </g>
                                            <text
                                              id="_15_"
                                              className="_15_"
                                              data-name="15%"
                                              transform="translate(98 1413)"
                                              fontSize="12"
                                              fontFamily="Comfortaa-Bold, Comfortaa"
                                              fontWeight="700"
                                            >
                                              <tspan x="0" y="0">
                                                {rec.discount}%
                                              </tspan>
                                            </text>
                                          </g>
                                        </g>
                                      </g>
                                      <g
                                        id="Group_270"
                                        data-name="Group 270"
                                        className="hook"
                                        transform="translate(277.674 -41)"
                                      >
                                        <path
                                          id="Path_156"
                                          data-name="Path 156"
                                          d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                          transform="translate(1997.472 4421.696)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_155"
                                          data-name="Path 155"
                                          d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                          transform="translate(1980.552 4427.111)"
                                          fill="none"
                                          stroke="#bbb"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/product-cart/${rec._id}`} >
                              {
                                rec.prod_gal === "Video" ?
                                  <img
                                    src={rec.video_thambnail}
                                    className="card-img-top"
                                    alt="..."
                                  />
                                  :
                                  <img
                                    src={rec.product_img}
                                    className="card-img-top"
                                    alt="..."
                                  />
                              }
                            </Link>
                            <button
                              className="like-btn border-0 bg-transparent"
                            >
                              {wishlist.length > 0 ? (
                                wishlist.includes(rec._id) ? (
                                  <span className="icon-wishlist-fill" onClick={(e) => changeIcon(e, rec._id)}></span>
                                ) : (
                                  <span className="icon-wishlist" onClick={(e) => changeIcon(e, rec._id)}></span>
                                )
                              ) : (
                                <span className="icon-wishlist" onClick={(e) => changeIcon(e, rec._id)}></span>
                              )}

                            </button>

                            <div className="card-body">

                              {rec.tags === "Trending" ?
                                <div className="flag1">
                                  <span className="flag-text">{rec.tags}</span>
                                </div> : rec.tags === "Popular" ?
                                  <div className="flag2">
                                    <span className="flag-text">{rec.tags}</span>
                                  </div> :
                                  <div className="flag3">
                                    <span className="flag-text">{rec.tags}</span>
                                  </div>

                              }



                              {/* <div className="flag new-flag"><span className="flag-text">New</span></div>
                              <div className="flag popular-flag"><span className="flag-text">Popular</span></div> */}
                              <h4>{rec.product_name}</h4>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>{data && data?.role === "retailer" ? "₹" + rec?.retailerPrice + " ( CP : "  :""} ₹{rec.saving ? rec.price - rec.saving : rec.price} {data?.role !== "retailer" && rec.discount ? <del className="ms-1">₹{rec.price}</del> : ""} {data && data?.role === "retailer" ? " )" :" "}</span>

                                <button
                                  onClick={() => add_card(rec._id)}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <img src={basket} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
    
    {!isLaptop &&  (
      <section className="popular-section d-block d-sm-none">
        <div className="container  container-el">
          <div className="row d-flex justify-content-between shop_underline ">
            <div className="col-4">
              <div className="product-headeing-section">
                <h5>Collections</h5>
                <img src={collection_underline} alt="" className="popular-underline" />
              </div>
            </div>
            <div className="col-4 text-right">
              <li
                className="list-group-item view-all-btn pt-3"
                style={{
                  float: "right",
                  color: "#c9184a",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => navigate('/product')}
              >
                View All
              </li>
            </div>
          </div>
          
          <div className="popular-slider-row ">
            <div className="container  container-el coloured-bg swiper">
              <div className=" popularSwiper">
                <div className="swiper-wrapper">
                  <OwlCarousel
                    items={5}
                    {...optiMob}
                    autoplayHoverPause={true}
                    loop
                    autoplay={true}
                    autoplayTimeout={3000}
                    nav={true}
                    dots={true}
                  >
                    {mobilePopular.map((popular, i) => {
                      const funMobPop = (rec) => {
                        return (
                          <div className="col-6">
                            <div className="card pb-2">
                              {rec.discount ? (
                                <span className="price-tag">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="69.521"
                                    height="74.698"
                                    viewBox="0 0 69.521 74.698"
                                  >
                                    <defs>
                                      <filter
                                        id="_281639d9032fd35ab631d5064a2c1da9"
                                        x="0"
                                        y="5.177"
                                        width="69.521"
                                        height="69.521"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feOffset dy="3" input="SourceAlpha" />
                                        <feGaussianBlur
                                          stdDeviation="2"
                                          result="blur"
                                        />
                                        <feFlood
                                          floodColor="#7a6b6b"
                                          floodOpacity="0.361"
                                        />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g
                                      id="Component_59_1"
                                      data-name="Component 59 – 1"
                                      transform="translate(6.865 0.507)"
                                    >
                                      <g
                                        id="Group_272"
                                        data-name="Group 272"
                                        transform="translate(-357.104 -1330.704)"
                                      >
                                        <g
                                          id="Group_269"
                                          data-name="Group 269"
                                          transform="translate(278 -41)"
                                        >
                                          <g id="Group_268" data-name="Group 268">
                                            <g
                                              id="Group_267"
                                              data-name="Group 267"
                                              className="discount"
                                            >
                                              <g
                                                transform="matrix(1, 0, 0, 1, 72.24, 1371.2)"
                                                filter="url(#_281639d9032fd35ab631d5064a2c1da9)"
                                              >
                                                <path
                                                  id="_281639d9032fd35ab631d5064a2c1da9-2"
                                                  data-name="281639d9032fd35ab631d5064a2c1da9"
                                                  d="M0,23.04,21.04,2l17.1,1.315,1.315,17.1L18.41,41.45ZM26.3,11.205A3.945,3.945,0,1,0,30.245,7.26,3.945,3.945,0,0,0,26.3,11.205"
                                                  transform="translate(5.45 35.39) rotate(-45)"
                                                  fill="#fff1f5"
                                                  stroke="#ee7e9e"
                                                  strokeWidth="1"
                                                  className="rotate-tag"
                                                />
                                              </g>
                                              <text
                                                id="_15_"
                                                className="_15_"
                                                data-name="15%"
                                                transform="translate(98 1413)"
                                                fontSize="12"
                                                fontFamily="Comfortaa-Bold, Comfortaa"
                                                fontWeight="700"
                                              >
                                                <tspan x="0" y="0">
                                                  {rec.discount}%
                                                </tspan>
                                              </text>
                                            </g>
                                          </g>
                                        </g>
                                        <g
                                          id="Group_270"
                                          data-name="Group 270"
                                          className="hook"
                                          transform="translate(277.674 -41)"
                                        >
                                          <path
                                            id="Path_156"
                                            data-name="Path 156"
                                            d="M-1888.481-3049.954c-2.053-.333-2.207,1.591-1.42,2.583s0,1.564,0,2.007"
                                            transform="translate(1997.472 4421.696)"
                                            fill="none"
                                            stroke="#bbb"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_155"
                                            data-name="Path 155"
                                            d="M-1878.679-3041.1c-3.255-4.01-.308-10.762,5.682-9.538s7.139,8.9-.938,14.194"
                                            transform="translate(1980.552 4427.111)"
                                            fill="none"
                                            stroke="#bbb"
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/product-cart/${rec._id}`} >
                                {
                                  rec.prod_gal === "Video" ?
                                    <img
                                      src={rec.video_thambnail}
                                      className="card-img-top"
                                      alt="..."
                                    />
                                    :
                                    <img
                                      src={rec.product_img}
                                      className="card-img-top"
                                      alt="..."
                                    />
                                }
                              </Link>
                              <button
                                className="like-btn border-0 bg-transparent"
                              >
                                {wishlist.length > 0 ? (
                                  wishlist.includes(rec._id) ? (
                                    <span className="icon-wishlist-fill" onClick={(e) => changeIcon(e, rec._id)}></span>
                                  ) : (
                                    <span className="icon-wishlist" onClick={(e) => changeIcon(e, rec._id)}></span>
                                  )
                                ) : (
                                  <span className="icon-wishlist" onClick={(e) => changeIcon(e, rec._id)}></span>
                                )}

                              </button>

                              <div className="card-body">

                                {rec.tags === "Trending" ?
                                  <div className="flag1">
                                    <span className="flag-text">{rec.tags}</span>
                                  </div> : rec.tags === "Popular" ?
                                    <div className="flag2">
                                      <span className="flag-text">{rec.tags}</span>
                                    </div> :
                                    <div className="flag3">
                                      <span className="flag-text">{rec.tags}</span>
                                    </div>

                                }
                                {/* <div className="flag new-flag"><span className="flag-text">New</span></div>
                              <div className="flag popular-flag"><span className="flag-text">Popular</span></div> */}
                                <h4>{rec.product_name}</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>₹{rec.saving ? rec.price - rec.saving : rec.price} {rec.discount ? <del className="ms-1">₹{rec.price}</del> : ""}</span>

                                  <button
                                    onClick={() => add_card(rec._id)}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <img src={basket} alt="" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      };

                      return (
                        <div className="swiper-slide slide-width" key={popular[0]._id+""+Math.random()}>
                          <div className="row">
                              {popular.map((rec) =>
                                funMobPop(rec)
                              )}
                          </div>
                        </div>
                      )
                    })
                    }
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
    </>
  )
}

export default Collection