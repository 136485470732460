import React, { useState, useEffect, useContext } from "react";
import firebase from "../../firebase";
import logo from "../../assets/img/zTrial/CC-Logo.png";
import newlogo from "../../assets/img/new-logo.png";
import top_nav_bottom from "../../assets/img/zTrial/top-nav-bottom.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import purchase from "../../assets/img/purchase.gif";
import axios from "axios";
import UserImage from "../../assets/img/user-login.svg";
import leftArrow from "../../assets/img/left-arrow.png";
import SearchIconBlack from "../../assets/img/search-icon-black.png";
import SearchIcon from "../../assets/img/icons-search.png";
import DropdownImage from "../../assets/img/category-icon.svg";
import hamburger from "../../assets/img/hamburger.svg";
import globeVar from "../../GlobeApi";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from "jquery";
import { Alert, Modal } from "react-bootstrap";
import { useRef } from "react";
import MyContext from "./MyContext";
import LogoutImg from "../../assets/img/logout-img.png";
import e from "cors";
import HeaderCart from "../InnerPage/HeaderCart";

const Header = () => {
  //

  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef();
  const path = location.pathname;
  const [login, setlogin] = useState({
    mobile: "",
    otp: "",
  });

  const [signup, setsignup] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
  });

  function myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
  const [first_nameError, setfirst_nameError] = useState("");
  const [last_nameError, setlast_nameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [mobile_noError, setmobile_noError] = useState("");

  const { mobile, otp } = login;
  const { first_name, last_name, email, mobile_no } = signup;
  var user = sessionStorage.getItem("user");
  user = JSON.parse(user);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidPhone(phone) {
    return /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phone);
  }

  const handleChanges = (e) => {
    var error_new = $(".error_new");
    if (!(isValidEmail(e.target.value) || isValidPhone(e.target.value))) {
      //console.log('Email is invalid');
      error_new.css("color", "Red");
      error_new.html("Email / Phone are invalid");
    } else {
      //setError(null);
      error_new.css("color", "green");
      error_new.html("Valid");
    }
    // console.log(e.target.value);
    setlogin({ ...login, [e.target.name]: e.target.value });
  };

  const handle_signup = (e) => {
    setsignup({ ...signup, [e.target.name]: e.target.value });
  };

  const onSignupSubmit = (e) => {
    e.preventDefault();
    if (signup.first_name === "") {
      setfirst_nameError("Required");
    } else if (!signup.first_name.match(/^[a-zA-Z]+$/)) {
      setfirst_nameError("Invalid");
    } else {
      setfirst_nameError("");
    }

    if (signup.last_name === "") {
      setlast_nameError("Required");
    } else if (!signup.last_name.match(/^[a-zA-Z]+$/)) {
      setlast_nameError("Invalid");
    } else {
      setlast_nameError("");
    }

    if (signup.email === "") {
      setemailError("Required");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signup.email)
    ) {
      setemailError("Invalid");
    } else {
      setemailError("");
    }

    if (signup.mobile_no === "") {
      setmobile_noError("Required");
    } else if (signup.mobile_no.length != 10) {
      setmobile_noError("Invalid");
    } else if (
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/i.test(
        signup.mobile_no
      )
    ) {
      setmobile_noError("");
    } else {
      //setmobile_noError('');
      setmobile_noError("Invalid");
    }

    if (
      !signup.first_name.match(/^[a-zA-Z]+$/) == false &&
      !signup.last_name.match(/^[a-zA-Z]+$/) == false &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signup.email) ==
        false &&
      (signup.mobile_no.length != 10) == false
    ) {
      const item = {
        first_name: signup.first_name,
        last_name: signup.last_name,
        email: signup.email,
        phone: signup.mobile_no,
      };
      axios.post(globeVar + "registration", item).then((response) => {
        if (response.data.type === "success") {
          setsignup({
            first_name: "",
            last_name: "",
            email: "",
            mobile_no: "",
          });
          alert_msg(response.data.type, response.data.message);
          setTimeout(() => {
            $("#exampleModalToggle1").modal("hide");
            $("#exampleModalToggle").modal("show");
          }, 3000); 
        } else {
          setsignup({
            first_name: "",
            last_name: "",
            email: "",
            mobile_no: "",
          });
          alert_msg(response.data.type, response.data.message);
        }
      });
    }
  };

  const STATUS = {
    pause: 0,
    start: 1,
    default: 2,
  };
  /* const [minutes, setMinutes] = React.useState(2); */
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(59);
  const [displayMessage, setDisplayMessage] = React.useState(false);
  const [status, setStatus] = React.useState(STATUS.default);
  const intervalRef = React.useRef();

  function countdoen() {
    $("#time").show();
    $("#demo").hide();
    document.getElementById("otp").value = "";
    $("#otp").text("");
    $(".otps").text("");
    $("#otpnumberhide").show();
    $(".otpnumberhide").attr("style", "display:block !important");

    $("#back").show();
    if (timerMinutes <= 0 && timerSeconds <= 0) {
      $("#demo").show();
      $("#time").hide();
      $("#otpnumberhide").hide();
      $(".otpnumberhide").attr("style", "display:none !important");
      document.getElementById("otp").value = "";
      $("#otp").text("");
      $(".otps").text("");
      setStatus(STATUS.pause);
    }
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes((min) => (min - 1 ? "1" : "0"));
      } else {
        let mins = displayMessage ? 1 : "";
        let sec = 59;
        setSeconds(sec);
        setMinutes(mins);
        setDisplayMessage((value) => !value);
      }
    } else {
      setSeconds((sec) => sec - 1);
    }
  }

  useEffect(() => {
    if (status === STATUS.start) {
      intervalRef.current = setInterval(() => {
        countdoen();
      }, 1000);
    } else if (status === STATUS.pause && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [minutes, seconds, status]);

  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;

  const start = () => setStatus(STATUS.start);
  const stop = () => {
    setStatus(STATUS.pause);
    setMinutes(1);
    setSeconds(59);
  };

  //  Serch
  const [fullForm, setfullForm] = useState({
    search1: "",
  });

  const { search1 } = fullForm;
  const handelInput = (e) => {
    setfullForm({ ...fullForm, [e.target.name]: e.target.value });
  };

  const [val, setval] = useState("");
  const handelSubmit = async (e) => {
    e.preventDefault();
    var value = fullForm.search1;
    // console.log(value);
    //navigate("/product", { state: { product_data: fullForm.search1 } });
    window.location.href = "/product?data=" + value;
    setval(value);
  };
  // for logout are you sure popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // for logout are you sure popup end
  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
          // console.log("Recaptca varified");
        },
        defaultCountry: "IN",
      }
    );
  };

  const { activeNavItem, setActiveNavItem } = useContext(MyContext);

  const onSignInSubmit = (e) => {
    //  setlogin({ otp: "" })
    setMinutes(0);
    setSeconds(59);
    document.querySelector(".nav-tabs").style.display = "none";
    setStatus(STATUS.start);
    $(".nav-tabs").hide();
    $("#time").show();
    $("#demo").hide();
    $("#back").show();
    e.preventDefault();
    /* configureCaptcha(); */
    const phoneNumber = "+91" + mobile;
    // console.log(phoneNumber);

    const item = { phone: mobile };
    axios.post(globeVar + "verify_login", item).then((response) => {
      if (response.data.type === "success") {
        request();
        alert_msg1("success", "OTP Send to your registered Phone No/Email");
      } else {
        setlogin({ mobile: "", otp: "" });

        alert_msg1(response.data.type, response.data.message);
      }
    });
  };

  const [otpError, setotpError] = useState("");
  const onSubmitOTP = (e) => {
    if (otp === "") {
      setotpError("Please enter OTP");
    } else if (otp.length != 6) {
      setotpError("Enter pincode must be 6 digit.");
    } else {
      setfirst_nameError("");
    }

    e.preventDefault();
    const code = otp;
    var email = mobile.includes("@");
    if (email === true) {
      //email code
      const item = { phone: mobile, otp: code };
      if (localStorage.getItem("cart")) {
        const item = { phone: mobile, otp: code };
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            const items = {
              cartId: JSON.parse(localStorage.getItem("cart")),
              userId: response.data.data._id,
            };
            // console.log(items);

            axios
              .post(globeVar + "update_cart_details", items)
              .then((response) => {
                if (response.data.type === "success") {
                  window.location.href = `${path}`;
                }
              });
          }
        });
      } else if (localStorage.getItem("wishlist")) {
        const item = { phone: mobile, otp: code };
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            const items = {
              userId: response.data.data._id,
              productId: JSON.parse(localStorage.getItem("wishlist")),
            };
            // console.log(items);

            axios.post(globeVar + "add_to_wishlist", items).then((response) => {
              if (response.data.type === "success") {
                window.location.href = `${path}`;
              }
            });
          }
        });
      } else {
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));

            window.location.href = `${path}`;
          }
        });
      }
    } else {
      if (localStorage.getItem("cart")) {
        const item = { phone: mobile, otp: code };
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            alert_msg1(response.data.type, response.data.message);
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            const items = {
              cartId: JSON.parse(localStorage.getItem("cart")),
              userId: response.data.data._id,
            };
            // console.log(items);

            axios
              .post(globeVar + "update_cart_details", items)
              .then((response) => {
                if (response.data.type === "success") {
                  window.location.href = `${path}`;
                }
              });
          }
        });
      } else if (localStorage.getItem("wishlist")) {
        const item = { phone: mobile, otp: code };
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            alert_msg1(response.data.type, response.data.message);
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            const items = {
              userId: response.data.data._id,
              productId: JSON.parse(localStorage.getItem("wishlist")),
            };
            // console.log(items);

            axios.post(globeVar + "add_to_wishlist", items).then((response) => {
              if (response.data.type === "success") {
                window.location.href = `${path}`;
              }
            });
          }
        });
      } else {
        const item = { phone: mobile, otp: code };
        axios.post(globeVar + "login", item).then((response) => {
          if (response.data.type === "success") {
            alert_msg1(response.data.type, response.data.message);
            $("#otp-group-id").hide();
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            window.location.href = `${path}`;
          } else {
            alert_msg1(response.data.type, response.data.message);
          }
        });
      }
    }
  };

  const reset = () => {
    setfirst_nameError("");
    setlast_nameError("");
    setemailError("");
    setmobile_noError("");
  };

  function alert_msg(status, msg) {
    //(success,danger)
    var color = "#91EDA0";
    if (status !== "success") {
      status = "danger";
      color = "#f8d7da";
    }
    var html = "";
    html += `<div className="fade alert alert-${status} show" role="alert" style="background-color: ${color}">${msg}</div>`;
    // html += '<div className="alert alert-' + status + ' role="alert">';
    // html +=
    //   '<div className="msg" style="font-size:12px; color:">' + msg + "</div>";
    // html += "</div>";
    $("#alert_msg").css('background-color', color)
    $("#alert_msg").html(html);
    $("#alert_msg").show();
    setTimeout(function () {
      $("#alert_msg").hide();
    }, 5000);
  }

  function alert_msg1(status, msg) {
    //(success,danger)
    var color = "#91EDA0";
    var icon = "notification-off-line";
    var html = "";
    var html = "";
    if(status !== 'success'){
      color = "#f8d7da";
    }
    html += `<div className="fade alert alert-${status} show" role="alert" style="background-color:${color}">${msg}</div>`;
    // html += '<div className="alert alert-' + status + ' role="alert">';
    // html += '<div className="msg" style="font-size:12px">' + msg + "</div>";
    // html += "</div>";
    $("#alert_msg1").css('background-color', color)
    $("#alert_msg1").html(html);
    $("#alert_msg1").show();
    setTimeout(function () {
      $("#alert_msg1").hide();
    }, 5000);
  }
  const [open, setOpen] = useState(false);
  const [test, setTest] = useState("");
  const handleOpen = () => {
    document.body.style.overflow = open ? "auto" : "hidden";
    setOpen((oldState) => !oldState);
    open ? setTest("bgInActive") : setTest("bgActive");
  };
  const chefsSec = (check) => {
    document.body.style.overflow = open ? "auto" : "hidden";
    (check.target.className === 'chefs-sec-main') ? alert(1) : console.log(1);
    //  setOpen((oldState) => !oldState);
    open ? setTest("bgInActive") : setTest("bgActive");
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        // setOpen(false);
        setTest("bgInActive");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  // search mobile functionality start
  const search = document.getElementById("search");
  const secondary = document.getElementById("secondary-header");
  const back2 = document.getElementById("back-arrow");
  var x2 = document.getElementById("myDIV");

  var x3 = document.getElementById("navbarSupportedContent");
  // //Event Listeners
  function searchMobile() {
    secondary.style.visibility = "visible";
    if (x2.style.display === "none") {
      x2.style.display = "block";
    } else {
      x2.style.display = "none";
    }
  }

  function searchBack() {
    secondary.style.visibility = "hidden";
    if (x2.style.display === "none") {
      x2.style.display = "block";
    } else {
      x2.style.display = "none";
    }
  }

  function closeCollapse() {
    location.reload();
  }

  //    search mobile functionality end

  const [chefs, setchefs] = useState([]);
  const [category, setcategory] = useState([]);

  const category_list = () => {
    //api of therapist
    axios.post(globeVar + "category_list").then((response) => {
      if (response.data.type === "success") {
        setcategory(response.data.data);
      }
    });
  };

  const chef_detail = (id) => {
    sessionStorage.setItem("functionality", "Chef");
    sessionStorage.setItem("chef_id", id);
    window.location.href = "/chef_detail";
  };
  const prdBySubCate = (id, cate_id) => {
    sessionStorage.setItem("sub_id", id);
    sessionStorage.setItem("cate_sub_id", cate_id);
    window.location.href = "/product";
  };
  const prdByCate = (id) => {
    sessionStorage.setItem("cate_id", id);
    window.location.href = "/product";
  };
  const prdByfunc = (name) => {
    setActiveNavItem(name);
    sessionStorage.setItem("functionality", name);
    window.location.href = "/product";
  };
  /* const gotoCart = () => {
    window.location.href = "/my-cart";
  }; */
  function request() {
    document.getElementById("otp-group-id").style.display = "block";
    document.getElementById("mail-id").style.display = "none";
  }

  function back() {
    document.getElementById("otp-group-id").style.display = "none";
    document.getElementById("mail-id").style.display = "block";
    setlogin({ mobile: "", otp: "" });
    setStatus(STATUS.pause);
    setMinutes(0);
    setSeconds(59);
  }

  function resend() {
    document.getElementById("success").style.display = "block";
    document.getElementById("mail-id").style.display = "none";
    document.getElementById("otp-group-id").style.display = "none";
  }
  const handleLogout = async (e) => {
    // window.confirm("You want to sure");
    e.preventDefault();
    /* let data = sessionStorage.getItem('user');
    data = JSON.parse(data);
    console.log(data.email); */

    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };
  const chef = {
    responsive: {
      0: {
        items: 1,
      },
      520: {
        items: 2,
      },
      650: {
        items: 3,
      },
      750: {
        items: 4,
      },
      950: {
        items: 5,
      },
      1050: {
        items: 6,
      },
      1200: {
        items: 6,
      },
      1250: {
        items: 7,
      },
      1350: {
        items: 8,
      },
    },
  };

  useEffect(() => {
    axios.post(globeVar + "chef_list").then((response) => {
      if (response.data.type === "success") {
        if(response.data.data.length !== chefs.length){
          setchefs(response.data.data);
        }
      }
    });
    category_list();
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("functionality")) {
      setActiveNavItem(sessionStorage.getItem("functionality"));
    }
  }, []);
  return (
    <div>
      <header className="top-header">
        <div className="first-header">
          <div className="fixed-top">
            <div className="container-xxl">
              <div className="row">
                <div className="col-md-4 col-sm-5 col-4 d-flex align-items-center">
                  <ul className="shop-by-category-dd">
                    <li className="nav-item dropdown d-flex align-items-center sbc-dropdown">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <div className="d-flex">
                          <span className="icon-hamburger me-1  d-block"></span>
                          <span className="sbc-dd-title d-none d-xl-inline-block ">
                            <span className="">SHOP BY </span> CATEGORY
                          </span>
                        </div>
                      </a>
                      <ul className="dropdown-menu sbc-ul-one dropright">
                        {category.map((rex) => {
                          return (
                            <li className="sbc-dd-item" key={rex._id}>
                              <a
                                className="dropdown-item sbc-dd-a"
                                style={{ cursor: "pointer" }}
                                onClick={() => prdByCate(rex._id)}
                              >
                                {" "}
                                {rex.category_name.substring(0, 20) + "..."}
                              </a>
                              <span className="icon-right-arrow"></span>
                              <ul
                                className="submenu dropdown-menu sbc-ul-two"
                                style={{
                                  maxHeight: "50vh",
                                  overflowY: "auto",
                                }}
                              >
                                {rex.sub_data.map((rev) => {
                                  return (
                                    <li className="sbc-dd-item" key={rev._id}>
                                      <a
                                        className="dropdown-item sbc-dd-a2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          prdBySubCate(rev._id, rex._id)
                                        }
                                      >
                                        {rev.subcategory_name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                  <span
                    onClick={myFunction}
                    className=" left-menu-icon d-block d-sm-none"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample1"
                    aria-controls="offcanvasExample1"
                  >
                    <img src={DropdownImage} alt="" />
                  </span>
                  <div
                    className="offcanvas offcanvas-start"
                    tabindex="-1"
                    id="offcanvasExample1"
                    aria-labelledby="offcanvasExample1Label"
                  >
                    <div className="offcanvas-header border-bottom">
                      <h6
                        id="offcanvasExample1Label"
                        className="m-0 ps-3 ps-sm-0"
                      >
                        SHOP BY CATEGORY
                      </h6>
                      <button
                        type="button"
                        onClick={myFunction}
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body p-0">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item border-0">
                          {category.map((rex, i) => {
                            return (
                              <div
                                className="accordion-item Handmade"
                                key={rex._id}
                              >
                                <h2
                                  className="accordion-header"
                                  id={"heading_" + i + 1}
                                >
                                  <button
                                    className={
                                      "collapsed accordion-button arrow_" +
                                      rex._id
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#collapse_" + i + 1}
                                    aria-expanded="false"
                                    aria-controls={"collapse_" + i + 1}
                                  >
                                    {rex.category_name}
                                  </button>
                                </h2>
                                <div
                                  id={"collapse_" + i + 1}
                                  className={
                                    "accordion-collapse collapse cate_id_" +
                                    rex._id
                                  }
                                  aria-labelledby={"heading_" + i + 1}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <ul className="list-group">
                                      {rex.sub_data.map((rev) => {
                                        return (
                                          <li
                                            className="list-group-item"
                                            key={rev._id}
                                          >
                                            <a
                                              className="dropdown-item sbc-dd-a2"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                prdBySubCate(rev._id, rex._id)
                                              }
                                            >
                                              {rev.subcategory_name}
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-box-main">
                    <form
                      action=""
                      onSubmit={(e) => handelSubmit(e)}
                      method="POST"
                    >
                      <div className="search-box">
                        <input
                          type="text"
                          className="search-input"
                          placeholder="Search Honey,Pickles..."
                          id="search"
                          name="search1"
                          onChange={(e) => handelInput(e)}
                          required
                          style={{ color: "black" }}
                        />
                        <button className="btn btn-search p-0" type="submit">
                          <a className="search-btn">
                            <img src={SearchIcon}></img>
                          </a>
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="container-primary">
                    <div className="primary">
                      <i className="material-icons search2" onClick={searchMobile}>
                        {" "}
                        <img className="img-fluid" src={SearchIconBlack}></img>
                      </i>
                    </div>
                    <div className="secondary" id="secondary-header">
                      <ul>
                        <li>
                          <i
                            className="material-icons back"
                            onClick={searchBack}
                            id="back-arrow"
                          >
                            <img src={leftArrow}></img>
                          </i>
                        </li>
                        <li>
                          <form
                            className="form2"
                            onSubmit={(e) => handelSubmit(e)}
                          >
                            <input
                              id="search1"
                              className="input-search"
                              type="text"
                              placeholder="Search Honey,Pickles..."
                              name="search1"
                              onChange={(e) => handelInput(e)}
                              required
                              style={{ color: "black" }}
                            />
                            <button className="btn btn-search" type="submit">
                              <i
                                className="material-icons search-btn2"
                                id="search1"
                              >
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={SearchIconBlack}
                                ></img>
                              </i>
                            </button>
                          </form>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex d-md-block justify-content-center col-sm-2 col-3">
                  <a href="/">
                    <div className="newest-logo d-none d-md-block">
                      <div className="logo">
                        <img src={logo} />
                      </div>
                      <div className="logo-bottom">
                        <img src={top_nav_bottom} />
                      </div>
                    </div>
                  </a>
                  <a href="/">
                    <div className="logo d-md-none mt-sm-2 d-block">
                      <img src={newlogo} className="logo-image-2  img-fluid" />
                    </div>
                  </a>
                </div>
                <div className="col-md-4  col-5">
                  <div className="login-button-right">
                    <ul className="list-inline list-unstyled">
                      {user ? (
                        <li className="list-inline-item position-relative cart-button ms-0">
                          <div className="dropdown">
                            <div
                              className="welcome btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <span className="d-inline-block d-lg-none">
                                <img src={UserImage} alt="" />
                              </span>
                              <span className="d-none d-lg-inline-block">
                                Welcome :{" "}
                                {/* <span onClick={() => navigate("/dashboard")}> */}
                                {user.first_name}
                                {/* </span> */}
                              </span>
                            </div>
                            <ul className="dropdown-menu profile-logout-dd">
                              <li>
                                <Link className="dropdown-item" to="/dashboard">
                                  My Profile
                                </Link>
                              </li>
                              {/* <li><a className="dropdown-item" onClick={handleLogout} >Log Out</a></li> */}
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={handleShow}
                                >
                                  Log Out
                                </a>
                              </li>
                              <Modal show={show} onHide={handleClose}>
                                <div className="profile bg-white order-detail rounded">
                                  <div className="are-youlog-out">
                                    <img src={LogoutImg} />
                                    <h4 className="mt-3 text-center">
                                      Are you sure? you really want to logout!!
                                    </h4>
                                    <div className="row  justify-content-center pt-4 pb-2">
                                      <div className="col-lg-4 mb-2 mb-lg-0">
                                        <button
                                          className="primary-btn d-inline-block"
                                          onClick={handleClose}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                      <div className="col-lg-4">
                                        <button
                                          className="primary-btn d-inline-block"
                                          onClick={handleLogout}
                                        >
                                          Log Out
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                            </ul>
                          </div>
                        </li>
                      ) : (
                        <li
                          className="list-inline-item"
                          data-bs-toggle="modal"
                          href="#exampleModalToggle"
                          role="button"
                        >
                          <span className="icon-login me-1"></span>
                          <span
                            className="login-cart-hide"
                            onClick={() => reset()}
                          >
                            Log In
                          </span>
                        </li>
                      )}
                      <li className="list-inline-item position-relative cart-button">
                        {/* <div
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/my-cart")}
                        >
                          <span className="cart-section icon-cart">
                            {productCart.length === 0 ? (
                              ""
                            ) : (
                              <span className="quantity">
                                {productCart.length}
                              </span>
                            )}
                          </span>
                          <span className="login-cart-hide">Cart</span>
                        </div> */}
                        <HeaderCart />
                      </li>
                    </ul>
                  </div>
                  <nav className="navbar d-sm-none navbar-expand-xl navbar-light d-flex flex-column bg-dark">
                    <div className="container-fluid bg-dark">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id="myDIV"
                      >
                        <img src={hamburger} alt="" />
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul
                          className="header navbar-nav d-flex justify-content-between w-100 mb-2 mb-lg-0"
                          id="navMenus"
                        >
                          <div className="d-flex">
                            <li
                              className={`nav-item ${test} ${
                                activeNavItem === "Chef" ? "active" : ""
                              }`}
                              ref={ref}
                            >
                              <button
                                className="nav-link your-home"
                                href="#"
                                id="RootNode"
                                onClick={handleOpen}
                              >
                                Your Home Chefs
                              </button>
                              {open ? (
                                <div
                                  className="chefs-sec-main-bar"
                                    onClick={(e) => chefsSec(e)}
                                >
                                  <div className="chefs-sec-main"
                                    onClick={(e) => chefsSec(e)}
                                  >
                                    <ul className="chefs-sec w-100"
                                      // onClick={() => chefsSec(false)}
                                    >
                                      <div className="swiper mySwiper-two">
                                        <div className="swiper-wrapper d-flex">
                                          <OwlCarousel
                                            items={8}
                                            {...chef}
                                            margin={5}
                                          >
                                            {chefs.map((data) => {
                                              return (
                                                <div
                                                  className="swiper-slide"
                                                  key={data._id}
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={() =>
                                                      chef_detail(data._id)
                                                    }
                                                  >
                                                    <div className="card">
                                                      <div className=" text-center">
                                                        <img
                                                          src={data.avatar}
                                                          className="card-img-top rounded-circle"
                                                          alt="..."
                                                        />
                                                      </div>
                                                      <div className="card-body text-center">
                                                        <h5 className="card-title">
                                                          {data.title}
                                                        </h5>
                                                        <p className="card-text">
                                                          {data.sub_title}{" "}
                                                          <br /> Maker
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Link>
                                                </div>
                                              );
                                            })}
                                          </OwlCarousel>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              ) : null}
                              {/* {open ? <div>Is Open</div> : <div>Is Closed</div>} */}
                            </li>
                            <li
                              className={`nav-item ${
                                activeNavItem === "Trending" ? "active" : ""
                              }`}
                            >
                              <a
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => prdByfunc("Trending")}
                              >
                                Trending
                              </a>
                            </li>
                            <li
                              className={`nav-item ${
                                activeNavItem === "Subscriptions"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <a
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => prdByfunc("Subscriptions")}
                              >
                                Subscriptions
                              </a>
                            </li>
                            <li
                              className={`nav-item ${
                                activeNavItem === "Combos" ? "active" : ""
                              }`}
                            >
                              <a
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => prdByfunc("Combos")}
                              >
                                Combos
                              </a>
                            </li>
                          </div>
                          <div className="d-flex">
                            <li
                              className={`nav-item ${
                                activeNavItem === "Offers" ? "active" : ""
                              }`}
                              onClick={() => setActiveNavItem("Offers")}
                            >
                              <Link
                                className="nav-link"
                                to={`/offers`}
                                onClick={closeCollapse}
                              >
                                Offers
                              </Link>
                            </li>
                            <li
                              id
                              className={`nav-item ${
                                activeNavItem === "Events" ? "active" : ""
                              }`}
                              onClick={() => setActiveNavItem("Events")}
                            >
                              <Link
                                className="nav-link"
                                aria-current="page"
                                to={"/event"}
                                onClick={closeCollapse}
                              >
                                Events
                              </Link>
                            </li>
                            <li
                              className={`nav-item ${
                                activeNavItem === "About Us" ? "active" : ""
                              }`}
                              onClick={() => setActiveNavItem("About Us")}
                            >
                              <Link
                                className="nav-link"
                                to={`/about_us`}
                                onClick={closeCollapse}
                              >
                                About Us
                              </Link>
                            </li>
                            <li
                              className={`nav-item ${
                                activeNavItem === "Corporate AV" ? "active" : ""
                              }`}
                              onClick={() => setActiveNavItem("Corporate AV")}
                            >
                              <Link
                                className="nav-link"
                                to={`/corporateAV`}
                                onClick={closeCollapse}
                              >
                                Corporate AV
                              </Link>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade modal-size"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content login-modal-resize">
              <div className="d-md-flex login-popup justify-content-lg-end">
                <div className="col-12 col-md-6 right-side d-flex flex-column ">
                  <ul className="nav d-flex nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        Log In
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        Register
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <div className="enter-email" id="mail-id">
                        <form onSubmit={(e) => onSignInSubmit(e)}>
                          <div id="sign-in-button"></div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control log-in-input input"
                              name="mobile"
                              min={0}
                              value={mobile}
                              onChange={(e) => handleChanges(e)}
                              id="emailpass"
                              placeholder="Enter email or mobile number"
                            />
                            <p className="error_new"></p>

                            <p className="m-0">
                              By continuing, you agree to iCottage Cart's{" "}
                              <a
                                href="/term_condition"
                                className="text-decoration-none"
                              >
                                Terms of Use
                              </a>{" "}
                              and{" "}
                              <a
                                href="/privacy"
                                className="text-decoration-none"
                              >
                                Privacy Policy.
                              </a>
                            </p>

                            <button
                              className="btn mt-2"
                              type="submit"
                              onClick={start}
                            >
                              Request OTP
                            </button>
                          </div>
                        </form>
                      </div>
                      <div
                        className="col-12 me-3 mt-3 alert"
                        id="alert_msg1"
                        style={{ display: "none" }}
                      ></div>
                      <div className="enter-email" id="mail-id"></div>
                      <div className="otp-group" id="otp-group-id">
                        <form onSubmit={(e) => onSubmitOTP(e)}>
                          <div className="form-group-otp mt-3">
                            <h5>OTP Verification</h5>
                            <p>
                              Enter OTP Received on your email/mobile number
                            </p>
                            <div
                              className="input-field d-flex otpnumberhide"
                              id="otpnumberhide"
                            >
                              <input
                                type="number"
                                name="otp"
                                id="otp"
                                className="otps"
                                min="0"
                                style={{ width: "15em" }}
                                placeholder="OTP Number"
                                onChange={(e) => handleChanges(e)}
                                value={otp}
                              />
                            </div>

                            <h6 className="mt-3" id="time">
                              Resend OTP : {timerMinutes}:{timerSeconds}
                            </h6>
                            <span className="address_form_error">
                              {otpError}
                            </span>
                            <br />
                            <br />
                            <button
                              type="button"
                              className="resend-otp-back mt-2"
                              id="demo"
                              onClick={(e) => onSignInSubmit(e)}
                              style={{ display: "none" }}
                            >
                              Resend OTP
                            </button>
                          </div>
                          <div className="resend-otp d-flex justify-content-between mt-5">
                            <button
                              type="button"
                              className="resend-otp-back mt-2"
                              id="back"
                              onClick={() => back()}
                            >
                              Back
                            </button>

                            <button
                              className="resend-otp-continue mt-2"
                              type="submit"
                            >
                              Continue
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="form-group-success" id="success">
                        <span className="icon-right-arrow"></span>
                        <h4 className="mt-3">Success</h4>
                        <h6 className="mt-3">
                          Congratulation, you log in successfully.
                        </h6>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <div className="col-12 d-flex flex-column justify-content-between">
                        <div
                          className="col-12 me-3 mt-3 alert"
                          id="alert_msg"
                          style={{ display: "none" }}
                        ></div>
                        <div className="enter-email" id="mail-id-signup">
                          <form onSubmit={(e) => onSignupSubmit(e)}>
                            <div id="sign-in-button"></div>
                            <div className="form-group">
                              <div className="row g-3 pt-3">
                                <div className="col-6">
                                  <label
                                    htmlFor="first_name"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input"
                                    name="first_name"
                                    id="first_name"
                                    onChange={(e) => handle_signup(e)}
                                    value={first_name}
                                    placeholder="First Name"
                                  />
                                  <span className="address_form_error">
                                    {first_nameError}
                                  </span>
                                </div>
                                <div className="col-6">
                                  <label
                                    htmlFor="last_name"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input"
                                    name="last_name"
                                    id="last_name"
                                    onChange={(e) => handle_signup(e)}
                                    value={last_name}
                                    placeholder="Last Name"
                                  />
                                  <span className="address_form_error">
                                    {last_nameError}
                                  </span>
                                </div>

                                <div className="col-12">
                                  <label htmlFor="email" className="form-label">
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input"
                                    name="email"
                                    id="email"
                                    onChange={(e) => handle_signup(e)}
                                    value={email}
                                    placeholder="Email Address"
                                  />
                                  <span className="address_form_error">
                                    {emailError}
                                  </span>
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="mobile_no"
                                    className="form-label"
                                  >
                                    Mobile Number
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control input"
                                    name="mobile_no"
                                    id="mobile_no"
                                    min={0}
                                    onChange={(e) => handle_signup(e)}
                                    value={mobile_no}
                                    placeholder="Mobile Number"
                                  />
                                  <span className="address_form_error">
                                    {mobile_noError}
                                  </span>
                                </div>
                                <div className="col-12">
                                  <p className="m-0">
                                    By continuing, you agree to iCottage Cart's{" "}
                                    <a
                                      href="/term_condition"
                                      className="text-decoration-none"
                                    >
                                      Terms of Use
                                    </a>{" "}
                                    and{" "}
                                    <a
                                      href="/privacy"
                                      className="text-decoration-none"
                                    >
                                      Privacy Policy.
                                    </a>
                                  </p>
                                  <button className="btn mt-2" type="submit">
                                    Create account
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade modal-size"
          id="exampleModalToggle1"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content login-modal-resize">
              <div className="d-md-flex login-popup">
                <div className="col-12 col-md-4 left-side d-flex flex-md-column justify-content-between">
                  <div>
                    <h4>Registration</h4>
                    <p>
                      Get access to your Orders, Wishlist and Recommendations
                    </p>
                  </div>
                  <div className="text-center">
                    <img src={purchase} style={{ width: "100px" }} alt="" />
                  </div>
                </div>
                <div className="col-12 col-md-8 right-side d-flex flex-column justify-content-between">
                  <div
                    className="col-12 me-3 mt-3"
                    id="alert_msg"
                    style={{ display: "none" }}
                  ></div>
                  <div className="enter-email" id="mail-id-signup">
                    <form onSubmit={(e) => onSignupSubmit(e)}>
                      <div id="sign-in-button"></div>
                      <div className="form-group">
                        <div className="row g-3">
                          <div className="col-md-12">
                            <label htmlFor="first_name" className="form-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control input"
                              name="first_name"
                              id="first_name"
                              onChange={(e) => handle_signup(e)}
                              value={first_name}
                              placeholder="First Name"
                            />
                            <span className="address_form_error">
                              {first_nameError}
                            </span>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="last_name" className="form-label">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control input"
                              name="last_name"
                              id="last_name"
                              onChange={(e) => handle_signup(e)}
                              value={last_name}
                              placeholder="Last Name"
                            />
                            <span className="address_form_error">
                              {last_nameError}
                            </span>
                          </div>

                          <div className="col-12">
                            <label htmlFor="email" className="form-label">
                              Email Address
                            </label>
                            <input
                              type="text"
                              className="form-control input"
                              name="email"
                              id="email"
                              onChange={(e) => handle_signup(e)}
                              value={email}
                              placeholder="Email Address"
                            />
                            <span className="address_form_error">
                              {emailError}
                            </span>
                          </div>
                          <div className="col-12">
                            <label htmlFor="mobile_no" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="number"
                              className="form-control input"
                              name="mobile_no"
                              id="mobile_no"
                              min={0}
                              onChange={(e) => handle_signup(e)}
                              value={mobile_no}
                              placeholder="Mobile Number"
                            />
                            <span className="address_form_error">
                              {mobile_noError}
                            </span>
                          </div>
                          <div className="col-12">
                            <p className="m-0">
                              By continuing, you agree to Cottage Cart's{" "}
                              <a
                                href="/term_condition"
                                className="text-decoration-none"
                              >
                                Terms of Use
                              </a>{" "}
                              and{" "}
                              <a
                                href="/privacy"
                                className="text-decoration-none"
                              >
                                Privacy Policy.
                              </a>
                            </p>
                            <button className="btn mt-2" type="submit">
                              Create account
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="toftr">
                      <p className="mb-0">
                        Already have an account?{" "}
                        <Link
                          className="list-inline-item"
                          data-bs-toggle="modal"
                          to="#exampleModalToggle"
                          role="button"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav className="navbar d-none d-sm-block navbar-expand-xl navbar-light d-flex flex-column bg-dark">
        <div className="container-xxl bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="myDIV"
          >
            <img src={hamburger} alt="" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="header navbar-nav d-flex justify-content-between w-100 mb-2 mb-lg-0"
              id="navMenus"
            >
              <div className="d-flex">
                <li
                  className={`nav-item ${test} ${
                    activeNavItem === "Chef" ? "active" : ""
                  }`}
                  ref={ref}
                >
                  <button
                    className="nav-link your-home"
                    href="#"
                    id="RootNode"
                    onClick={handleOpen}
                  >
                    Your Home Chefs
                  </button>
                  {open ? (
                    <div
                      className="chefs-sec-main-bar position-relative"
                      onClick={chefsSec}
                    >
                      <div className="chefs-sec-main">
                        <ul className="chefs-sec w-100">
                          <div className="container-xxl">
                            <div className="swiper mySwiper-two">
                              <div className="swiper-wrapper d-flex">
                                <OwlCarousel items={8} {...chef} margin={5}>
                                  {chefs.map((data) => {
                                    return (
                                      <div
                                        className="swiper-slide"
                                        key={data._id}
                                      >
                                        <Link
                                          to="/chef_detail"
                                          onClick={() => chef_detail(data._id)}
                                        >
                                          <div className="card">
                                            <div className=" text-center">
                                              <img
                                                src={data.avatar}
                                                className="card-img-top rounded-circle"
                                                alt="..."
                                              />
                                            </div>
                                            <div className="card-body text-center">
                                              <h5 className="card-title">
                                                {data.title}
                                              </h5>
                                              <p className="card-text">
                                                {data.sub_title} <br /> Maker
                                              </p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    );
                                  })}
                                </OwlCarousel>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {/* {open ? <div>Is Open</div> : <div>Is Closed</div>} */}
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "Trending" ? "active" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => prdByfunc("Trending")}
                  >
                    Trending
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "Subscriptions" ? "active" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => prdByfunc("Subscriptions")}
                  >
                    Subscriptions
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "Combos" ? "active" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => prdByfunc("Combos")}
                  >
                    Combos
                  </a>
                </li>
              </div>
              <div className="d-flex">
                <li
                  className={`nav-item ${
                    activeNavItem === "Offers" ? "active" : ""
                  }`}
                  onClick={() => setActiveNavItem("Offers")}
                >
                  <Link className="nav-link" to={`/offers`}>
                    Offers
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "Events" ? "active" : ""
                  }`}
                  onClick={() => setActiveNavItem("Events")}
                >
                  <Link className="nav-link" aria-current="page" to={"/event"}>
                    Events
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "About Us" ? "active" : ""
                  }`}
                  onClick={() => setActiveNavItem("About Us")}
                >
                  <Link className="nav-link" to={`/about_us`}>
                    About Us
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeNavItem === "Corporate AV" ? "active" : ""
                  }`}
                  onClick={() => setActiveNavItem("Corporate AV")}
                >
                  <Link className="nav-link" to={`/corporateAV`}>
                    Corporate AV
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      
    </div>
  );
};

export default Header;
