import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GooglePlay from "../../assets/img/homepage/GooglePlay.png";
import AppStore from "../../assets/img/homepage/AppStore.png";
import facebook from "../../assets/img/logos/Facebook.svg";
import youtube from "../../assets/img/logos/YouTube.svg";
import instagram from "../../assets/img/logos/Instagram.svg";
import whatsapp from "../../assets/img/logos/WhatsApp.svg";
import Bulding from "../../assets/img/bulding.svg";
import Email from "../../assets/img/email.svg";
import Phone from "../../assets/img/call.svg";
import axios from "axios";
import globeVar from "../../GlobeApi";
import PaymentsMethod from "../../assets/img/homepage/PaymentsMethod.svg";
const Footer = () => {
  window.scrollTo(0, 0);
  const [contactus, setcontactus] = useState([]);

  const getApp = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.cottagecart";
  }

  function openRetailerLink() {
    window.open('https://app.cottage-cart.com/retailer', '_blank');
  }
  
  useEffect(() => {
    axios.post(globeVar + "contactus").then((response) => {
      if (response.data.type === "success") {
        /* console.log(response.data.data) */
        setcontactus(response.data.data);
      }
    });
  }, []);
  return (
    <footer id="footer-menu">
      <div className="container container-el">
        <div className="row justify-content-between footer-row-1">
          <div className="col-lg-4 col-md-6">
            <p className="footer-menu-title">DOWNLOAD THE APP</p>
            <p className="mb-1 footer-menu-item">Get the download link</p>
            <div className="row input-row">
              <div className="col-9 p-0">
                <input type="tel" placeholder="Enter your mobile number" />
              </div>
              <div className="col-3 p-0">
                <button type="button" className="btn btn-primary">
                  Send
                </button>
              </div>
            </div>
            <p className="mb-0 footer-menu-item">Get it on</p>
            <div className="img-div">
              <img src={GooglePlay} alt="" onClick={getApp} />
              <img src={AppStore} alt="" />
              <button type="button" className="btn btn-primary w-auto m-auto" onClick={openRetailerLink}>Become a Retailer</button>
            </div>
          </div>
          <div className="col-lg-8 pt-md-5 pt-lg-0 col-md-12 footer-menu-second">
            <div className="row">
              <div className="col-sm-4 col-lg-3 col-6">
                <p className="footer-menu-title">ABOUT US</p>
                <ul>
                  <li>
                    <Link to={`/about_us`}>About Us</Link>
                  </li>
                  {/* <li><Link to={`/mission_vision`}>Mission and Vision</Link></li> */}
                  <li>
                    <Link to={`/faq`}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={`/blog`}>Blog</Link>
                  </li>
                  <li>
                    <a
                      href="https://app.cottage-cart.com/signup"
                      target="_blank"
                    >
                      Partner With Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 col-lg-3 col-6">
                <p className="footer-menu-title">OUR POLICIES</p>
                <ul>
                  <li>
                    <Link to={`/term_condition`}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to={`/privacy`}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={`/return_policy`}>Refund and Return</Link>
                  </li>
                  <li>
                    <Link to={`/shipping_policy`}>Shipping Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 col-lg-6 col-12 pt-3 pt-sm-0">
                <p className="footer-menu-title">CONTACT US</p>
                <ul>
                  <div className="row">
                    <div className="col-1">
                      <img src={Bulding} alt="" />
                    </div>
                    <div className="col-10">{contactus.address}</div>
                  </div>
                </ul>
                <ul>
                  <div className="row">
                    <div className="col-1">
                      <img src={Phone} alt="" />
                    </div>
                    <div className="col-10">{contactus.phone}</div>
                  </div>
                </ul>
                <ul>
                  <div className="row">
                    <div className="col-1">
                      <img src={Email} alt="" />
                    </div>
                    <div className="col-10">{contactus.email}</div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-between footer-row-2">
          <div className="col-lg-8 d-block d-sm-flex align-items-center px-0">
            <p className="m-0 payment-para text-center text-sm-start">
              Payment Method
            </p>
            <div className="payment-imgs ms-2">
              <img src={PaymentsMethod} alt="" />
            </div>
          </div>
          <div className="col-lg-3 d-block d-sm-flex  justify-content-center align-items-center justify-content-sm-end">
            <p className="m-0  follow-para text-center text-sm-start">Follow Us</p>
            <div className="social-icons ms-2 text-center text-sm-start">
              <a href="https://www.facebook.com/icottagecart">
                <img src={facebook} alt="" />
              </a>
              <a href="https://www.youtube.com/@iCottageCart">
                <img src={youtube} alt="" />
              </a>
              <a href="https://www.instagram.com/icottagecart/">
                <img src={instagram} alt="" />
              </a>
              {/* <a href='https://api.whatsapp.com/send/?phone=919096099303&text=Hi'><img src={whatsapp} alt="" /></a> */}
            </div>
          </div>
        </div>
        <div className="row footer-row-3">
          <div className="copyright">
            <div className="row justify-content-center copys">
              <div className="col-md-6 copyright-first">
                <p className="text-left">
                  <span>&#169;</span>2023 iCottage Cart Limited.All rights
                  reserved.
                </p>
              </div>
              <div className="col-md-6 copyright-second">
                <p className="text-lg-right">
                  FSSAI License Number - 21523037001481
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
