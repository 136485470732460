import React, { useState, useEffect } from 'react'
import underline from '../../assets/img/Group 444.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import globeVar from '../../GlobeApi';
import ScrollToTop from './ScrollToTop';
import half_circle from '../../assets/img/Ellipse 416.svg'
import visionBubble from '../../assets/img/AboutUs_Page/bubble1.png'
import missionBubble from '../../assets/img/AboutUs_Page/bubble2.png'
import coretopicsBubble from '../../assets/img/AboutUs_Page/bubble3.png'
import Bullet from '../../assets/img/AboutUs_Page/bullet.png'
import OurVisiion from "../../assets/img/Our-Vision-Banner.jpg"
import OurConeValues from "../../assets/img/Our-Core-Values-Banner-2.jpg"
function About_Us() {
  const [about_us, setabout_us] = useState({})
  const [director, setdirector] = useState([])
  const [team, setteam] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post(globeVar + "aboutus_list").then((response) => {
      console.log(response);
      if (response.data.type === "success") {
        setabout_us(response.data.data);
        setdirector(response.data.dir_data);
        setteam(response.data.team_data);

      }
    })
  }, [])
  return (
    <div>
      <ScrollToTop />
      <section className="privacy-banner" style={{backgroundImage:`url(${about_us.aboutus_banner_img})`,backgroundPosition: 'right top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <h1 className="text-center"></h1>
        {/* {about_us.aboutus_banner_title} */}
          {/* <ul className="privacy-path">
            <li><Link to={`/`}>Home</Link></li>
            <li> {`>`} </li>
            <li>{about_us.aboutus_banner_title}</li>
          </ul> */}
      </section>
      <section className="aboutUs-page-section">
        <div className="container">
          <h2 className='text-center'>{about_us.aboutus_title}</h2>
          <div className="product-headeing-section our-vision-underline mb-5">
            <h5>Our Vision</h5>
            <img src={underline} alt="" />
          </div>
          {/* <div className="vsn-msn-ct-bnr mb-5 d-none d-lg-block">
            <div className="vmcImg">
              <div className="vsn-bbl-img">
                <img src={visionBubble} alt="" />
              </div>
              <img src={about_us.vision_gif} alt="" className='vsn-gif-img' />
              <p className='vsn-para' dangerouslySetInnerHTML={{ __html: about_us.vision }}></p>
              <div className="msn-bbl-img">
                <img src={missionBubble} alt="" />
              </div>
              <img src={about_us.mission_gif} alt="" className='msn-gif-img' />
              <p className='msn-para' dangerouslySetInnerHTML={{ __html: about_us.mission }}></p>
              <div className="ct-bbl-img">
                <img src={coretopicsBubble} alt="" />
              </div>
              <img src={about_us.core_topics_gif} alt="" className='ct-gif-img' />
              <ul className="ul-left">
                <li><img src={Bullet} alt="" /> {about_us.core_topics1}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics2}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics3}</li>
              </ul>
              <ul className="ul-right">
                <li><img src={Bullet} alt="" /> {about_us.core_topics4}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics5}</li>
                <li><img src={Bullet} alt="" /> {about_us.core_topics6}</li>
              </ul>
            </div>
          </div> */}
          
          <img className="w-100" src={OurVisiion} alt=""/>
        </div>
      </section>
      <section className="aboutUs-page-section">
        <div className="container">
          <h2 className='text-center'>{about_us.aboutus_title}</h2>
          <div className="product-headeing-section our-cone-vision-underline mb-5">
            <h5>Our Core Values</h5>
            <img src={underline} alt="" />
          </div>
          <img className="w-100" src={OurConeValues} alt=""/>
        </div>
      </section>
      <div className="about-story-section">
        <div className="container container-el">
          <div className="product-headeing-section our-vision-underline">
            <h5>Our Story</h5>
            <img src={underline} alt="" />
          </div>
          <p dangerouslySetInnerHTML={{ __html: about_us.story }}></p>

        </div>
      </div>
      <div className="about-leaders-section my-5">
        <div className="container container-el">
          <div className="product-headeing-section leader-underline">
            <h5>Meet Our Leaders</h5>
            <img src={underline} alt="" />
          </div>
          <div className="row meet-ldrs-row">
            {director.map((data, i) => {
              return <div className="col-md-6 abt-ldr-cntnt" key={data._id} style={{height: "fit-content"}}>
                <div className="ldr-vdo-1">
                  <video src={data.director_video}
                    loop
                    muted
                    autoPlay
                    controls
                  ></video>
                </div>
                <div className="abt-ldr-detail">
                  {/* <h6>Director {i + 1}</h6> */}
                  <h3>{data.director_name}</h3>
                  { data.director_about && <p dangerouslySetInnerHTML={{ __html: data.director_about }}></p> }
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
      <div className="about-leaders-section my-5">
        <div className="container container-el">
          <div className="product-headeing-section leader-underline">
            <h5>Meet Our Team</h5>
            <img src={underline} alt="" />
          </div>

          <div className="row  meet-ldrs-row">
            {team.map((team) => {
              return (
                <div className="col-sm-6 d-flex justify-content-center justify-content-md-start col-md-4 col-lg-3 abt-ldr-cntnt containerdiv1" key={team._id}>
                  <div className="card border-0">
                    <div>
                      <img src={half_circle} alt="" className='halfcircle1' />
                      <img src={team.team_img} className="card-img-top rounded-circle cornerimage1" alt="..." />
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">{team.team_name}</h5>
                      { team.team_designation && <p className="text-center card-text">{team.team_designation}</p> }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default About_Us
