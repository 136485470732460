import React, { useState, useEffect } from 'react'
import './SuccessPayment.css';
import { BsCheckCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/img/Final-Logo-success.png'
import Check from '../../assets/img/check-1.png'
import axios from 'axios';
import globeVar from '../../GlobeApi';

const SuccessPayment = (response) => {
   const { order_id } = useParams();
   const [orderData, setOrderData] = useState([]);
   useEffect(() => {
      axios.get(`${globeVar}/order_by_ID/${order_id}`).then((response) => {
         console.log(response.data);
         setOrderData(response.data.data[0]);
      }).catch((error) => {
         console.log(error);
      });
   }, [order_id]);

   /*  const [user, setUser] = useState({
       name: "",
       email:""
    })
    const {name,email} = user;
    const onInputChange = e => {
       setUser({...user,[e.target.name]: e.target.value})
   };
 
    const onSubmit = async e => {
       console.log(user);
   } */
   /* function handleClick(e){
         e.preventDefault();
         let data = {name: "stepOne", values: [this.state.user]};
         this.context.router.push("/registration/step-two");
    } */


   /*  
    axios(config)
   .then(function (response) {
     console.log(JSON.stringify(response.data));
     console.log(response);
     
   .catch(function (error) {
     console.log(error);
   });  
    */
   const navigate = useNavigate();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [])
   return (
      <div>
         <div className="container">
            <div className="row">
               <div className="col-md-12 mx-auto mt-5">
                  <div className="payment_successful">
                     <div className='row justify-content-center'>
                         <div className='col-lg-3'>
                         
                           </div>
                     </div>
                     <div>
                         <img src={Logo} className='img-fluid d-block mx-auto' alt='' />
                     </div>
                     <div className='payment_header mb-5'>
                        <img className="img-fluid" src={Check} alt=''/>
                        <h5 className='py-4'>Your Order Has been Placed.</h5>         
                     </div>
                     {/* <div className="payment_header">
                   
                        <div className="check"><BsCheckCircleFill className='icons' /></div>
                     </div> */}
                     <div className="content">
                       <h5>Hello, {orderData?.customer_name }</h5>
                       <p>Your Order has been confirmed and will be shipping soon.</p>
                       <hr/>
                       <h5>Order Details</h5>
                       <hr/>
                       <div className='row py-2'>
                           <div className='col-lg-4'>
                              <p className='mb-2'>Order Number</p>
                              <p><b>{orderData?.order_id}</b></p>
                           </div>
                           <div className='col-lg-4'>
                              <p className='mb-2'>Order Date</p>
                              <p><b>{orderData?.order_date }</b></p>
                           </div>
                           <div className='col-lg-4'>
                              <p className='mb-2'>Payment Method</p>
                              <p><b>{orderData?.order_payment_mode }</b></p>
                           </div>
                           <div className='col-lg-4'>
                              <p className='mb-2'>Contact Info</p>
                              <p><b>{orderData?.customer_email }</b></p>
                           </div>
                           <div className='col-lg-4'>
                              <p className='mb-2'>Shipping Address</p>
                              <p><b>{orderData?.customer_shipping }</b></p>
                           </div>
                       </div>
                       <hr/>
                       <div className='row py-2'>
                           {/* <div className='col-8'>
                              <p className='mb-2'>Product Details</p>
                              {orderData?.order_products?.map((item, index) => {
                                 return (
                                    <p><b>{item.product_name}</b></p>
                                 )
                              })}
                              <p><b>Sweet Mango Pickle 400 gm</b></p>
                           </div>
                           <div className='col-2'>
                              <p className='mb-2'>Quantity</p>
                              <p><b>1 unit</b></p>
                              {orderData?.order_products?.map((item, index) => {
                                 return (
                                    <p><b>{item.quantity} unit</b></p>
                                 )
                              })}
                           </div>
                           <div className='col-2 text-align-right'>
                              <p className='mb-2'>Amount</p>
                              <p><b>₹224.1</b></p>
                              {orderData?.order_products?.map((item, index) => {
                                 return (
                                    <p><b>{item.amount}</b></p>
                                 )
                              })}
                           </div> */}
                           <div className='table-responsive'>
                              <table className='table borderless'>
                                 <thead>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                       <td className='col-6'>Product Details</td>
                                       <td className='col-2'>Quantity</td>
                                       <td className='col-2 text-right'>Amount</td>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {orderData?.order_products?.map((item, index) => {
                                    return (
                                       <tr style={{borderStyle : "none", borderColor : 'white'}} key={index}>
                                          <td className='col-6'><b>{item.product_name}</b></td>
                                          <td className='col-2'><b>{item.quantity} unit</b></td>
                                          <td className='col-2 text-right'><b>{item.amount}</b></td>
                                       </tr>
                                    )
                                    })}
                                 </tbody>
                              </table>
                              </div>
                       </div>
                       <hr/>
                       <div className='row justify-content-end'>
                           {/* <div className='col-lg-2'>
                              <p >Subtotal:</p>
                              <p >Shipping charges:</p>
                              <p >Discount:</p>
                              <p >Saving:</p>
                           </div>
                           <div className='col-lg-4 text-align-right'>
                              <p><b>₹{parseFloat(orderData?.order_products?.reduce((total, obj) => parseInt(obj.amount) + parseInt(total),0)).toFixed(2)}</b></p>
                              <p><b>₹40.00</b></p>
                              <p><b>₹{orderData?.order_discount ?? "00.00"}</b></p>
                              <p><b>₹{orderData?.order_saving ?? "00.00"}</b></p>
                           </div> */}
                           <div className='table-responsive'>
                              <table className='table borderless'>
                                 <tbody>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                    <td className='text-right' style={{width : "77%", textAlign: "end"}}>Subtotal:</td>
                                    <td className='text-right' style={{paddingLeft: "24px"}}><b>₹{parseFloat(orderData?.order_products?.reduce((total, obj) => parseInt(obj.amount) + parseInt(total),0)).toFixed(2)}</b></td>
                                    </tr>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                    <td className='text-right' style={{width : "77%", textAlign: "end"}}>Shipping charges:</td>
                                    <td className='text-right' style={{paddingLeft: "24px"}}><b>₹40.00</b></td>
                                    </tr>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                    <td className='text-right' style={{width : "77%", textAlign: "end"}}>Discount:</td>
                                    <td className='text-right' style={{paddingLeft: "24px"}}><b>- ₹{orderData?.order_discount ?? "00.00"}</b></td>
                                    </tr>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                    <td className='text-right' style={{width : "77%", textAlign: "end"}}>Saving:</td>
                                    <td className='text-right' style={{paddingLeft: "24px"}}><b>- ₹{orderData?.order_saving ?? "00.00"}</b></td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                       </div>
                       <hr/>
                       <div className='row justify-content-end'>
                           {/* <div className='col-lg-2'>
                              <p className='mb-2'></p>
                            
                           </div>
                           <div className='col-lg-3 text-start'>
                              <p><b>₹</b></p>
                             
                           </div> */}
                           <div className='table-responsive'>
                              <table className='table borderless'>
                                 <tbody>
                                    <tr style={{borderStyle : "none", borderColor : 'white'}}>
                                       <td className='text-right' style={{width : "77%", textAlign: "end"}}>Total Amount:</td>
                                       <td className='text-right' style={{paddingLeft: "24px"}}><b>₹{parseFloat(orderData?.order_amount).toFixed(2)}</b></td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                       </div>

                       <div className='text-center pt-5' >
                               <h6>You'll receive an email when your order is ready.</h6> 
                               <span>We appreciate your business. Please visit again.</span>
                       </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         {/* 
      <form onSubmit={e => onSubmit(e)} method='GET'>
        Name:
        <input type="text" name="name"  value={name} onChange={e => onInputChange(e)}/><br/><br/>
        Email:
        <input type="text" name="email" value={email} onChange={e => onInputChange(e)}/><br/><br/>

        <input type="submit" name="submit" value="Submit" />
   </form> */}
      </div>


   )
}

export default SuccessPayment