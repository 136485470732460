// MyContextProvider.js
import React, { useState } from 'react';
import MyContext from './MyContext';

const MyContextProvider = ({ children }) => {
  const [activeNavItem, setActiveNavItem] = useState("");

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  return (
    <MyContext.Provider value={{ activeNavItem, setActiveNavItem }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContextProvider;
