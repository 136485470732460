import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
// Import Swiper React components
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import axios from 'axios';
import globeVar from '../../GlobeApi';
import OwlCarousel from "react-owl-carousel";

var user = sessionStorage.getItem("user");
user = JSON.parse(user);
function Offers() {
    const [tcs,settcs] = useState('');

    const offer_pro = (id) => {
        // console.log(id);
        sessionStorage.setItem("cate_id", id);
        window.location.href = "/product";

    }


    const subscription = (id) => {
        // console.log(id);
        sessionStorage.setItem("true", id);
        window.location.href = "/product";
    }

    const combo = (id) => {
        // console.log(id);
        sessionStorage.setItem("Combo Offer", id);
        window.location.href = "/product";
    }

    const [offer, setoffer] = useState([])


    const offers_page = {
        responsive: {
            0: {
                items: 1,
            },
            520: {
                items: 2,
            },
            950: {
                items: 3,
            },
            1000: {
                items: 3,
            },

        },
    };
    useEffect(() => {

        window.scrollTo(0, 0);
        axios.post(globeVar + "offers_page").then((response) => {

            if (response.data.type === "success") {
                setoffer(response.data.data[0])
                // console.log(response.data.data[0]);
            }
        })
    }, [])
    return (
        <div>
            <section className='offers-page-banner pt-5 pt-sm-0'>
                <div className=' p-0'>

                    <Swiper className="mySwiper" modules={[Autoplay, Pagination]}
                        loop
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            slidesPerView: 1,
                            slidesPerGroup: 1
                        }}
                        pagination={{
                            clickable: true,
                        }}>
                        {offer.offer_banner?.map((data) => {
                            return <SwiperSlide>
                                <img src={data} ></img>
                            </SwiperSlide>;
                        })}
                    </Swiper>

                </div>
            </section>

            <section className='offers-second-slider'>
                <div className="container container-el position-relative">
                    <div className="row category-card-row">
                        <div className="">
                            <Swiper
                                // install Swiper modules
                                breakpoints={{
                               
                                   0: {
                                        slidesPerView: 1.3,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                   768: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                    },
                                    950: {
                                        slidesPerView: 2,
                                        slidesPerGroup: 2
                                    },
                                    1000: {
                                        slidesPerView: 2.5,
                                        slidesPerGroup: 1
                                    },
                                    1200: {
                                        slidesPerView: 2.5,
                                        slidesPerGroup: 1
                                    },
                                    1400: {
                                        slidesPerView: 2.5,
                                        slidesPerGroup: 1
                                    },
                                  
                                }}
                                modules={[Navigation, Pagination]}
                                centerslide="false"
                                effect={"fade"}
                                grabCursor={true}
                                spaceBetween={15}
                           
                                /* loop={true}  */
                                pagination={{ clickable: true }}
                                navigation={{
                                    nextEl: ".product2",
                                    prevEl: ".product1",
                                }}

                            /* onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log("slide change")} */
                            >
                                <div className="swiper-wrapper">
                                    {offer.offer_banner_list?.map(data => {
                                        return (<SwiperSlide>

                                            <div className='card' key={data._id}>
                                                <a href="/product"
                                                    onClick={() => offer_pro(data._id)}>
                                                    <img src={data.cat_value} className='img-fluid'></img>
                                                </a>
                                            </div>
                                        </SwiperSlide>)
                                    })}

                                </div>
                            </Swiper>
                            <div className="arrow product1">&#10094;</div>
                            <div className="arrow product2">&#10095;</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='offers-third-slider pb-3 pb-sm-5'>

                <section className='offers-fourth-slider pb-3 pb-sm-5'>

                    <div className='container container-el'>

                        <div className=' grid-container-offer'>
                            {offer.offer_product?.map(data => {
                                return <div className='item' key={data._id}>
                                    <a href={`/product-cart/${data._id}`}>
                                        <img src={data.prod_value} className='img-fluid'></img>
                                    </a>
                                </div>
                            })}

                        </div>

                    </div>
                </section>
                <div className=' offers-fourth-slider'>
                    <div className='container container-el'>
                        <Swiper
                            // install Swiper modules
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    slidesPerGroup: 1
                                },
                                520: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                950: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                1000: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                1200: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                1400: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                            }}
                            modules={[Navigation, Pagination]}
                            centerslide="false"
                            effect={"fade"}
                            grabCursor={true}
                            spaceBetween={15}
                            slidesPerView={4}
                            /* loop={true}  */
                            pagination={{ clickable: true }}
                            navigation={{
                                nextEl: ".product2",
                                prevEl: ".product1",
                            }}

                        /* onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")} */
                        >

                            <div className="swiper-wrapper position-relative">
                                {offer.offer_combo?.map(data => {
                                    return <SwiperSlide>
                                        <div className='card border-0' key={data._id}>
                                        <a href={`/product`}  onClick={() => combo(data._id)}>
                                            <img src={data.combo_value} className='img-fluid'></img>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                })}

                                {/* </OwlCarousel> */}
                            </div>
                        </Swiper>
                        <div className="arrow product1">&#10094;</div>
                        <div className="arrow product2">&#10095;</div>
                    </div>
                </div>
            </section>
            
            <section className='offers-fifth-slider pb-3 pb-sm-5'  >
            {offer.offer_subscription?.map(data => {
               return<div className='container container-el' key={data._id}>
                <a href={`/product`}  onClick={() => subscription(data._id)}>
                    <img src={data.sub_value} className='img-fluid' />
                </a>
                </div>
            })}
            </section>
        </div>
    )
}



export default Offers
